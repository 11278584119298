export const state = () => ({
  shipments: [],
  totalShipments: 0,
  sortField: 'ArrivalDate',
  sortOrder: 'desc',
  currentPage: 1,
  pageLimit: 20
})

export const mutations = {
  resetState(currentState) {
    Object.assign(currentState, state())
  },
  setSorting(state, { sortField, sortOrder }) {
    state.sortField = sortField
    state.sortOrder = sortOrder
  },
  setShipments(state, { shipments, totalShipments }) {
    state.shipments = shipments
    state.totalShipments = totalShipments
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  },
  setPageLimit(state, pageLimit) {
    state.pageLimit = pageLimit
  }
}

export const actions = {
  async initialize({ dispatch, commit }, route) {
    let p = null
    if (route) {
      p = parseInt(route.query.p)
      const r = parseInt(route.query.r)

      if (r && [20, 40, 60, 100].includes(r)) {
        commit('setPageLimit', r)
      }
    }

    await dispatch('fetchShipments', { resetPage: true, p })
  },
  async fetchShipments(
    { state, rootState, rootGetters, commit },
    { resetPage = false, p } = {}
  ) {
    const requestId = 'company/shipments/fetchShipments'
    this.$axios.cancel(requestId)
    if (resetPage) {
      commit('setCurrentPage', 1)
    }
    const { companyId, stat } = rootState.company
    const { pageLimit, sortField, sortOrder, currentPage } = state
    const { from, to } = rootGetters['company/arrivalDateBetween']
    const { country, tradeType: shipmentType } = rootState
    const sort = sortOrder === 'desc' ? '-' : ''
    const API_URL = `${COMPANY_API_URL}/companies/${companyId}/shipments`
    const traderTypes = {
      all: '',
      shipper: 'consignee',
      consignee: 'shipper',
      internal: 'internal'
    }[stat]
    const params = {
      arrivalDates: `${from}-${to}`,
      traderTypes,
      sortBy: `${sort}${sortField}`,
      page: currentPage,
      size: pageLimit,
      country,
      shipmentType
    }

    try {
      const { data } = await this.$axios.$get(API_URL, { params, requestId })
      const { shipments, totalShipments } = data[0]
      commit('setShipments', { shipments, totalShipments })

      if (p && p > 1) {
        const total = Math.min(10000, totalShipments)
        const pageCount = Math.ceil(total / state.pageLimit)
        if (pageCount) {
          p = p > pageCount ? pageCount : p
          commit('setCurrentPage', p)
        }
      }
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  }
}
