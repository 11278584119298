import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {
  Message,
  Loading,
  Notification,
  Checkbox,
  CheckboxGroup,
  Select,
  Option,
  Tooltip,
  Collapse,
  CollapseItem,
  Breadcrumb,
  BreadcrumbItem,
  Progress
} from 'element-ui'
import Pagination from './pagination'
import Popover from './popover'
import Radio from './radio'
import './dialog'
import Clickoutside from 'element-ui/lib/utils/clickoutside'
import ElementLocale from 'element-ui/lib/locale'
import translations from './translations'

Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Popover)
Vue.use(Loading.directive)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Select)
Vue.use(Option)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.directive('click-outside', Clickoutside)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Progress)

export default (ctx, inject) => {
  ctx.$message = Message
  inject('message', ctx.$message) // ReferenceError: Message is not defined
  ctx.$notify = Notification
  inject('notify', ctx.$notify)

  const i18n = new VueI18n({
    locale: ctx.app.i18n.locale,
    messages: translations
  })

  ElementLocale.i18n((key, value) => i18n.t(key, value))
}
