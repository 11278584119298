/**
 * Import and export necessary date-fns methods.
 * Methods are extracted this way to take advantage of tree-shaking.
 */
import {
  format,
  formatDistance,
  getUnixTime,
  startOfDay,
  addDays,
  formatDistanceStrict,
  isValid
} from 'date-fns'

export default {
  format,
  formatDistance,
  getUnixTime,
  startOfDay,
  addDays,
  formatDistanceStrict,
  isValid
}
