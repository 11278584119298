<template>
  <div class="upgrade-body flex-container">
    <div class="flex-items">
      <div class="plan-options">
        <div 
          role="radiogroup" 
          class="el-radio-group el-radio-button-group">
          <el-radio-group
            v-model="selectedPlan"
            class="el-radio-group el-radio-button-group"
            @change="$bus.$emit('changeSelectedPlan', selectedPlan)">
            <el-radio-button
              v-for="plan in planTypes"
              :key="plan.type"
              :label="plan.type"
              class="el-radio-button">
              <span class="plan-type">{{ plan.type }}</span>
              <span class="amount"><sub>$</sub>{{ plan.amount }}<sub>/month</sub></span>
            </el-radio-button>
          </el-radio-group>
          <!-- <label 
            aria-checked="true" 
            role="radio" 
            tabindex="0" 
            class="el-radio-button is-active">
            <input 
              type="radio" 
              tabindex="-1" 
              class="el-radio-button__orig-radio" 
              value="plus"> 
            <span class="el-radio-button__inner">
              <span class="plan-type">Plus</span>
              <span class="amount"><sub>$</sub>199<sub>/month</sub></span>
            </span>
          </label> 
          <label 
            role="radio" 
            tabindex="-1" 
            class="el-radio-button">
            <input 
              type="radio" 
              tabindex="-1" 
              class="el-radio-button__orig-radio" 
              value="premium"> 
            <span class="el-radio-button__inner">
              <span class="plan-type">Premium</span>
              <span class="amount"><sub>$</sub>399<sub>/month</sub></span>
            </span>
          </label> -->
        </div>
      </div>
      <plus-plan v-if="selectedPlan === 'Plus'"/>
      <premium-plan v-else/>
    </div>

    <div class="flex-items">
      <div class="card-base current-plan">
        <div class="card-headbar">
          <div class="plan-heading flex-container">
            <div class="plan-header-title">
              <span class="plan-small-des">Current Plan</span>
              <h2>{{ plan.type }}</h2>
            </div>
            <div class="plan-amount">
              <span class="amount"><sub>$</sub>99<sub>/month</sub></span>
            </div>
          </div>
        </div>
        <div class="card-main">
          <div class="plan-features">
            <h2>Plan Features</h2>
            <ul>
              <li 
                v-for="(feature, index) in plan.planFeatures" 
                :key="index">
                <h3>{{ feature.feature }}</h3>
                <p>{{ feature.description }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-base contact-card">
        <div class="contact-support flex-container">
          <h2>Talk now with our Client Support Team</h2>
          <button 
            class="btn-default btn-outline-white-blue" 
            @click="toggleChatWidget">Live Chat</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlusPlan from './PlusPlan'
import PremiumPlan from './PremiumPlan'

export default {
  name: 'PlusAccountModal',
  components: { PlusPlan, PremiumPlan },
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedPlan: 'Plus',
      planTypes: [
        {
          type: 'Plus',
          amount: 199
        },
        {
          type: 'Premium',
          amount: 399
        }
      ]
    }
  },
  methods: {
    toggleChatWidget() {
      this.$chat.toggle()
    }
  }
}
</script>
