import { snakeCase } from 'lodash'
import camelize from 'camelize'
import { statesKeys } from '~/utils/address'
import analyticsFilters from '~/config/analytics-filters'
import { chart } from '~/config/config'
import $date from '~/plugins/date/methods'
import { convertToTwoDigitDecimal } from '~/utils/number'

export const state = () => ({
  isResultsClean: true,
  chartType: 'line',
  chartTypeOptions: {
    line: 'historical trend',
    bar: 'bar chart',
    pie: 'pie chart',
    table: 'pivot table'
  },
  measurement: 'shipments',
  measurementOptions: {
    shipments: 'Shipments',
    kgs: 'Gross Weight (kgs)',
    calculated_teu: 'TEU',
    containers: 'Containers'
  },
  field: 'consignee',
  lineChartField: 'none',
  fieldOptions: {
    none: '-None-',
    consignee: 'Consignee (Importer)',
    shipper: 'Shipper (Supplier)',
    usPort: 'U.S. Port',
    foreignPort: 'Foreign Port',
    country: 'Country of Origin',
    state: 'State'
  },
  analyticsData: [],
  page: 0,
  dataResultsLimit: 50,

  chartLegend: [],

  selectedDatasets: []
})

export const mutations = {
  resetState(currentState) {
    Object.assign(currentState, state())
  },
  toggleCleanResults(state) {
    state.isResultsClean = !state.isResultsClean
  },
  setChartType(state, type) {
    state.chartType = type
  },
  setMeasurement(state, measurement) {
    state.measurement = measurement
  },
  setField(state, field) {
    state.field = field
  },
  setLineChartField(state, field) {
    state.lineChartField = field
  },
  setAnalyticsData(state, analyticsData) {
    state.analyticsData = analyticsData
  },
  setPage(state, page) {
    state.page = page
  },
  setChartLegend(state, legend) {
    state.chartLegend = legend
  },
  addSelectedDataset(state, dataset) {
    state.selectedDatasets.push(dataset)
  },
  removeSelectedDataset(state, index) {
    state.selectedDatasets.splice(index, 1)
  },
  setSelectedDatasets(state, datasets) {
    state.selectedDatasets = datasets
  }
}

export const getters = {
  lineChartData(state, getters, { search }) {
    const dataProp = (() => {
      switch (state.measurement) {
        case 'shipments':
          return 'shipments'
        case 'containers':
          return 'no_of_containers'
        case 'calculated_teu':
          return 'calculated_teu'
        case 'kgs':
          return 'weight'
      }
    })()

    let labels = []
    const data = []
    let legend = []
    const scaleFormat = $date.getFormat('days')

    search.chartData.forEach(({ items, name, id }, index) => {
      // check if the Remove blank entries has been toggled
      if (state.isResultsClean && name.toLowerCase() === 'n/a') {
        return
      }

      const dataArray = []

      legend.push({
        color: chart.line.colors[index],
        label: name,
        link: id ? `/company/${id}/overview` : null
      })

      items.forEach(item => {
        let dataCount = item[dataProp]
        labels.push({
          date: $date.formatUtc(+item.label.split('-')[0], scaleFormat),
          timestamp: item.label
        })

        const pointIndex = labels.findIndex(
          element => element.timestamp === item.label
        )

        dataArray[pointIndex] = dataCount
      })

      data.push({
        key: dataProp,
        data: Array.from(dataArray, item => item || 0),
        name
      })
    })

    labels = [...new Map(labels.map(x => [x.date, x])).values()] // remove duplicate dates
    labels = labels.map(x => x.timestamp) // return only timestamp

    return {
      labels,
      data,
      legend
    }
  },
  lineChartTableData(state, getters) {
    const shipmentData = getters.lineChartData.data
    const shipmentLabels = getters.lineChartData.labels

    const data = []
    shipmentLabels.forEach((label, index) => {
      data.push({
        data: [
          { text: index + 1 },
          {
            text: label
              .split('-')
              .map(timestamp => {
                return $date.formatUtc(+timestamp, 'yyyy-MM-dd')
              })
              .join(' - ')
          }
        ]
      })
    })

    shipmentData.forEach(shipment => {
      shipment.data.forEach((point, index) => {
        data[index].data.push({
          text: convertToTwoDigitDecimal(point)
        })
      })
    })
    return data
  },
  cleanFunction(state) {
    // returns true if item SHOULD NOT BE REMOVED
    const filters = analyticsFilters[state.field]
    return item => {
      const name = item.name.toUpperCase()
      if (!name || name.toLowerCase() === 'n/a') {
        return false
      }

      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i]
        if (name.indexOf(filter) > -1) {
          return false
        }
      }

      return true
    }
  },
  allAnalyticsData(state) {
    const measurementPropName = (() => {
      switch (state.measurement) {
        case 'shipments':
          return 'shipmentsCount'
        case 'containers':
          return 'containersCount'
        case 'kgs':
          return 'weightKg'
        case 'calculated_teu':
          return 'calculatedTeu'
        default:
          throw new Error(
            `Invalid Y-axis: ${JSON.stringify(state.measurement)}`
          )
      }
    })()
    return state.analyticsData.map(item => {
      return {
        ...item,
        name: item.name || 'N/A',
        value: item[measurementPropName]
      }
    })
  },
  // Returns sliced analytics data along with 'value' property
  // of selected measurement field
  analyticsData(state, getters) {
    return getters.allAnalyticsData.slice(0, state.dataResultsLimit)
  },
  cleanedAnalyticsData(state, getters) {
    if (!state.isResultsClean) {
      return getters.analyticsData
    }

    const { cleanFunction } = getters
    return getters.allAnalyticsData
      .reduce((analyticsData, item) => {
        if (cleanFunction(item)) {
          analyticsData.push(item)
        }
        return analyticsData
      }, [])
      .slice(0, state.dataResultsLimit)
  },
  measurementLabel(state) {
    return state.measurementOptions[state.measurement]
  },
  fieldLabel(state) {
    return state.fieldOptions[state.field]
  },
  pluralizedFieldLabel(state, getters) {
    if (getters.cleanedAnalyticsData.length < 2) {
      return getters.fieldLabel
    }
    if (getters.fieldLabel === 'Country of Origin') {
      return 'Countries of Origin'
    }
    if (getters.fieldLabel === 'Consignee (Importer)') {
      return 'Consignees'
    }
    if (getters.fieldLabel === 'Shipper (Supplier)') {
      return 'Shippers'
    }
    return `${getters.fieldLabel}s`
  },
  chartTitle(state, getters) {
    switch (state.chartType) {
      case 'line':
        return `Total ${getters.measurementLabel}`
      case 'pie':
        return `Total ${getters.measurementLabel} by ${getters.fieldLabel}`
      case 'bar':
      case 'table':
        return `Top ${getters.cleanedAnalyticsData.length || '?'} ${
          getters.pluralizedFieldLabel
        } by ${getters.measurementLabel}`
      default:
        return ''
    }
  }
}

export const actions = {
  async getOptionsData({ state }, options) {
    options = options || {}
    const field = options.field || state.field
    const lineField = options.lineField || state.lineChartField
    const action = options.action || 'changeFieldAnalyzed'

    const fieldPathMapper = f => {
      switch (f) {
        case 'country':
          return 'origin_countries'
        case 'none':
          return 'none'
        default:
          return snakeCase(f) + 's'
      }
    }

    const fieldPath = fieldPathMapper(field)
    const lineFieldPath = fieldPathMapper(lineField)

    const measurement = options.measurement || state.measurement
    const measurementQuery = (() => {
      switch (measurement) {
        case 'containers':
        case 'shipments':
          return `${measurement}_count`
        case 'calculated_teu':
          return 'calculated_teu'
        default:
          return `weight_${measurement.slice(0, 2)}`
      }
    })()

    return {
      action,
      field,
      measurement,
      fieldPath,
      lineFieldPath,
      measurementQuery,
      lineField
    }
  },
  async fetchAnalyticsData(
    { state, commit, dispatch, rootState, rootGetters },
    options
  ) {
    if (!rootState.userSubscriptions.userHasAnalyticsSubscription) {
      return
    }

    const {
      action,
      field,
      lineField,
      measurement,
      fieldPath,
      lineFieldPath,
      measurementQuery
    } = await dispatch('getOptionsData', options)

    try {
      const cleanedResultsBuffer = 15
      const limit = state.dataResultsLimit + cleanedResultsBuffer

      const { params } = rootGetters['search/searchQueryParam']([
        {
          key: 'scale',
          value: rootState.dateInterval
        },
        {
          key: 'timezone',
          value: rootState.timezone
        }
      ])
      params.set('limit', limit)
      params.set('sortBy', measurementQuery)

      let apiParams = { fieldPath, params, field, action, measurement }

      if (action !== 'scaleRefinement')
        await dispatch('analyticsDataHandler', apiParams)

      if (lineField === 'none') {
        apiParams.field = lineField
        apiParams.fieldPath = lineFieldPath
      }

      if (action !== 'changeMeasurement') commit('setSelectedDatasets', [])

      await dispatch('fetchChartData', apiParams)
    } catch (error) {
      const response = error.response ? error.response.data : ''

      if (response.reason === 'Daily search limit exceeded') {
        return
      }

      if (response && error.response.status === 403) {
        // Update store on revoked analytics subscription
        dispatch('userSubscriptions/getUserSubscriptions', null, {
          root: true
        })

        return
      }

      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }

    // We commit here after fetching so that the label
    // displays are updated according to the date that is displayed
    commit('setField', field)
    commit('setMeasurement', measurement)
  },
  async analyticsDataHandler(
    { rootState, commit },
    { fieldPath, params, field }
  ) {
    const requestId = 'fetchAnalyticsData'
    this.$axios.cancel(requestId)

    const result = await this.$axios.$get(
      `${SHIPMENTS_API_URL}/v1/${rootState.country}/${
        rootState.tradeType
      }/shipments/analytics/${fieldPath}`,
      {
        headers: {
          ...(rootState.search.searchToken
            ? {
                'x-search-token': rootState.search.searchToken
              }
            : {})
        },
        params,
        requestId,
        progress: false
      }
    )

    if (!result.success) {
      throw new Error(`Analytics search unsuccessful`)
    }

    let resultData = result.data
    if (field === 'state') {
      resultData = result.data.map(val => {
        val.name = statesKeys[val.name] || val.name
        return val
      })
    }

    commit('setAnalyticsData', camelize(resultData))
  },
  async fetchChartData(
    { rootState, state, getters, commit },
    { field, fieldPath, params, action, measurement }
  ) {
    const requestId = 'fetchChartData'
    this.$axios.cancel(requestId)

    params.set('analyticsField', fieldPath)

    const { legend = [] } = getters.lineChartData
    if (action === 'scaleRefinement') {
      const selectedItems = legend.reduce((itemList, data) => {
        itemList.push(data.label)
        return itemList
      }, [])
      params.set('selectedItems', JSON.stringify(selectedItems))
    }

    const result = await this.$axios.$get(
      `${SHIPMENTS_API_URL}/v1/${rootState.country}/${
        rootState.tradeType
      }/shipments/summary`,
      {
        headers: {
          ...(rootState.search.searchToken
            ? {
                'x-search-token': rootState.search.searchToken
              }
            : {})
        },
        params,
        requestId,
        progress: false
      }
    )

    const formattedBody = {
      totals: result.total,
      items: result.data.map(item => ({
        ...item,
        items: item.items.sort(
          (a, b) => (a.label.split('-')[0] > b.label.split('-')[0] ? 1 : -1)
        )
      }))
    }

    /**
     * Add the previously selected datasets here instead of fetching it from backend
     */
    if (action === 'changeMeasurement' && state.selectedDatasets.length) {
      let duplicateIndex = -1
      let selectedDatasets = Object.assign([], state.selectedDatasets)

      formattedBody.items.forEach(data => {
        duplicateIndex = selectedDatasets.findIndex(selected => {
          return selected.name === data.name
        })

        if (duplicateIndex > -1) {
          selectedDatasets.splice(duplicateIndex, 1)
        }
      })

      let others = null
      if (
        formattedBody.items[formattedBody.items.length - 1].name === 'Others'
      ) {
        others = formattedBody.items.pop()

        let { shipments, weight, no_of_containers } = others.total
        selectedDatasets.forEach(data => {
          shipments -= data.total.shipments
          weight -= data.total.weight
          no_of_containers -= data.total.no_of_containers

          data.items.forEach((dataPoint, index) => {
            others.items[index].shipments -= dataPoint.shipments
            others.items[index].weight -= dataPoint.weight
            others.items[index].no_of_containers -= dataPoint.no_of_containers
          })
        })
        others.total = { shipments, weight, no_of_containers }
      }

      switch (measurement) {
        case 'containers':
          measurement = 'no_of_containers'
          break
        case 'shipments':
          break
        default:
          measurement = 'weight'
      }
      selectedDatasets = selectedDatasets.sort((a, b) => {
        if (b.total[measurement] > a.total[measurement]) return 1
        if (b.total[measurement] < a.total[measurement]) return -1
        return 0
      })

      formattedBody.items = formattedBody.items.concat(selectedDatasets)
      if (others) formattedBody.items.push(others)
    }

    commit('search/setChartData', formattedBody, { root: true })
    commit('setLineChartField', field)
    commit(
      'setSelectedDatasets',
      formattedBody.items.filter(item => {
        return !['All', 'Others'].includes(item.name)
      })
    )
  },
  async setPage({ commit }, page) {
    commit('setPage', page)
  },
  selectDataset({ state, commit, rootState }, key) {
    if (['All', 'Others'].includes(key)) return

    const index = state.selectedDatasets.findIndex(data => {
      return data.name === key
    })

    if (index === -1) {
      const dataIndex = rootState.search.chartData.findIndex(data => {
        return data.name === key
      })
      commit('addSelectedDataset', rootState.search.chartData[dataIndex])
    } else {
      commit('removeSelectedDataset', index)
    }
  }
}
