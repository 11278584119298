export const cioPageTracking = process.env.CUSTOMERIO_SITE_ID
  ? {
      watch: {
        $route(to, from) {
          if (to.path !== from.path) {
            global._cio.page(document.location.origin + to.fullPath)
          }
        }
      }
    }
  : {}

export default cioPageTracking
