import tryPromises from '~/utils/tryPromises'

export default {
  tryPromises,
  copy(object) {
    return JSON.parse(JSON.stringify(object))
  },
  getHash(str) {
    let hash = 5381
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) + hash + str.charCodeAt(i)
      /* hash * 33 + c */
    }
    return hash
  },
  stringToColor(str) {
    while (str.length < 5) {
      str += str
    }
    const hash = this.getHash(str)
    const r = (hash & 0xff0000) >> 16
    const g = (hash & 0x00ff00) >> 8
    const b = hash & 0x0000ff
    return `#${('0' + r.toString(16)).substr(-2) +
      ('0' + g.toString(16)).substr(-2) +
      ('0' + b.toString(16)).substr(-2)}`
  },
  typeof(obj) {
    return {}.toString
      .call(obj)
      .match(/\s([a-zA-Z]+)/)[1]
      .toLowerCase()
  },
  log(text) {
    // eslint-disable-next-line
    console.log(text)
  }
}
