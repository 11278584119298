import {
  validateDateRange,
  validateSorts,
  validatePageLimit
} from './helper-functions'

export default function validateSearch({ state }, storage) {
  const search = {}

  // sortOrder && sortField
  const { sortOrder, sortField } = validateSorts(state, storage)
  if (sortOrder) {
    search.sortOrder = sortOrder
  }
  if (sortField) {
    search.sortField = sortField
  }

  // pageLimit
  if (validatePageLimit(state, storage.pageLimit)) {
    search.pageLimit = storage.pageLimit
  }

  // stat
  if (state.search.stats.includes(storage.stat)) {
    search.stat = storage.stat
  }

  // bookmarkDates
  if (
    validateDateRange(
      {
        minDate: state.search.bookmarkMinDate,
        maxDate: state.search.bookmarkMaxDate
      },
      storage.bookmarkDateRange
    )
  ) {
    search.bookmarkDateRange = storage.bookmarkDateRange
  }

  return search
}
