<template>
  <el-popover
    v-model="showPopover"
    placement="bottom-end"
    popper-class="light download-status with-footer bordered-list">
    <div class="popover-content">
      <div class="pop-over-default">
        <credit-widget
          :values="{
            percentage: downloadPercentage,
            credits: downloadCredits
          }"
          label="Downloads"
          credits-label="records"/>
        <nuxt-link
          to="/download-history"
          class="btn btn-text download-history flex-container">
          <span @click="showPopover = false">Download History</span>
        </nuxt-link>
        <div
          v-if="$actions.pending('downloads/generateDownload')"
          class="result-queuing">
          <ContentLoader
            :height="35">
            <rect
              x="0"
              y="4"
              width="35"
              height="35"/>
            <rect
              x="45"
              y="4"
              width="50%"
              height="8"/>
            <rect
              x="45"
              y="20"
              width="100"
              height="8"/>
          </ContentLoader>
        </div>
        <ul
          v-if="allDownloads.length"
          class="download-lists">
          <current-downloads-item
            v-for="item in allDownloads"
            :key="item.export_id"
            :item="item"
            @preview="openPreview"
            @cancel-download="cancelDownload"
            @remove-download="removeDownload" />
        </ul>
        <div
          v-if="!allDownloads.length && !$actions.pending('downloads/generateDownload')"
          class="empty">No downloads running right now.
        </div>
      </div>
    </div>
    <div class="popover-footer flex-container justify-right">
      <button
        class="btn btn-text secondary flex-items"
        @click="showPopover = false">Close</button>
    </div>
    <a
      slot="reference"
      class="icon download-icon-white"/>
  </el-popover>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { ContentLoader } from '~/components/custom/vue-content-loader'
import CurrentDownloadsItem from './CurrentDownloadsItem'
import CreditWidget from '~/components/common/CreditWidget'
import downloadExport from '~/mixins/downloadExport'

export default {
  name: 'CurrentDownloads',
  components: {
    CurrentDownloadsItem,
    ContentLoader,
    CreditWidget
  },
  mixins: [downloadExport],
  data() {
    return {
      showPopover: false,
      isFetching: false,
      finishedDownloads: []
    }
  },
  computed: {
    ...mapState('downloads', [
      'currentDownloads',
      'dateRange',
      'maxDate',
      'enableDownload'
    ]),
    ...mapGetters('userCredits', ['downloadPercentage', 'downloadCredits']),
    allDownloads() {
      const finishedNotInCurrent = this.finishedDownloads.filter(
        download =>
          this.currentDownloads.findIndex(
            dl => dl.export_id === download.export_id
          ) === -1
      )
      return [...this.currentDownloads, ...finishedNotInCurrent]
    },
    activeDownloads() {
      return this.currentDownloads.map(download => {
        if (
          download.export_status === 'started' ||
          download.export_status === 'queued'
        ) {
          return download.export_id
        }
      })
    }
  },
  watch: {
    finishedDownloads(downloads) {
      const latestDownload = downloads[0]

      this.generateDownloadLink({ id: latestDownload.export_id })
    },
    currentDownloads(downloads, oldDownloads) {
      if (downloads.length) {
        const finishedDownloads = downloads.filter(
          download => download.export_status === 'completed'
        )
        if (finishedDownloads.length) {
          finishedDownloads.forEach(download => {
            if (
              this.finishedDownloads.findIndex(
                dl => dl.export_id === download.export_id
              ) > -1
            ) {
              return
            }
            this.finishedDownloads = [download, ...this.finishedDownloads]
            // Remove last finished download if more than 3 are finished
            if (this.finishedDownloads.length > 3) {
              this.finishedDownloads = this.finishedDownloads.slice(0, 3)
            }
          })
          const maxDate = new Date().getTime()
          // If current 'to' date is now, then set it to the new maxDate as well
          if (this.maxDate === this.dateRange.to) {
            this.setDateRange({ to: maxDate })
          }
          this.setDateLimits({ maxDate })
          this.fetchDownloadHistory()
          this.setDownloadStatus(true)
        }
        if (oldDownloads.length > downloads.length && !this.enableDownload) {
          this.setDownloadStatus(true)
        }
        if (
          downloads.length > 5 &&
          this.enableDownload &&
          finishedDownloads.length === 0
        ) {
          this.setDownloadStatus(false)
        }
      }
      this.getUserCredits()
      this.$axios.cancel('fetchCurrentDownloads')
      this.fetch()
    }
  },
  mounted() {
    this.$bus.$on('downloadStarted', this.openPopover)
  },
  destroyed() {
    this.$bus.$off('downloadStarted', this.openPopover)
  },
  async created() {
    await this.initialize()
    if (this.activeDownloads.length) {
      this.fetch()
    }
  },
  methods: {
    ...mapActions('downloads', [
      'initialize',
      'cancelDownload',
      'fetchCurrentDownloads',
      'fetchDownloadHistory'
    ]),
    ...mapMutations('downloads', [
      'setDateLimits',
      'setDateRange',
      'setDownloadStatus'
    ]),
    ...mapActions('userCredits', ['getUserCredits']),
    async fetch() {
      if (this.isFetching) {
        return
      }
      this.isFetching = true
      if (!this.activeDownloads.length) {
        this.isFetching = false
        return
      }
      await this.fetchCurrentDownloads(this.activeDownloads.join(','))
      this.isFetching = false
      setTimeout(this.fetch, 500)
    },
    openPreview(item) {
      this.showPopover = false
      this.$bus.$emit('openPreview', item)
    },
    openPopover() {
      this.showPopover = true
    },
    removeDownload(id) {
      this.finishedDownloads = this.finishedDownloads.filter(
        download => download.export_id !== id
      )
    }
  }
}
</script>

<style lang="scss" src="~/assets/scss/sections/download/download-status-lists.scss"/>
