export const state = () => ({
  suppliers: [],
  totalSuppliers: 0,
  currentPage: 1
})

export const mutations = {
  resetState(currentState) {
    Object.assign(currentState, state())
  },
  setSuppliers(state, suppliers) {
    state.suppliers = suppliers
    state.totalSuppliers = suppliers.length
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  }
}

export const actions = {
  async initialize({ dispatch, commit }, route) {
    let p = null
    if (route) {
      p = parseInt(route.query.p)
      const r = parseInt(route.query.r)

      if (r && [20, 40, 60, 100].includes(r)) {
        commit('company/setPageLimit', r, { root: true })
      }
    }
    await dispatch('fetchCompanySuppliers', p)
  },
  async fetchCompanySuppliers({ commit, rootState, rootGetters }, p) {
    const requestId = 'fetchCompanySuppliers'
    this.$axios.cancel(requestId)

    try {
      const { from, to } = rootGetters['company/arrivalDateBetween']
      const {
        country,
        tradeType: shipmentType,
        company: { companyId, pageLimit }
      } = rootState
      const sortBy = rootGetters['company/companySortBy']

      const { data } = await this.$axios.$get(
        `${COMPANY_API_URL}/companies/${companyId}/shippers`,
        {
          params: {
            arrivalDates: `${from}-${to}`,
            sortBy,
            country,
            shipmentType
          }
        }
      )

      commit('setSuppliers', data.items)

      if (p && p > 1) {
        const total = Math.min(10000, data.items.length)
        const pageCount = Math.ceil(total / pageLimit)
        if (pageCount) {
          p = p > pageCount ? pageCount : p
          commit('setCurrentPage', p)
        }
      }
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  }
}
