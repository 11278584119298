import { redirectExperiment } from '~/config/posthog-enums'
import { APPLICATION_DOMAIN } from '~/api/config'

export default {
  methods: {
    /**
     * Checks if a feature flag is enabled
     * @param {String} key - feature flag key
     * @param {Boolean} sendEvent (optional) - automatic sendevent parameter
     */
    isFeatureFlagPresent(key, sendEvent = false) {
      const featureFlagEnabled = this.$posthog.isFeatureEnabled(key, {
        send_event: sendEvent
      })

      return featureFlagEnabled
    },
    getExperimentGroup(key) {
      if (this.isFeatureFlagPresent(key)) {
        return this.$posthog.getFeatureFlag(key)
      }

      return null
    },
    /**
     * Checks if user is part of redirect experiment
     */
    isUserPartOfRedirectTestExperiment() {
      if (this.$cookies.get('initiallyNonUS')) {
        return false
      }

      const hasRedirectGroupCookie = this.$cookies.get('redirectTestGroup')
      const partOfRedirectExperiment =
        this.isFeatureFlagPresent('isng-redirect-test') &&
        this.isUserRegisteredPastRedirectExperimentThreshold() &&
        this.$store.getters['experiments/isIdentified'] &&
        hasRedirectGroupCookie

      return partOfRedirectExperiment
    },
    /**
     * Checks if current user is registered past the date threshold for the redirect test experiment
     */
    isUserRegisteredPastRedirectExperimentThreshold() {
      const registeredDate = this.$store.getters['accounts/userCreatedDate']
      const userCreated = new Date(registeredDate) // TODO: Fix this.$store.getters['user/created']
      const userCreatedDate = new Date(userCreated)
      const userRegistrationThreshold = new Date(
        redirectExperiment.DATE_THRESHOLD
      )

      return userCreatedDate > userRegistrationThreshold
    },
    /**
     * Identifies the current user logged in.
     */
    identifyUser(email) {
      if (!this.$store.getters['experiments/isIdentified']) {
        const validCookie = this.isPosthogCookieValid()

        if (!validCookie) {
          this.$posthog.identify(email)
          this.$store.commit('experiments/setIsIdentified', true)
        }
      }
    },
    /**
     * Gets posthog cookie value
     */
    getPosthogCookie() {
      return this.$cookies.get(`ph_${process.env.POSTHOG_API_KEY}_posthog`)
    },
    /**
     * Checks if posthog cookie identity matches with current logged in user email
     */
    isPosthogCookieValid() {
      const phCookie = this.getPosthogCookie()
      return (
        phCookie &&
        this.$store.getters['accounts/email'] === phCookie.distinct_id
      )
    },
    /**
     * Sets if the user was redirected initiallly
     * @param {String} group either control or test
     */
    setRedirectTestCookie(group) {
      this.$cookies.set('redirectTestGroup', group, {
        domain: APPLICATION_DOMAIN,
        path: '/',
        maxAge: 60 * 60 * 24 * 365 * 9999
      })
    },
    /**
     * Gets redirect test cookie
     */
    getRedirectTestCookie() {
      return this.$cookies.get('redirectTestGroup')
    }
  }
}
