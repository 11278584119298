import { merge } from 'lodash'
import enLocale from 'element-ui/lib/locale/lang/en'

const en = merge({}, enLocale, {
  el: {
    pagination: {
      total: 'Number of Results: {total}'
    }
  }
})

export default {
  en
}
