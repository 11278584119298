import _ from 'lodash'
import md5 from 'md5'

export const advancedSearchHelpText = [
  {
    field: 'AllFields',
    text: 'Search through all fields on all shipments',
    placeholder: 'Enter your search term'
  },
  {
    field: 'Consignee',
    text:
      'Search for companies that have imported shipments during your search period',
    placeholder: 'Enter importer/consignee name'
  },
  {
    field: 'ConsigneeZipCode',
    text: 'Search via consignee zip codes',
    placeholder: 'Enter zip code'
  },
  {
    field: 'ConsigneeAddress',
    text:
      'Search via consignee address. Try broadening the address to get more matches.',
    placeholder: 'Enter street names, city names, or address fragments'
  },
  {
    field: 'NotifyParty',
    text: 'Search for shipments via their notify parties',
    placeholder: 'Enter notify party'
  },
  {
    field: 'NotifyPartyAddress',
    text: 'Search for shipments via their notify party address',
    placeholder: 'Enter notify party address'
  },
  {
    field: 'ProductDescription',
    text: 'Search for products and contents of shipments',
    placeholder: 'e.g. crude oil, textiles'
  },
  {
    field: 'BillOfLading',
    text: 'Search for shipments matching specific bills of lading',
    placeholder: 'Enter bill of lading number'
  },
  {
    field: 'MarksAndNumbers',
    text: 'Search for shipments with matching marks and numbers',
    placeholder: 'Enter marks and numbers'
  },
  {
    field: 'HSCode',
    text: 'Search for shipments according to their declared HS Code',
    placeholder: 'Enter 8 digit HS Code'
  },
  {
    field: 'Shipper',
    text:
      'Search for foreign suppliers and companies that have traded with the United States',
    placeholder: 'Enter shipper/supplier name'
  },
  {
    field: 'ShipperAddress',
    text: 'Search for shipments via their shipper address',
    placeholder: 'Enter shipper address'
  },
  {
    field: 'CountryOfOrigin',
    text: 'Search for shipments coming from specific countries',
    placeholder: 'e.g. "China", "India"'
  },
  {
    field: 'ForeignPort',
    text: 'Search for shipments coming from specific ports',
    placeholder: 'e.g. "Shanghai", "Kaohsiung"'
  },
  {
    field: 'PlaceOfReceipt',
    text:
      'Place of receipt is where the shipper handed over the cargo to their carrier',
    placeholder: 'Enter place of receipt'
  },
  {
    field: 'CarrierName',
    text: 'Search shipments via their carrier ',
    placeholder: 'e.g. "Maersk", "APL LOGISTICS"'
  },
  {
    field: 'CarrierCode',
    text: 'Search shipments via their carrier code',
    placeholder: 'e.g. "MAEU", "APLL"'
  },
  {
    field: 'DestinationPort',
    text: 'Search for shipments via their entry port in the US',
    placeholder: 'e.g. "Los angeles", "Houston", "Long beach"'
  },
  {
    field: 'ContainerNumber',
    text: 'Search for specific container numbers',
    placeholder: 'Enter container number'
  },
  {
    field: 'VesselName',
    text: 'Search for shipments via the vessel name',
    placeholder: 'Enter vessel name'
  },
  {
    field: 'ZipCodeRange',
    text:
      'Search via a range of consignee zip codes (values should have 5 digits)',
    startPlaceholder: 'Enter start of range',
    endPlaceholder: 'Enter end of range'
  }
]

// Used to split string of keyword
export function splitAdvancedKeywords(advancedKeyword, index = null) {
  let keyword = '',
    type = '',
    field = ''

  if (typeof advancedKeyword === 'object') {
    if (advancedKeyword.i) return splitAdvancedKeywords(advancedKeyword.i[0])

    keyword = Array.isArray(advancedKeyword.keyword)
      ? advancedKeyword.keyword
      : [advancedKeyword.keyword]
    type = advancedKeyword.type
    field = advancedKeyword.field
  } else if (advancedKeyword) {
    const splitKeyword = advancedKeyword.split(',')

    keyword = [splitKeyword[0]]
    type = splitKeyword[1]
    field = splitKeyword[2]
  }

  let sanitizedKeywords = { keyword, type, field }

  if (index !== null) {
    sanitizedKeywords.id = md5(JSON.stringify(sanitizedKeywords) + index)
  }

  return sanitizedKeywords
}

export function updateKeywordStructure(keywords, index = 0) {
  if (!keywords) {
    return
  }

  if (typeof keywords === 'string' || keywords.field) {
    return splitAdvancedKeywords(keywords, index)
  }

  const keywordsObject = _.isArray(keywords)
    ? {
        o: 'AND',
        i: keywords
      }
    : keywords

  const updatedKeywords = keywordsObject.i.map((item, i) => {
    return updateKeywordStructure(item, i)
  })

  return {
    o: keywordsObject.o,
    i: updatedKeywords
  }
}
