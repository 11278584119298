import Vue from 'vue'
import notifyError from './notifyError'

export default function({ isDev }) {
  // This captures errors thrown during vue render (during the lifecycle hooks), i.e. mounted, etc.
  // These errors are handled by error.vue so no need to notifyError()
  Vue.config.errorHandler = function(event) {
    if (isDev) {
      console.error(event)
    }
    // notifyError()
  }

  // This handles all other errors
  window.onerror = function handleWindowError() {
    // if (isDev) console.log('window.onerror')
    notifyError()
  }

  window.addEventListener('unhandledrejection', function unhandledrejection(
    error
  ) {
    // if (isDev) console.log('unhandledrejection')
    // console.log(error)
    if (error && error.reason && error.reason.response) {
      const {
        reason: {
          response: { data }
        }
      } = error
      notifyError({
        type: 'warning',
        message: data.reason
      })
      return
    }
    if (error && error.reason && error.reason.isAxiosError)
      notifyError({
        type: 'warning',
        message: error && error.reason ? error.reason.toString() : ''
      })
    else notifyError()
  })
}
