import Vue from 'vue'
import loadZohoSalesIQ from './salesiq.js'
import installSwaychat from './swaychat.js'

export default () => {
  const { CHAT_MODE } = process.env

  let isChatReady = false
  let isChatWindowVisible = false

  let chat
  if (CHAT_MODE === 'salesiq') {
    chat = {
      elementId: 'zsiq_float',
      /**
       * Opens the SalesIQ bubble.
       */
      open: () => {
        if (isChatReady) {
          window.$zoho.salesiq.floatwindow.visible('show')
          isChatWindowVisible = true
        }
      },
      /**
       * Minimizes the SalesIQ bubble.
       */
      minimize: () => {
        if (isChatReady) {
          window.$zoho.salesiq.floatwindow.visible('hide')
          isChatWindowVisible = false
        }
      },
      /**
       * Toggles the visibility of the SalesIQ bubble.
       */
      toggle: () => {
        if (isChatReady) {
          if (isChatWindowVisible) {
            window.$zoho.salesiq.floatwindow.visible('hide')
            isChatWindowVisible = false
          } else {
            window.$zoho.salesiq.floatwindow.visible('show')
            isChatWindowVisible = true
          }
        }
      },
      /**
       * Displays the chat button.
       */
      displayChat: () => {
        if (isChatReady) {
          window.$zoho.salesiq.floatbutton.visible('show')
        }
      },
      /**
       * Hides the chat button and bubble.
       */
      hideChat: () => {
        if (isChatReady) {
          window.$zoho.salesiq.floatwindow.visible('hide')
          window.$zoho.salesiq.floatbutton.visible('hide')
          isChatWindowVisible = false
        }
      },

      /**
       * Sets the user information in SalesIQ.
       * @param {object} user - Information about the user.
       * @param {string} user.email - The email of the user.
       * @param {string} user.name - The full name of the user.
       * @param {string} user.phone - The contact number of the user.
       */
      setUser: user => {
        if (isChatReady) {
          window.$zoho.salesiq.visitor.email(user.email)
          window.$zoho.salesiq.visitor.name(user.name)
          window.$zoho.salesiq.visitor.contactnumber(user.phone)
        }
      },
      openPopout: () => {},
      closePopout: () => {}
    }

    // Load SalesIQ script
    loadZohoSalesIQ(() => {
      // Hide widget on load
      window.$zoho.salesiq.floatbutton.visible('hide')
      window.$zoho.salesiq.floatwindow.visible('hide')
      isChatWindowVisible = false

      // Update visibility tracking if user manually closes the widget
      window.$zoho.salesiq.floatwindow.minimize(() => {
        isChatWindowVisible = false
      })

      isChatReady = true
      chat.hideChat()
    })
  } else {
    installSwaychat()

    chat = {
      elementId: 'swaychat_chat_widget',
      /**
       * Toggles the visibility of the SwayChat chat window.
       */
      toggle: () => {
        window._SwayChat('toggleMinimize')
      },
      /**
       * Pops out SwayChat in a new window.
       */
      openPopout: () => {
        window._SwayChat('popOutOpen')
      },
      /**
       * Closes the SwayChat popout window.
       */
      closePopout: () => {
        window._SwayChat('popOutClose')
      },
      /**
       * Sets the user information in SalesIQ.
       * @param {object} user - Information about the user.
       * @param {string} user.email - The email of the user.
       * @param {string} user.name - The full name of the user.
       * @param {string} user.phone - The contact number of the user.
       */
      setUser: user => {
        window._SwayChat('setUser', user)
      }
    }
  }

  if (chat) {
    Vue.prototype.$chat = chat
  }
}
