<template>
  <div
    class="upgrade-body flex-container">
    <div class="flex-items">
      <premium-plan />
    </div>

    <div class="flex-items">
      <div class="card-base">
        <div class="card-headbar">
          <div class="plan-heading flex-container">
            <div class="plan-header-title">
              <span class="plan-small-des">Current Plan</span>
              <h2>{{ plan.type }}</h2>
            </div>
            <div class="plan-amount">
              <span class="amount"><sub>$</sub>{{ plan.amount }}<sub>/month</sub></span>
            </div>
          </div>
        </div>
        <div class="card-main">
          <div class="plan-features">
            <h2>Plan Features</h2>
            <ul>
              <li
                v-for="(feature, index) in plan.planFeatures"
                :key="index">
                <h3>{{ feature.feature }}</h3>
                <p>{{ feature.description }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-base contact-card">
        <div class="contact-support flex-container">
          <h2>Talk now with our Client Support Team</h2>
          <button
            class="btn-default btn-outline-white-blue"
            @click="toggleChatWidget">Live Chat</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PremiumPlan from './PremiumPlan'
export default {
  name: 'PremiumAccountModal',
  components: { PremiumPlan },
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleChatWidget() {
      this.$chat.toggle()
    }
  }
}
</script>
