export default {
  matchable: [
    { id: 'ProductDescription', priority: 7 },
    { id: 'ProductKeywords', priority: 23 },
    { id: 'HSCode', priority: 10 },
    { id: 'Consignee', priority: 2 },
    { id: 'ConsigneeAddress', priority: 4 },
    { id: 'ConsigneeStreetNumber', priority: 24 },
    { id: 'ConsigneeStreet', priority: 25 },
    { id: 'ConsigneeCity', priority: 26 },
    { id: 'ConsigneeState', priority: 27 },
    { id: 'ConsigneeZipCode', priority: 3 },
    { id: 'ConsigneePhoneNumber', priority: 28 },
    { id: 'ConsigneeEmailAddress', priority: 29 },
    { id: 'Shipper', priority: 11 },
    { id: 'ShipperAddress', priority: 12 },
    { id: 'NotifyParty', priority: 5 },
    { id: 'NotifyPartyAddress', priority: 6 },
    { id: 'MarksAndNumbers', priority: 9 },
    { id: 'USPort', priority: 18 },
    { id: 'DistributionPort', priority: 30 },
    { id: 'ForeignPort', priority: 14 },
    { id: 'CountryOfOrigin', priority: 13 },
    { id: 'PlaceOfReceipt', priority: 15 },
    { id: 'BillOfLading', priority: 8 },
    { id: 'BillTypeCode', priority: 31 },
    { id: 'MasterBillOfLading', priority: 32 },
    { id: 'InBondEntryType', priority: 33 },
    { id: 'NumberOfContainers', priority: 34 },
    { id: 'ContainerNumber', priority: 19 },
    { id: 'ContainerType', priority: 35 },
    { id: 'ContainerWidth', priority: 37 },
    { id: 'EquipmentDescriptionCode', priority: 38 },
    { id: 'SealNumbers', priority: 39 },
    { id: 'ManifestNumber', priority: 40 },
    { id: 'Measurement', priority: 36 },
    { id: 'MeasurementUnit', priority: 21 },
    { id: 'VesselName', priority: 20 },
    { id: 'CarrierName', priority: 16 },
    { id: 'CarrierCode', priority: 17 },
    { id: 'CarrierAddress', priority: 41 },
    { id: 'CarrierCity', priority: 42 },
    { id: 'CarrierState', priority: 43 },
    { id: 'CarrierZip', priority: 44 },
    { id: 'LoadStatus', priority: 45 },
    { id: 'Quantity', priority: 46 },
    { id: 'QuantityUnit', priority: 22 },
    { id: 'TypeOfService', priority: 47 },
    { id: 'VoyageNumber', priority: 48 },
    { id: 'AllFields', priority: 1 }
  ]
}
