import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import jwt from 'jsonwebtoken'
import $date from '~/plugins/date/methods'
import DecoratedAuth from '~/plugins/decoratedAuth'
import validateStorage from '~/utils/validateStorage'
import { userCreditMessage, subscriptionMessage } from '~/utils/errorMessage'
import { buildURLParams, buildQueryParams } from '~/utils/url'
import hotjar from '~/plugins/hotjar'
import gtm from '~/plugins/gtm'
import ga from '~/plugins/ga'
import TimePeriod from '~/utils/TimePeriod'
import { datadogRum } from '@datadog/browser-rum'
import installSwaychat from '~/plugins/chat/swaychat'

export const state = () => ({
  isBetaModalOpen: false,
  isMounted: false,
  isSidebarContentOpen: false,
  inFullscreen: false,
  popoverActive: false,
  scrolled: false,
  navFilterMobile: false,
  isIncompleteDataShown: true,
  autoscaleYAxis: true,
  isSidebarShown: true,
  countDownRefreshMsg: '',
  osVersion: '',
  error: {
    show: false,
    message: null
  },
  country: 'us',
  tradeType: 'import',
  tradeTypes: [
    {
      label: 'export',
      $isDisabled: true
    }
  ],

  dateRange: { from: null, to: null },
  dateInterval: 'weeks',
  selectedDatePreset: '',
  timezone: $date.getTimezone(),
  minDate: null,
  maxDate: null,

  rootLink: '/search?view=overview',
  baseLink: '/search?view=overview',

  loadedThirdPartyScripts: false,
  redirectToConsole: false,
  redirectNonMigratedAccount: false,

  // shipment that the user is currently viewing
  shipment: {},

  // starter announcement
  acceptedAnnouncement: false,
  isEligibleForStarterUpgrade: false
})

export const getters = {
  arrivalDateBetween(state) {
    const { from, to } = state.dateRange
    return { from: from / 1000, to: to / 1000 }
  },
  dateLimits(state) {
    return {
      from: state.minDate,
      to: state.maxDate
    }
  },
  currentSearchURL(state, getters) {
    const { currentSearchId } = state.search
    const { activeAggregateView: view } = state.views
    const currentPage = getters['search/activeViewCurrentPage']
    const pageLimit = getters['search/activeViewPageLimit']

    const URLParamsList = ['search', currentSearchId].filter(Boolean)
    const URLQueryDict = {
      view,
      ...(view !== 'analytics' && view !== 'overview'
        ? { p: currentPage }
        : {}),
      ...(view !== 'analytics' && view !== 'overview' ? { r: pageLimit } : {})
    }

    const URLParams = buildURLParams(URLParamsList)
    const URLQuery = buildQueryParams(URLQueryDict)

    return URLParams + URLQuery
  },
  shipmentAlertSearchURL(state, getters) {
    const { shipmentAlertId, resultId } = state.search.shipmentAlertResult
    const { activeAggregateView: view } = state.views
    const currentPage = getters['search/activeViewCurrentPage']
    const pageLimit = getters['search/activeViewPageLimit']

    const URLParamsList = ['shipment-alert', shipmentAlertId, resultId].filter(
      Boolean
    )
    const URLQueryDict = {
      view,
      ...(view !== 'analytics' ? { p: currentPage } : {}),
      ...(view !== 'analytics' ? { r: pageLimit } : {})
    }

    const URLParams = buildURLParams(URLParamsList)
    const URLQuery = buildQueryParams(URLQueryDict)

    return URLParams + URLQuery
  }
}

export const mutations = {
  mergeState(state, stateToMerge) {
    const initialState = _.cloneDeep(state)

    _.merge(state, stateToMerge)

    // merge has a bug that replaces top of view with
    if (initialState.views && initialState.views.views) {
      const views = JSON.parse(JSON.stringify(initialState.views.views))
      state.views.views = views
      state.views.activeShipmentsView = state.views.views.find(
        view => 'Legacy Order' === view.name
      )
    }
  },
  resetState(currentState) {
    Object.assign(currentState, state())
  },
  setIsSideBarShown(state, isSidebarShown) {
    state.isSidebarShown = isSidebarShown
  },
  setInFullscreen(state, inFullscreen) {
    state.inFullscreen = inFullscreen
  },
  setIsMounted(state, isMounted) {
    state.isMounted = isMounted
  },
  setIsIncompleteDataShown(state, isIncompleteDataShown) {
    state.isIncompleteDataShown = isIncompleteDataShown
  },
  setAutoscaleYAxis(state, autoscaleYAxis) {
    state.autoscaleYAxis = autoscaleYAxis
  },
  setScrolled(state, scrolled) {
    state.scrolled = !!scrolled
  },
  setPopoverActive(state, popoverActive) {
    state.popoverActive = !!popoverActive
  },
  setNavFilterMobile(state, navFilterMobile) {
    state.navFilterMobile = !!navFilterMobile
  },
  setCountry(state, country) {
    state.country = country
  },
  setTradeType(state, tradeType) {
    state.tradeType = tradeType
  },
  setDateLimits(state, { minDate, maxDate }) {
    state.minDate = minDate
    state.maxDate = maxDate
  },
  setCountDownRefreshMsg(state, msg) {
    state.countDownRefreshMsg = msg
  },
  setError(state, options) {
    state.error = options
  },
  setOs(state, osVersion) {
    state.osVersion = osVersion
  },

  setDateRange(state, dateRange) {
    if (!dateRange) return
    const { from, to } = dateRange

    if (!$date.validateUtcDate(from) || !$date.validateUtcDate(to)) {
      throw new Error(`Timestamps must be in UTC Unix format: ${from}-${to}`)
    }
    state.dateRange = { from, to }
    state.dateInterval = $date.getDateInterval(
      state.dateRange.from,
      state.dateRange.to
    )

    const preset = $date
      .getCommonDateRanges(state.maxDate)
      .find(
        preset =>
          preset.from === state.dateRange.from &&
          preset.to === state.dateRange.to
      )

    state.selectedDatePreset = preset ? preset.label : ''
  },
  setDateInterval(state, interval) {
    state.dateInterval = interval
  },
  toggleBetaModal(state, value) {
    state.isBetaModalOpen = value
  },
  setRootLink(state, link) {
    state.rootLink = link
  },
  setLoadedThirdParty(state) {
    state.loadedThirdPartyScripts = true
  },
  setSidebarContent(state, isOpen) {
    state.isSidebarContentOpen = isOpen
  },
  setRedirectToConsole(state, status) {
    state.redirectToConsole = status
  },
  setViewedShipment(state, shipment) {
    state.shipment = shipment
  },
  setRedirectNonMigratedAccount(state, status) {
    state.redirectNonMigratedAccount = status
  },
  setAnnouncementStatus(state, status) {
    state.acceptedAnnouncement = !!status
  },
  setEligibleStatus(state, status) {
    state.isEligibleForStarterUpgrade = status
  }
}

export const actions = {
  loadThirdPartyScripts({ state, commit }) {
    if (!state.loadedThirdPartyScripts) {
      setTimeout(() => {
        if (process.env.CHAT_MODE !== 'salesiq' && !window['_SwayChat']) {
          installSwaychat()
        }

        if (process.env.HOTJAR_ID) hotjar()

        if (process.env.GOOGLE_ANALYTICS) ga(this)
        if (process.env.GOOGLE_TRACKING_MANAGER) gtm()

        commit('setLoadedThirdParty')
      }, 1000)
    }
  },
  countDownRefresh({ commit }) {
    const todayUtc = this.$date.formatUtc(new Date(), 'yyyy-MM-dd HH:ii:ss')
    const tomUtc = this.$date.startOfDay(
      this.$date.addDays(new Date(todayUtc), 1)
    )
    commit(
      'setCountDownRefreshMsg',
      this.$date.formatDistanceStrict(new Date(tomUtc), new Date(todayUtc))
    )
  },
  showErrorUserCredits({ getters, commit, dispatch }) {
    const searchCredits = getters['userCredits/searchCredits']
    const isShipmentAlert = getters['search/isShipmentAlertSearch']

    if (searchCredits <= 0 && !isShipmentAlert) {
      commit('setError', { show: true, message: userCreditMessage })
      dispatch('countDownRefresh')
      return
    }
    commit('setError', {
      show: false,
      message: ''
    })
    commit('setCountDownRefreshMsg', '')
  },
  showShipmentAlertError({ getters, commit, dispatch }) {
    const shipmentAlertsResult = getters['search/shipmentAlertResult']
    const {
      criteria: { arrivalDates }
    } = shipmentAlertsResult

    const parsedDates = arrivalDates.split('-')
    const [from, to] = parsedDates
    const arrivalDateBetween = { from: +from * 1000, to: +to * 1000 }

    const withinSubscription = getters[
      'userSubscriptions/withinSubscriptionDateRange'
    ](arrivalDateBetween)

    if (!withinSubscription) {
      commit('setError', { show: true, message: subscriptionMessage })
      dispatch('countDownRefresh')
      return
    }
  },
  getOS({ commit }) {
    let platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      os = null

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows'
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux'
    }

    commit('setOs', os)
  },
  async nuxtServerInit(ctx) {
    // Needs to be in a try catch so that the error bubbles up
    // Not sure why...
    const { dispatch, state, getters } = ctx
    try {
      const updatedAppVersion = await dispatch('updateAppVersion')

      await dispatch('validateServiceWorkerCache', updatedAppVersion)

      if (!this.$auth.loggedIn) {
        return
      }

      dispatch('configureSentry')

      let [userSettings] = await Promise.all([
        dispatch('loadUserSettings'),
        dispatch('fetchSettingsViews'),
        dispatch('userCredits/getUserCredits'),
        dispatch('userSubscriptions/getUserSubscriptions')
      ])
      userSettings = {
        ...userSettings,
        maxDate: state.maxDate
      }

      await dispatch('userSubscriptions/getSubscriptionEndDate')

      const subscriptionDateRange =
        getters['userSubscriptions/subscriptionDateRange']

      if (userSettings) {
        const validatedState = validateStorage(
          ctx,
          userSettings,
          subscriptionDateRange
        )
        ctx.commit('mergeState', validatedState)
      }
      dispatch('showErrorUserCredits')
      dispatch('getOS')
      this.$storage.watch()
    } catch (error) {
      console.error(error) // eslint-disable-line
    }
  },
  async updateAppVersion() {
    if (!process.client) return

    let previousVersion, updatedVersion

    try {
      const APP_VERSION = 'app.version'
      const { version: serverVersion } = await this.$axios.$get('/api/version')
      const clientVersion = this.$cookies.get(APP_VERSION) || null

      this.$cookies.set(APP_VERSION, serverVersion)

      previousVersion = clientVersion
      updatedVersion = serverVersion
    } catch (err) {
      previousVersion = updatedVersion = null
      console.error(err)
    }

    return { previousVersion, updatedVersion }
  },
  async validateServiceWorkerCache(_ctx, { previousVersion, updatedVersion }) {
    const serviceWorkerActivated = 'caches' in window

    if (!(process.client && serviceWorkerActivated)) return

    try {
      if (!previousVersion || previousVersion !== updatedVersion) {
        const keys = await caches.keys()
        const runtimeKey = keys.find(key => key.match(/runtime/))

        if (runtimeKey) {
          await caches.delete(runtimeKey)
          console.info('Service worker runtime cache cleared')
        }
      }
    } catch (err) {
      console.error(err)
    }
  },
  configureSentry(ctx) {
    if (process.env.SENTRY_ENVIRONMENT !== 'local') {
      const user = ctx.state.auth.user
      Sentry.init({
        dsn: process.env.SENTRY_DSN,
        environment: process.env.SENTRY_ENVIRONMENT
      })
      Sentry.configureScope(function(scope) {
        scope.setUser({
          email: user.email,
          id: user.clientId
        })
      })
    }
  },
  resetStates({ commit }) {
    Object.keys(this._modulesNamespaceMap).forEach(moduleName => {
      const module = this._modulesNamespaceMap[moduleName]
      if (!module._rawModule.mutations.resetState) {
        return
      }
      commit(`${moduleName}resetState`)
    })
    commit('setIsSideBarShown', true)
  },
  async logout({ dispatch }) {
    try {
      await this.$auth.logout()
      dispatch('setUniversalCookie', '')
      this.$storage.unwatch()
      setTimeout(() => process.nextTick(() => dispatch('resetStates')), 1)
    } catch (error) {
      console.error(error) // eslint-disable-line
      dispatch('clearUserState')
    }
  },
  resetDateRange({ state, commit }) {
    const maxDate =
      state.maxDate ||
      $date.convertUTC($date.getPreviousDate(new Date(), 2).getTime())

    const oneDayAgo = $date.getPreviousDate(maxDate).getTime()
    const dateRange = { from: oneDayAgo, to: maxDate }

    commit('setDateRange', dateRange)
  },
  async setPopoverActive({ commit }, payload) {
    commit('setPopoverActive', payload)
  },
  async setScrolled({ commit }, payload) {
    commit('setScrolled', payload)
  },
  async setNavFilterMobile({ commit }, payload) {
    commit('setNavFilterMobile', payload)
  },
  async toggleNavFilterMobile({ commit, state }) {
    commit('setNavFilterMobile', !state.navFilterMobile)
  },
  async fetchSettings({ commit, state, dispatch }, resetDate = true) {
    const { id: clientId } = this.$auth.user
    const { data } = await this.$axios.$get(
      `${SHIPMENTS_API_URL}/v1/${state.country}/${
        state.tradeType
      }/shipment/settings`,
      {
        params: {
          clientId
        }
      },
      {
        progress: false
      }
    )

    commit('views/setFields', data.fields)

    // const minDate = +data.limits.ArrivalDate.min * 1000
    const maxDate = +data.limits.ArrivalDate.max * 1000

    // temporary until all data is in new ELK
    const minDate = TimePeriod.subtractDateRangeFromTimestamp(
      maxDate,
      '5 years'
    )
    if (!$date.validateUtcDate(minDate) || !$date.validateUtcDate(maxDate)) {
      console.error('Server min/max dates are not UTC')
    }
    commit('setDateLimits', { minDate, maxDate })
    if (resetDate) dispatch('resetDateRange')
  },
  async setCountry({ commit, dispatch, state }, payload) {
    if (payload === state.country) {
      return
    }

    if (payload === 'in') {
      commit('setTradeType', 'import')
    }

    commit('setCountry', payload)
    commit('search/setCurrentPage', 1)

    dispatch('search/clearFilters')
    dispatch('search/setDefaultMiscFilters')

    await Promise.all([
      dispatch('fetchSettingsViews'),
      dispatch('search/fetchShipmentsAndShipmentAggregates'),
      dispatch('analytics/fetchAnalyticsData')
    ])
  },
  async setTradeType({ commit, dispatch, state }, payload) {
    if (payload === state.tradeType) {
      return
    }

    commit('setTradeType', payload)
    commit('search/setCurrentPage', 1)
    dispatch('search/clearFilters')

    await dispatch('fetchSettingsViews')
    await Promise.all([
      dispatch('search/fetchShipmentsAndShipmentAggregates'),
      dispatch('analytics/fetchAnalyticsData')
    ])
  },
  async fetchSettingsViews({ commit, dispatch, getters }) {
    await Promise.all([
      dispatch('setDefaultDates'),
      dispatch('fetchSettings'),
      dispatch('views/fetchViews').then(() =>
        commit('views/setActiveShipmentsView', getters['views/getDefaultView'])
      )
    ])
  },
  async setDefaultDates({ commit }) {
    const minDate = new Date('January 14, 2019').getTime()
    const maxDate = new Date().getTime()
    commit('downloads/setDateLimits', { minDate, maxDate })
    commit('search/setBookmarkDateLimits', { minDate, maxDate })
  },
  clearUserState() {
    this.$auth.$storage.setState('loggedIn', false)
    this.$auth.$storage.setState('user', false)
    this.$auth.reset()
  },

  async autologin() {
    const decoratedAuth = new DecoratedAuth(this.$auth, this.$axios)
    await decoratedAuth.autologin()
  },

  async validateUserAuth({ dispatch }) {
    const isngCookie = this.$auth.$storage.getCookies().isng_access_login_id

    const universalCookie = this.$auth.$storage.getUniversal('isngCookie')
    if (
      universalCookie != isngCookie &&
      isngCookie != '' &&
      this.$auth.loggedIn === true
    ) {
      await dispatch('autologin')
      dispatch('setUniversalCookie', isngCookie)
    } else if (isngCookie && this.$auth.loggedIn === false) {
      await dispatch('autologin')
    } else if (isngCookie != universalCookie) {
      dispatch('clearUserState')
      dispatch('logout')
      return
    }
  },

  setUniversalCookie(app, cookie) {
    this.$auth.$storage.setUniversal('isngCookie', cookie)
  },

  async validateAuth({ dispatch }) {
    try {
      const token = this.$auth.getToken(this.$auth.strategy.name)

      if (!token) {
        return dispatch('clearUserState')
      }
      const { clientId, username, exp } = jwt.decode(
        token.replace('Bearer ', '')
      )

      const timeNow = Date.now() / 1000
      if (exp < timeNow) {
        return dispatch('clearUserState')
      }

      if (typeof FS !== 'undefined') {
        FS.identify(clientId, { displayName: username })
      }

      datadogRum.setUser({
        id: clientId,
        name: username
      })

      const client = await this.$axios.$get(`${USERS_API_URL}/client/details`)

      this.$auth.$storage.setState('user', {
        clientId,
        username: client.data.username.trim(),
        email: client.data.email,
        firstName: client.data.firstname,
        lastName: client.data.lastname,
        login_tour_state: client.data.login_tour_state,
        zero_results_state: client.data.zero_results_state,
        image_url: client.data.image_url
      })

      dispatch('accounts/fetchAccountInfo', client.data)

      // if customer.io has been loaded, identify the user
      if (global._cio) {
        global._cio.identify({
          id: clientId,
          email: client.data.email,
          firstName: client.data.first_name,
          lastName: client.data.last_name
        })

        global._cio.page(document.location.origin + document.location.pathname)
      }
    } catch (error) {
      dispatch('clearUserState')
      console.error(error) // eslint-disable-line
    }
  },
  loadUserSettings(store, options) {
    this.$storage.key = this.$auth.user.email
    return this.$storage.load(options)
  },
  async updateStarterAnnouncementStatus({ commit }, payload) {
    const { id: clientId } = this.$auth.user
    const { isSeen } = payload

    try {
      await this.$axios.put(
        `${USERS_API_URL}/v2/client/${clientId}/announcement`,
        {
          ...payload
        }
      )

      if (isSeen) return

      commit('setAnnouncementStatus', payload.accepted)
    } catch (e) {
      if (
        e.response.data.error.message ===
        'Starter upgrade activity already exist in the database'
      ) {
        commit('setAnnouncementStatus', payload.accepted)
      }
      return e && e.response && e.response.data
    }
  }
}
