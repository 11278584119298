import { omit } from 'lodash'
import axiosCancel from 'axios-cancel'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import methods from '~/plugins/mixins/methods.js'

export default function({ $axios, app }) {
  axiosCancel($axios)
  $axios.isCancel = axios.isCancel
  // Will retry non-POST methods, 5xx errors, and network errors.
  axiosRetry($axios, {
    retries: 1,
    retryDelay: axiosRetry.exponentialDelay
  })
  $axios.onRequest(request => {
    request.headers['Content-Type'] = 'application/json'
  })
  $axios.onResponse(response => {
    const { data } = response
    if (methods.typeof(data) === 'object') {
      response.data = omit(data, 'profiler')
    }
  })
  $axios.onError(async error => {
    const { response, request } = error
    if (
      response &&
      response.status === 401 &&
      request.responseURL.includes('/api/client-info')
    ) {
      if (app.$auth.loggedIn) await app.$auth.logout()
      return new Promise(() => {})
    }
    return Promise.reject(error)
  })
}
