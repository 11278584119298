export default async ({ store, redirect, $axios, params }) => {
  const remainingSearchCredits = store.getters['userCredits/searchCredits']
  const { searchId } = params

  const { clientId } = this.$auth.user
  let response =
    searchId && store.state.search.currentSearchId !== searchId
      ? await $axios.$get(
          `${USERS_API_URL}/v1/bookmarks/${clientId}/${searchId}`
        )
      : null

  if (!remainingSearchCredits && response && !response.isBookmarked) {
    redirect(
      REDIRECT_URLS.UPGRADE_SUBSCRIPTION_PLACEHOLDER /*eslint-disable-line no-undef */
    )
  }
}
