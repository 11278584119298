import $date from '~/plugins/date/methods'

export const validateDateRange = (state, dateRange = {}) => {
  const { minDate, maxDate } = state
  const { from, to } = dateRange
  return from && to && from >= minDate && to <= maxDate
}

export const validateDatePreset = (state, preset, maxDate) => {
  return preset
    ? $date.getCommonDateRanges(maxDate).find(({ label }) => label === preset)
    : null
}

export const validateTimezoneOffset = date => {
  return $date.validateUtcDate(date) ? date : $date.convertUTC(date)
}

export const validateSorts = (state, storage) => {
  //Validate search/company settings (pageLimit, sortField, sortOrder)
  let sortOrder = null
  let sortField = null
  const { sortOrders } = state.search
  const { activeShipmentsView, fields } = state.views
  const activeViewFields = activeShipmentsView.items.map(id => ({
    id,
    ...fields[id]
  }))

  // sortOrder
  if (sortOrders.includes(storage.sortOrder)) {
    sortOrder = storage.sortOrder
  }

  // sortField
  if (storage.sortField) {
    const templateField = activeViewFields.find(
      field => field.id === storage.sortField
    )
    if (templateField && templateField.sortable) {
      sortField = templateField.id
    }
  }

  return { sortOrder, sortField }
}

export const validatePageLimit = (state, pageLimit) => {
  return state.search.pageLimits.includes(pageLimit)
}
