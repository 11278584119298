import { SubscriptionFeatures, PlanNames } from '~/utils/constants/subscription'
import TimePeriod from '~/utils/TimePeriod'
import { fromUnixTime } from '~/utils/date-fns'
import { isBefore, isAfter, isEqual } from 'date-fns'

export const state = () => ({
  features: {},
  plans: [],
  userHasAnalyticsSubscription: true,
  userHasVisualMapSubscription: false,
  openUpgradeModal: false,
  subscriptionEndDate: '',
  recurlySubscriptions: []
})

export const getters = {
  subscriptionPlanType: state => {
    return (
      state.plans.find(plan => {
        return !!PlanNames[plan.name]
      }) || state.plans[0]
    )
  },
  hasPremiumSubscription(state) {
    return state.plans.some(({ name }) => name === 'Premium&')
  },
  hasPlanLimited: state => {
    return state.plans.some(
      ({ legacyPlanId }) => legacyPlanId === 12 || legacyPlanId === 30
    )
  },
  hasNupiPlan: state => {
    return state.plans.some(({ displayName }) => displayName.includes('Non US'))
  },
  isAdmin(state) {
    return state.plans.some(({ name }) => name === 'Admin')
  },
  subscriptionDateRange: (state, getters, rootState) => {
    const dateRange =
      state.features && state.features[SubscriptionFeatures.DATE_RANGE]
    let sanitized = {
      from: rootState.maxDate,
      to: rootState.maxDate
    }

    if (dateRange.toLowerCase() === 'all data' || getters.isAdmin) {
      sanitized.from = TimePeriod.subtractDateRangeFromTimestamp(
        sanitized.to,
        '5 years'
      )
    } else {
      sanitized.from = TimePeriod.subtractDateRangeFromTimestamp(
        sanitized.to,
        dateRange
      )

      if (sanitized.from < rootState.minDate) sanitized.from = rootState.minDate
    }

    return sanitized
  },
  withinSubscriptionDateRange: (_state, getters) => givenDateRange => {
    const { subscriptionDateRange } = getters

    const formattedDate = {
      subscription: {
        from: fromUnixTime(subscriptionDateRange.from),
        to: fromUnixTime(subscriptionDateRange.to)
      },
      given: {
        from: fromUnixTime(givenDateRange.from),
        to: fromUnixTime(givenDateRange.to)
      }
    }

    const withinFrom =
      isBefore(formattedDate.subscription.from, formattedDate.given.from) ||
      isEqual(formattedDate.subscription.from, formattedDate.given.from)

    const withinTo =
      isAfter(formattedDate.subscription.to, formattedDate.given.to) ||
      isEqual(formattedDate.subscription.to, formattedDate.given.to)

    return withinFrom && withinTo
  },
  upgradeModalIsOpened: ({ openUpgradeModal }) => {
    return openUpgradeModal
  },
  hasAccessToAllData: (_state, getters, rootState) => {
    const { subscriptionDateRange } = getters
    const { minDate, maxDate } = rootState

    return (
      subscriptionDateRange.from <= minDate &&
      subscriptionDateRange.to >= maxDate
    )
  },
  recurlyPlans: state => {
    const plans = []
    for (let i = 0; i < state.recurlySubscriptions.length; i++) {
      const { plan } = state.recurlySubscriptions[i]
      plans.push(plan.code)
    }

    return plans
  },
  subscription: state => {
    const plan =
      state.plans.find(plan => {
        return !!PlanNames[plan.name]
      }) || state.plans[0]

    return {
      plan: {
        ...plan,
        type: PlanNames[plan.name]
      },
      features: state.features
    }
  },
  subscriptionNonUs: state => {
    const excludedPlans = ['plus', 'starter', 'premium']
    return filterSubscriptions(state.recurlySubscriptions, excludedPlans)[0]
  },
  subscriptionEndDate: state => {
    return state.subscriptionEndDate
  },
  hasActiveLegacySubscription: state => {
    const plans = ['1 country', '3 countries', 'all countries']
    return state.recurlySubscriptions.some(subscription =>
      plans.some(
        plan =>
          subscription.plan.name.toLowerCase().includes(plan) &&
          subscription.state === 'active'
      )
    )
  },
  hasPendingNonUsSubscription: state => {
    return state.recurlySubscriptions.some(
      subscription =>
        subscription.plan.name.includes('Non US') &&
        subscription.pendingChange !== null
    )
  },
  nonUsSubscriptionAmount: state => {
    const excludedPlans = ['non us', 'plus', 'starter', 'premium']
    const nonUsSubscriptions = filterSubscriptions(
      state.recurlySubscriptions,
      excludedPlans
    )
    return nonUsSubscriptions.reduce(
      (total, subscription) => total + subscription.subtotal,
      0
    )
  }
}

export const mutations = {
  setUserSubscriptions(state, subscription) {
    state.plans = subscription.plans
    state.features = subscription.features
  },
  setUserHasAnalyticsSubscription(state, features) {
    state.userHasAnalyticsSubscription =
      features && features[SubscriptionFeatures.ANALYTICS]
  },
  setUserHasVisualMappingSubscription(state, features) {
    state.userHasVisualMappingSubscription =
      features && features[SubscriptionFeatures.VISUAL_MAPPING]
  },
  setOpenUpgradeModal(state, isOpen) {
    state.openUpgradeModal = isOpen
  },
  setSubscriptionEndDate(state, subscriptionEndDate) {
    state.subscriptionEndDate = subscriptionEndDate
  },
  setRecurlySubscriptions(state, recurlySubscriptions) {
    state.recurlySubscriptions.splice(
      0,
      state.recurlySubscriptions.length,
      ...recurlySubscriptions
    )
  }
}

export const actions = {
  async getUserSubscriptions({ commit }) {
    try {
      const { id: clientId } = this.$auth.user
      const { data } = await this.$axios.$get(
        `${SUBSCRIPTIONS_API_URL}/clients/${clientId}/access`,
        {},
        { progress: false }
      )
      await commit('setUserSubscriptions', data)
      commit('setUserHasAnalyticsSubscription', data.features)
      commit('setUserHasVisualMappingSubscription', data.features)
    } catch (error) {
      if (this.$axios.isCancel(error)) return
      throw error
    }
  },

  async getSubscriptionEndDate({ commit, getters }) {
    try {
      const { id: clientId } = this.$auth.user
      const { id: planId } = getters.subscriptionPlanType
      const { data } = await this.$axios.$get(
        `${SUBSCRIPTIONS_API_URL}/subscriptions/?clientId=${clientId}`,
        {},
        { progress: false }
      )

      const subscription = data.find(sub => sub.planId === planId)
      commit('setSubscriptionEndDate', subscription.subscriptionEnd)
    } catch (error) {
      if (this.$axios.isCancel(error)) return
      throw error
    }
  },

  async fetchRecurlySubscriptions({ commit }) {
    const accountCode = this.$auth.user.account_code

    if (!accountCode) return

    try {
      const { data } = await this.$axios.$get(
        // eslint-disable-next-line no-undef
        `${PAYMENTS_API_URL}/accounts/code-${accountCode}/subscriptions`
      )
      const activeRecurlySubscriptions = data.filter(
        subscription => subscription.state === 'active'
      )

      commit('setRecurlySubscriptions', activeRecurlySubscriptions)
    } catch (e) {
      console.error('Unable to fetch Recurly subscription: ', e)
    }
  }
}

// Helper function to filter subscriptions by planName
function filterSubscriptions(subscriptions, excludedPlans) {
  return subscriptions.filter(subscription => {
    const planName = subscription.plan.name.toLowerCase()
    return (
      !excludedPlans.some(plan => planName.includes(plan)) &&
      subscription.state === 'active'
    )
  })
}
