<template>
  <el-tooltip
    :disabled="isChatReady"
    content="Please wait while the chat loads">
    <a
      :class="{
        'chat-icon-disabled': !isChatReady,
        'active': NoResultsTour.currentStep === 1
      }"
      class="chat-icon no-results-tour-1"
      @click="toggleChatWidget"/>
  </el-tooltip>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ChatButton',
  data() {
    return {
      isChatReady: false
    }
  },
  computed: {
    ...mapGetters('accounts', ['email', 'name', 'phone']),
    ...mapState('tours', ['NoResultsTour'])
  },
  mounted() {
    const elementId = this.$chat.elementId
    // Check if SalesIQ has loaded after render
    if (document.getElementById(elementId)) {
      this.isChatReady = true
      this.setUserInChat()
    } else {
      // if chat widget is not yet ready, observe until ready
      const mutationObserver = new MutationObserver(() => {
        if (document.getElementById(elementId)) {
          mutationObserver.disconnect()
          this.isChatReady = true
          this.setUserInChat()
        }
      })

      mutationObserver.observe(document.body, { childList: true })
    }
  },
  methods: {
    toggleChatWidget() {
      this.noResultsTourEnd()

      if (process.env.CHAT_MODE === 'salesiq') {
        this.$chat.toggle()
      } else {
        // SwayChat-specific logic for display
        const chatWidgetEl = document.getElementById(this.$chat.elementId)
        const oldDisplayStyle = window.getComputedStyle(chatWidgetEl).display
        this.$chat.toggle()
        const newDisplayStyle = window.getComputedStyle(chatWidgetEl).display

        // Open popout if no agents are online
        if (oldDisplayStyle === newDisplayStyle && oldDisplayStyle === 'none') {
          this.$chat.closePopout()
          this.$chat.openPopout()
        }
      }
    },
    noResultsTourEnd() {
      const NoResultsTour = this.$tours.NoResultsTour

      if (
        NoResultsTour &&
        NoResultsTour.isRunning &&
        NoResultsTour.currentStep === 1
      ) {
        NoResultsTour.stop()
      }
    },
    setUserInChat() {
      this.$chat.setUser({
        name: this.name,
        email: this.email,
        phone: this.phone
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-icon-disabled {
  display: none;
}
</style>
