import options from './native-chart-js-options'
import colors from './colors'

export default {
  // data related
  keys: {
    x: 'label',
    y: ['imports']
  },

  // specific settings
  point: {
    colors: colors.line,
    radius: 4,
    border: {
      color: '#ffffff',
      width: 2
    },
    hover: {
      color: ['black', '#2fadee'],
      radius: 6,
      border: {
        color: '#ffffff',
        width: 2
      }
    }
  },
  line: {
    colors: [
      '#EC7063',
      '#AF7AC5',
      '#5DADE2',
      '#48C9B0',
      '#F5B041',
      '#B2B3FF',
      '#F8C471',
      '#F1948A',
      '#76D7C4',
      '#85C1E9',
      '#58D68D',
      '#F4D03F',
      '#AED6F1',
      '#B27BFC',
      '#A0EFDA',
      '#E21427',
      '#A52DF4',
      '#FF7F00',
      '#02AB3E',
      '#F5C000',
      '#1466E2',
      '#E11279',
      '#AE0110',
      '#6B0FB7',
      '#BB5400',
      '#00652E',
      '#936401',
      '#0023B9',
      '#A90060',
      '#660008',
      '#350766',
      '#752700',
      '#004021',
      '#543700',
      '#080080',
      '#5C003C',
      '#FFE0E4',
      '#F6E5FF',
      '#FFEBCC',
      '#D7FEE4',
      '#FFF5B8',
      '#E2F3FE',
      '#FED7EB'
    ],
    width: 1,
    backgroundColor: 'transparent',
    tension: 0
  },
  bar: {
    colors: colors.bar,
    border: {
      color: '#ffffff',
      width: 0
    }
  },
  pie: {
    colors: colors.pie,
    otherColor: '#15a2ed',
    border: {
      color: '#ffffff',
      width: 0
    },
    padding: 10,
    pieceLabel: {
      fontColor: 'white',
      fontSize: 10,
      fontStyle: 'bold',
      overlap: true
    }
  },
  xAxes: {
    gridLines: {
      display: false,
      drawBorder: false,
      tickMarkLength: 20
    },
    ticks: {
      maxTicksLimit: 15,
      maxRotation: 0,
      minRotation: 0,
      fontSize: 11,
      fontColor: 'rgba(52, 64, 80, 0.7)'
    }
  },
  yAxes: {
    gridLines: {
      color: 'rgba(0,0,0,0.05)',
      drawBorder: false,
      zeroLineColor: 'rgba(0,0,0,0.05)'
    },
    ticks: {
      fontSize: 10,
      fontColor: 'rgba(52, 64, 80, 0.4)'
    }
  },
  options
}
