import {
  validateDateRange,
  validatePageLimit
  // validateDatePreset
} from './helper-functions'
import $date from '../../plugins/date/methods/index'

export default function validateCompany({ state, getters }, storage) {
  const company = {}

  // const presetDate = validateDatePreset(
  //   state,
  //   storage.selectedDatePreset,
  //   state.maxDate
  // )
  // if (presetDate) {
  //   storage.dateRange = presetDate
  // }

  // dateRange
  /* set subscription date range as initial selected
     date range when viewing company profile */
  const subscriptionDateRange =
    getters['userSubscriptions/subscriptionDateRange']
  if (validateDateRange(state, subscriptionDateRange)) {
    const { from, to } = subscriptionDateRange
    company.dateRange = subscriptionDateRange
    company.dateInterval = $date.getDateInterval(from, to)
  }

  // stat
  if (state.company.shipmentTypes.concat(['all']).includes(storage.stat)) {
    company.stat = storage.stat
  }

  // sortOrder
  if (state.search.sortOrders.includes(storage.sortOrder)) {
    company.sortOrder = storage.sortOrder
  }

  // sortField
  if (
    storage.sortField &&
    getters['company/templateFields'].find(
      field => field.id === storage.sortField
    )
  ) {
    company.sortField = storage.sortField
  }

  // pageLimit
  if (validatePageLimit(state, storage.pageLimit)) {
    company.pageLimit = storage.pageLimit
  }

  return company
}
