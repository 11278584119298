module.exports = {
  incomplete: [],
  line: [
    '#EC7063',
    '#AF7AC5',
    '#5DADE2',
    '#48C9B0',
    '#F5B041',
    '#B2B3FF',
    '#F8C471',
    '#F1948A',
    '#76D7C4',
    '#85C1E9',
    '#58D68D',
    '#F4D03F',
    '#AED6F1',
    '#B27BFC',
    '#A0EFDA'
  ],
  bar: [
    '#EC7063',
    '#AF7AC5',
    '#5DADE2',
    '#48C9B0',
    '#F5B041',
    '#B2B3FF',
    '#F8C471',
    '#F1948A',
    '#76D7C4',
    '#85C1E9'
  ],
  pie: [
    [
      '#F4D03F',
      '#AED6F1',
      '#B27BFC',
      '#A0EFDA',
      '#7F93FF',
      '#F5B7B1',
      '#B2B3FF',
      '#F8C471',
      '#CACFD2',
      '#EC7063',
      '#82E0AA',
      '#92CCF7',
      '#F9E79F',
      '#EB984E',
      '#58D68D'
    ],
    [
      '#82E0AA',
      '#F1948A',
      '#AED6F1',
      '#F4D03F',
      '#C39BD3',
      '#F9D6D3',
      '#D5F69C',
      '#FFAA7F',
      '#E8DAEF',
      '#FDEBD0',
      '#F5B7B1',
      '#A3E4D7',
      '#F9E79F',
      '#92CCF7',
      '#B2B3FF'
    ]
  ]
}
