<template>
  <div class="selected-plan">
    <div class="plan-heading flex-container">
      <div class="plan-header-title">
        <span class="plan-small-des">Empire Builder</span>
        <h2>Premium</h2>
      </div>
      <div class="plan-amount">
        <span class="amount"><sub>$</sub>399<sub>/month</sub></span>
      </div>
    </div>
    <div class="plan-features">
      <ul>
        <li>View all Historical Data back to 2006</li>
        <li>Complete U.S. export data</li>
        <li>Expand from 25 to 50 searches per day</li>
        <li>Download 25k records per month<br>
          (XLSX, XLS, and CSV)</li>
        <li>50 Contact requests per month</li>
        <li>10 Company Email Alerts</li>
        <li>Visual Mapping</li>
        <li>Customer Support</li>
        <li>Dedicated Research Assistant</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PremiumPlan'
}
</script>
