<template>
  <ContentLoader v-bind="$props">
    <slot/>
  </ContentLoader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'DefaultContentLoader',
  components: {
    ContentLoader
  },
  props: {
    width: {
      type: Number,
      default: undefined
    },
    height: {
      type: Number,
      default: undefined
    },
    speed: {
      type: Number,
      default: undefined
    },
    preserveAspectRatio: {
      type: Number,
      default: undefined
    },
    primaryColor: {
      type: String,
      default: '#e7e7e7' // dependency default is #f9f9f9
    },
    secondaryColor: {
      type: String,
      default: '#dcdbdb' // dependency default is #ecebeb
    },
    uniqueKey: {
      type: String,
      default: undefined
    },
    animate: {
      type: Boolean,
      default: undefined
    },
    baseUrl: {
      type: String,
      default: undefined
    },
    primaryOpacity: {
      type: Number,
      default: undefined
    },
    secondaryOpacity: {
      type: Number,
      default: undefined
    }
  }
}
</script>
