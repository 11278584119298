const middleware = {}

middleware['analyticsSubscriptionIsRequired'] = require('../src/middleware/analyticsSubscriptionIsRequired.js')
middleware['analyticsSubscriptionIsRequired'] = middleware['analyticsSubscriptionIsRequired'].default || middleware['analyticsSubscriptionIsRequired']

middleware['autologin'] = require('../src/middleware/autologin.js')
middleware['autologin'] = middleware['autologin'].default || middleware['autologin']

middleware['check-referrer'] = require('../src/middleware/check-referrer.js')
middleware['check-referrer'] = middleware['check-referrer'].default || middleware['check-referrer']

middleware['checkAuth'] = require('../src/middleware/checkAuth.js')
middleware['checkAuth'] = middleware['checkAuth'].default || middleware['checkAuth']

middleware['companyViewCreditsIsRequired'] = require('../src/middleware/companyViewCreditsIsRequired.js')
middleware['companyViewCreditsIsRequired'] = middleware['companyViewCreditsIsRequired'].default || middleware['companyViewCreditsIsRequired']

middleware['fetch-starter-announcement'] = require('../src/middleware/fetch-starter-announcement.js')
middleware['fetch-starter-announcement'] = middleware['fetch-starter-announcement'].default || middleware['fetch-starter-announcement']

middleware['searchCreditsIsRequired'] = require('../src/middleware/searchCreditsIsRequired.js')
middleware['searchCreditsIsRequired'] = middleware['searchCreditsIsRequired'].default || middleware['searchCreditsIsRequired']

middleware['termsIsRequired'] = require('../src/middleware/termsIsRequired.js')
middleware['termsIsRequired'] = middleware['termsIsRequired'].default || middleware['termsIsRequired']

middleware['validateAuth'] = require('../src/middleware/validateAuth.js')
middleware['validateAuth'] = middleware['validateAuth'].default || middleware['validateAuth']

middleware['verify-cookie-login-id'] = require('../src/middleware/verify-cookie-login-id.js')
middleware['verify-cookie-login-id'] = middleware['verify-cookie-login-id'].default || middleware['verify-cookie-login-id']

middleware['visualMappingSubscriptionIsRequired'] = require('../src/middleware/visualMappingSubscriptionIsRequired.js')
middleware['visualMappingSubscriptionIsRequired'] = middleware['visualMappingSubscriptionIsRequired'].default || middleware['visualMappingSubscriptionIsRequired']

export default middleware
