export default class KeyboardEventsHandler {
  constructor() {
    if (KeyboardEventsHandler.instantiationGuard) {
      throw new Error(
        'KeyboardEventsHandler is a singleton class and an instance can only ' +
          'be obtained through getInstance static method.'
      )
    }
    this.isPressed = this.isPressed.bind(this)
    this.recordKeyDown = this.recordKeyDown.bind(this)
    this.recordKeyUp = this.recordKeyUp.bind(this)
    this.register = this.register.bind(this)
    this.unregister = this.unregister.bind(this)
    this.options = { passive: true }
    this.pressedKeys = []
  }

  isPressed(key) {
    key = KeyboardEventsHandler.normalize(key)

    return this.pressedKeys.includes(key)
  }

  recordKeyDown({ key }) {
    key = KeyboardEventsHandler.normalize(key)

    if (this.pressedKeys.includes(key)) return

    this.pressedKeys.push(key)
  }

  recordKeyUp({ key }) {
    key = KeyboardEventsHandler.normalize(key)

    this.pressedKeys = this.pressedKeys.filter(pressedKey => pressedKey !== key)
  }

  register() {
    window.addEventListener('keyup', this.recordKeyUp, this.options)
    window.addEventListener('keydown', this.recordKeyDown, this.options)
  }

  unregister() {
    window.removeEventListener('keyup', this.recordKeyUp)
    window.removeEventListener('keydown', this.recordKeyDown)
  }

  static normalize(key) {
    return key ? key.toLowerCase() : ''
  }

  static getInstance() {
    if (!KeyboardEventsHandler.singletonInstance) {
      KeyboardEventsHandler.instantiationGuard = false
      KeyboardEventsHandler.singletonInstance = new KeyboardEventsHandler()
      KeyboardEventsHandler.instantiationGuard = true
    }

    return KeyboardEventsHandler.singletonInstance
  }
}

KeyboardEventsHandler.singletonInstance = null
KeyboardEventsHandler.instantiationGuard = true
