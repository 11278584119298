export default function validateAnalytics(ctx, storage) {
  const { analytics: state } = ctx.state
  const analytics = {}

  if (typeof storage.isResultsClean === 'boolean') {
    analytics.isResultsClean = storage.isResultsClean
  }

  if (Object.keys(state.chartTypeOptions).includes(storage.chartType)) {
    analytics.chartType = storage.chartType
  }

  if (Object.keys(state.measurementOptions).includes(storage.measurement)) {
    analytics.measurement = storage.measurement
  }

  if (Object.keys(state.fieldOptions).includes(storage.field)) {
    analytics.field = storage.field
  }

  return analytics
}
