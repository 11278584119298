<template>
  <div class="selected-plan">
    <div class="plan-heading flex-container">
      <div class="plan-header-title">
        <span class="plan-small-des">Small &amp; Medium Business</span>
        <h2>Plus</h2>
      </div>
      <div class="plan-amount">
        <span class="amount"><sub>$</sub>199<sub>/month</sub></span>
      </div>
    </div>
    <div class="plan-features">
      <ul>
        <li>12 months of U.S. import data  for all companies</li>
        <li>Complete U.S. export data</li>
        <li>25 searches per day </li>
        <li>Download 10k records per month<br>
          (XLSX, XLS, and CSV)</li>
        <li>25 Contact requests per month</li>
        <li>5 Company Email Alerts</li>
        <li>Visual Mapping</li>
        <li>Customer Support</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlusPlan'
}
</script>
