<template>
  <el-popover
    ref="popover"
    trigger="click"
    placement="bottom"
    title="Notifications"
    popper-class="notification-container">
    <div slot="reference">
      <span class="notifications" />
      <a
        rel="popover"
        class="notification-icon"
        role="button"
        @click.prevent/>
    </div>
    <div
      slot="content"
      class="pop-over-default with-footer-button myNotifications">
      <span class="item-count red-bg absolute-zero with-right-pad">3</span>
      <span class="removed-icon absolute-zero right" />
      <ul>
        <li>
          <a href="#">
            You exported <strong>test.xls</strong> completed after 48 seconds.
            <span>2 mins ago</span>
          </a>
        </li>
        <li>
          <a href="#">
            You exported <strong>test2.xls</strong> completed after 48 seconds.
            <span>May 19, 2017</span>
          </a>
        </li>
        <li>
          <a href="#">
            You exported <strong>test3.xls</strong> completed after 48 seconds.
            <span>May 19, 2017</span>
          </a>
        </li>
      </ul>
      <a
        href="#"
        class="btn btn-text">View All</a>
    </div>
  </el-popover>
</template>

<script>
export default {
  methods: {
    togglePopover() {
      this.$refs.popover.show = false
    }
  }
}
</script>

<style src="~/assets/scss/sections/navbar/notifications.scss" lang="scss" />
