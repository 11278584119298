global.SHIPMENTS_API_URL = process.env.SHIPMENTS_API_URL
global.USERS_API_URL = process.env.USERS_API_URL
global.SUBSCRIPTIONS_API_URL = process.env.SUBSCRIPTIONS_API_URL
global.PAYMENTS_API_URL = process.env.PAYMENTS_API_URL
global.COMPANY_API_URL = process.env.COMPANY_API_URL
global.REDIRECT_URLS = {
  UPGRADE_SUBSCRIPTION_PLACEHOLDER:
    'https://app.importgenius.com/help/default_page/',
  LEGACY_APP_LOGIN: `${process.env.LEGACY_APP_URL}/`,
  CONSOLE_APP_URL: process.env.CONSOLE_APP_URL,
  SITE_URL: process.env.SITE_URL
}

global.PROMO_URL = `${process.env.LEGACY_APP_URL}/promo`

global.ISNG_TITLE = 'ImportGenius Lighthouse (Beta)'
