export default {
  transition: {
    beforeEnter() {
      if (this.$route.name.substring(0, 5) !== 'index')
        this.$store.commit('setIsSideBarShown', false)
      else this.$store.commit('setIsSideBarShown', true)
    },
    afterLeave() {
      if (this.$route.name.substring(0, 5) === 'index')
        this.$store.commit('setIsSideBarShown', true)
    }
  }
}
