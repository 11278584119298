<template>
  <ul class="el-pager">
    <li
      v-if="pageCount > 0 && showPrevJump"
      :class="{ active: currentPage === 1 }"
      class="number"
      tabindex="0"
      @click="changePage(1)">
      {{ firstPageText }}
    </li>
    <li
      v-if="showPrevJump"
      class="el-icon more btn-quickprev el-icon-d-arrow-left"
      tabindex="0"
      @click="changePage(currentPage - pageJumpCount)"/>
    <li
      v-if="showPrev"
      :class="{ disabled: $parent.internalCurrentPage <= 1 }"
      class="btn-prev el-icon el-icon-arrow-left"
      tabindex="0"
      @click.stop="$parent.prev"/>
    <li
      v-if="pageCount > 0 && !showPrevJump"
      :class="{ active: currentPage === 1 }"
      class="number"
      tabindex="0"
      @click="changePage(1)">
      {{ firstPageText }}
    </li>
    <li
      v-for="(pager, index) in pagers"
      :key="index"
      :class="{ active: currentPage === pager }"
      class="number"
      tabindex="0"
      @click="changePage(pager)">
      {{ pager }}
    </li>
    <li
      v-if="!showNextJump && pageCount > 1 && showLast"
      :class="{ active: currentPage === pageCount }"
      class="number"
      tabindex="0"
      @click="changePage(pageCount)">
      {{ lastPageText }}
    </li>
    <li
      v-if="showNext"
      :class="{ disabled: currentPage === pageCount || pageCount === 0 }"
      class="btn-next el-icon el-icon-arrow-right"
      tabindex="0"
      @click="$parent.next"/>
    <li
      v-if="showNextJump"
      class="el-icon more btn-quicknext el-icon-d-arrow-right"
      tabindex="0"
      @click="changePage(currentPage + pageJumpCount)"/>
    <li
      v-if="showNextJump && pageCount > 1 && showLast"
      :class="{ active: currentPage === pageCount }"
      class="number"
      tabindex="0"
      @click="changePage(pageCount)">
      {{ lastPageText }}
    </li>
  </ul>
</template>

<script type="text/babel">
/* eslint-disable */
export default {
  name: 'Pager',

  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    pagerLimit: {
      type: Number,
      default: 6
    },
    pageCount: {
      type: Number,
      default: 0
    },
    showLast: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    pageJumpCount() {
      return Math.floor(this.pagerLimit / 2)
    },
    firstPageText() {
      if (!this.showPrevJump) {
        return 1
      }
      return 'First'
    },
    lastPageText() {
      if (!this.showNextJump) {
        return this.pageCount
      }
      return 'Last'
    },
    showPrev(){
      return this.currentPage > 1 && this.pageCount >= this.pagerLimit
    },
    showPrevJump() {
      return this.currentPage > this.pagerLimit - this.pageJumpCount
    },
    showNext(){
      const currentPageIsLessThanLastPage = this.currentPage < this.pageCount
      const totalPagesIsNotZero = this.pageCount !== 0
      const totalPagesIsMoreThanPagerLimit = this.pageCount >= this.pagerLimit
      return currentPageIsLessThanLastPage && totalPagesIsNotZero && totalPagesIsMoreThanPagerLimit
    },
    showNextJump() {
      return this.showNext && this.currentPage < this.pageCount - this.pageJumpCount
    },
    pagers() {
      const pagerCount = this.pagerLimit
      const currentPage = Number(this.currentPage)
      const pageCount = Number(this.pageCount)
      const  { showPrevJump, showNextJump } = this
      const array = []

      if (showPrevJump && !showNextJump) {
        const startBuffer = this.showLast ? 2 : 3
        let startPage = pageCount - (pagerCount - startBuffer)
        startPage = startPage > 0 ? startPage : 1
        const endPage = this.showLast ? pageCount : pageCount + 1
        for (let i = startPage; i < endPage; i++) {
          array.push(i)
        }
      } else if (!showPrevJump && showNextJump) {
        for (let i = 2; i < pagerCount; i++) {
          array.push(i)
        }
      } else if (showPrevJump && showNextJump) {
        const offset = Math.floor(pagerCount / 2) - 1
        for (let i = currentPage - offset; i <= currentPage + offset; i++) {
          array.push(i)
        }
      } else {
        for (let i = 2; i < pageCount; i++) {
          array.push(i)
        }
      }

      return array
    }
  },

  methods: {
    changePage(newPage) {
      if (this.currentPage === newPage) {
        return
      }
      this.$emit('change', newPage)
    }
  }
}
</script>

