import { validateDateRange } from './helper-functions'
import validateSearch from './search'
import validateAnalytics from './analytics'
import validateDataViewer from './dataViewer'
import validateViews from './views'
import validateCompany from './company'
import validateCompanyShipments from './company.shipments'
export default function validateStorage(ctx, storage) {
  const state = {}

  if (storage.country === 'us') {
    state.country = storage.country
  }
  if (storage.tradeType === 'import') {
    state.tradeType = storage.tradeType
  }
  if (typeof storage.isIncompleteDataShown === 'boolean') {
    state.isIncompleteDataShown = storage.isIncompleteDataShown
  }

  if (storage.analytics) {
    state.analytics = validateAnalytics(ctx, storage.analytics)
  }
  if (storage.company) {
    state.company = validateCompany(ctx, storage.company)

    if (storage.company.shipments) {
      state.company.shipments = validateCompanyShipments(
        ctx,
        storage.company.shipments
      )
    }
  }
  if (storage.dataViewer) {
    state.dataViewer = validateDataViewer(ctx, storage.dataViewer)
  }

  if (storage.downloads) {
    if (validateDateRange(ctx.state.downloads, storage.downloads.dateRange)) {
      const downloads = {}
      downloads.dateRange = storage.downloads.dateRange
      state.downloads = downloads
    }
  }

  // Views must come before Search
  if (storage.views) {
    state.views = validateViews(ctx, storage.views)
  }
  if (storage.search) {
    state.search = validateSearch(ctx, storage.search)
  }

  return state
}
