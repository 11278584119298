<template>
  <div
    :class="{ 'navbar-wrapper-custom-zindex': inFullscreen }"
    class="navbar-wrapper">
    <nav
      :class="{ fixed: scrolled }"
      class="navbar">
      <a
        class="app-logo"
        @click="goToBlankSearchPage" />
      <!-- <a 
        href="#"
        @click.prevent="doError">Error</a> -->

      <div class="nav-list">
        <a
          v-if="showBackLink"
          href="#"
          type="button"
          class="btn btn-text btn-legacy"
          @click="backToImportgeniusClicked">
          Back to ImportGenius
        </a>
        <chat-button />
        <!-- Uncomment if first login tour feature will be use again -->
        <!-- <tour-library /> -->
        <current-downloads />
        <user-credits />
        <account-settings />
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { throttle } from 'lodash'
import Notifications from './Notifications'
import AccountSettings from './AccountSettings'
import TourLibrary from './TourLibrary'
import ChatButton from './ChatButton'
import CurrentDownloads from './CurrentDownloads/CurrentDownloads'
import UserCredits from './UserCredits'
import notifyError from '~/modules/unhandled-error-notification/notifyError'
import posthogHelper from '~/mixins/posthog-helper'
import checkPfsFlag from '~/mixins/checkPfsFlag'

export default {
  components: {
    ChatButton,
    Notifications,
    TourLibrary,
    AccountSettings,
    CurrentDownloads,
    UserCredits
  },
  mixins: [posthogHelper, checkPfsFlag],
  data() {
    return {
      isInputFocused: false,
      redirectUrl: process.env.LEGACY_APP_URL,
      shouldShowBackToImportgeniusLink: true
    }
  },
  computed: {
    ...mapState(['scrolled', 'inFullscreen', 'baseLink']),
    ...mapState('search', ['currentSearchId']),
    showBackLink() {
      return this.shouldShowBackToImportgeniusLink
    }
  },
  async mounted() {
    document.body.addEventListener('scroll', this.handleScroll)
    document.body.addEventListener('resize', this.handleResize)
    if (this.$cookies) {
      const redirectCookie = this.$cookies.get('redirectTestGroup')
      if (redirectCookie && redirectCookie === 'test') {
        this.shouldShowBackToImportgeniusLink = false
      } else {
        this.shouldShowBackToImportgeniusLink = true
      }
      await this.fetchAccountInfo()

      this.$posthog.onFeatureFlags((flags, flagVariants) => {
        if (!this.isPosthogCookieValid()) {
          const registeredWithinThreshold = this.isUserRegisteredPastRedirectExperimentThreshold()

          if (
            flags.includes('isng-redirect-test') &&
            registeredWithinThreshold
          ) {
            this.identifyUser(this.email())
          }
        } else {
          this.setIsIdentified(true)
          this.setRedirectTestControlGroup(flagVariants['isng-redirect-test'])
        }
      })
    }
  },
  destroyed() {
    document.body.removeEventListener('scroll', this.handleScroll)
    document.body.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapActions('accounts', ['fetchAccountInfo']),
    ...mapGetters('accounts', ['email']),
    ...mapMutations('experiments', [
      'setRedirectTestControlGroup',
      'setIsIdentified'
    ]),
    handleScroll: throttle(function() {
      this.setScrolled(window.scrollY > 0)
    }),
    handleResize: throttle(function(e) {
      if (e.currentTarget.innerWidth > 959) {
        this.setNavFilterMobile(false)
      }
    }),
    ...mapActions(['setScrolled', 'setNavFilterMobile']),
    ...mapActions('search', ['clearSearch']),
    async goToBlankSearchPage() {
      this.$router.push(this.baseLink)

      if (this.currentSearchId) {
        await this.clearSearch()
      }
    },
    doError() {
      notifyError({ message: 1 })
    },
    backToImportgeniusClicked() {
      if (this.isUserPartOfRedirectTestExperiment()) {
        this.$posthog.capture('Click - Back to ImportGenius')
      }

      window.location.href = this.redirectUrl
    }
  }
}
</script>
<style src="~/assets/scss/sections/navbar/navbar.scss" lang="scss" />
