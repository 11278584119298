import { ResizeObserver as Polyfill } from '@juggle/resize-observer'
import { debounce } from 'lodash'
import {
  poll,
  isDirectlyHovered,
  existsInDOM,
  getHorizontallyScrollableParent
} from './helpers'
import {
  IllegalInstantiationError,
  ObserversNotSupportedError,
  InitializationFailedError
} from './errors'

const instantiationKey = Symbol('FloatingHorizontalScrollBar.key')

export { default as errors } from './errors'

/*
 *  Instantiation:
 *    - Await builder method like `await FloatingHorizontalScrollBar.create(el)`
 *    - `el` would be the element to be attached with the floating scroll bar
 *    - ensure that target element is already attached to document before
 *      instantiation
 *  Usage:
 *    - use show() and hide() methods to control visibility
 *    - floating scroll bar will not be visible if the target element's original
 *      scroll bar is not visible
 *  Cleanup:
 *    - use destroy() methods to cleanup event listeners and instance variables
 *  Limitations:
 *    - Observers API is required in the client browser. Use static variable
 *      `isSupported` for additional validation before instantiation
 *    - Floating scroll bar will not work properly when attached to an element
 *      aside from the document/window that is vertically scrollable as the
 *      render computations are based from the client viewport
 *      - When needed, update floating scroll logic to compute relative to
 *        target's parent and inject scrollbar in it, with the document being
 *        the fallback
 */
export class FloatingHorizontalScrollBar {
  constructor(target, { key } = {}) {
    if (key !== instantiationKey) throw new IllegalInstantiationError()
    if (!FloatingHorizontalScrollBar.isSupported) {
      throw new ObserversNotSupportedError()
    }
    this.options = { passive: true }
    this.target = target
    this._DEFINE_runtimeOptimizedMethods()
  }

  get visible() {
    return this.scrollbar.style.visibility !== 'hidden'
  }

  set visible(visibility) {
    //  style.display prevents DOM update while hidden
    //  style.opacity still interact-able
    this.scrollbar.style.visibility = visibility ? 'visible' : 'hidden'
  }

  get width() {
    return this.scrollbar.style.width
  }

  set width(value) {
    this.scrollbar.style.width = value
  }

  get scrollableWidth() {
    return this.fill.style.width
  }

  set scrollableWidth(value) {
    this.fill.style.width = value
  }

  get positionX() {
    return this.scrollbar.style.left
  }

  set positionX(value) {
    this.scrollbar.style.left = value
  }

  get positionY() {
    return this.scrollbar.style.bottom
  }

  set positionY(value) {
    this.scrollbar.style.bottom = value
  }

  get offsetBottom() {
    return this.__offsetBottom || 0
  }

  set offsetBottom(value) {
    this.__offsetBottom = value
  }

  get zIndex() {
    return this.scrollbar.style.zIndex
  }

  set zIndex(value) {
    this.scrollbar.style.zIndex = value
  }

  get targetScrollBarHeight() {
    return +this.target.offsetHeight - +this.target.clientHeight
  }

  get targetHasScrollBar() {
    return this.targetScrollBarHeight > 0
  }

  get shown() {
    return this.__shown
  }

  get isDestroyed() {
    return this.__destroyed || false
  }

  show() {
    if (this.isDestroyed) return this

    this.__shown = true
    this._renderFloatingScrollVisibilityImmediate()

    return this
  }

  hide() {
    if (this.isDestroyed) return this

    this.__shown = false
    this._renderFloatingScrollVisibilityImmediate()

    return this
  }

  reRender() {
    if (this.isDestroyed) return this

    this._renderFloatingScrollVisibility()

    return this
  }

  async initialize() {
    if (this.isDestroyed) return this

    try {
      this._createScrollElement()
      await this._attachToDOM()

      this._attachSynchronizationScrollHandlers()
      this._attachParentScrollHandlers()

      this._observeTargetContentChanges()
    } catch (err) {
      this.destroy()

      throw new InitializationFailedError(err.message)
    }

    return this
  }

  destroy() {
    if (this.isDestroyed) return this

    this._unobserveTargetContentChanges()
    this._detachParentScrollHandlers()
    this._detachSynchronizationScrollHandlers()

    this._detachFromDOM()
    Object.keys(this).forEach(key => (this[key] = null))

    this.__destroyed = true

    return this
  }

  _createScrollElement() {
    this.fill = document.createElement('div')
    this.fill.style.height = '10px'
    this.fill.style.opacity = 0

    const tags = {
      id: this.target.id ? `#${this.target.id}` : '',
      class: this.target.className
        ? `.${this.target.className.replace(' ', '.')}`
        : ''
    }

    this.scrollbar = document.createElement('div')
    this.scrollbar.className = `scroll-float-horizontal${tags.id}${tags.class}`
    this.scrollbar.style.zIndex = 1
    this.scrollbar.style.position = 'fixed'
    this.scrollbar.style.overflowX = 'scroll'

    this.scrollbar.appendChild(this.fill)
  }

  _attachToDOM() {
    this.target.appendChild(this.scrollbar)
    document.addEventListener(
      'scroll',
      this._renderFloatingScrollVisibility,
      this.options
    )
    window.addEventListener(
      'resize',
      this._renderFloatingScrollVisibility,
      this.options
    )

    return poll(() => existsInDOM(this.scrollbar))
  }

  _detachFromDOM() {
    document.removeEventListener('scroll', this._renderFloatingScrollVisibility)
    window.removeEventListener('resize', this._renderFloatingScrollVisibility)

    if (existsInDOM(this.scrollbar)) this.target.removeChild(this.scrollbar)
  }

  _attachSynchronizationScrollHandlers() {
    this.target.addEventListener('scroll', this._syncElementScrollFromTarget)
    this.scrollbar.addEventListener(
      'scroll',
      this._syncTargetScrollFromElement,
      this.options
    )
  }

  _detachSynchronizationScrollHandlers() {
    if (this.target) {
      this.target.removeEventListener(
        'scroll',
        this._syncElementScrollFromTarget
      )
    }

    if (this.scrollbar) {
      this.scrollbar.removeEventListener(
        'scroll',
        this._syncTargetScrollFromElement
      )
    }
  }

  _attachParentScrollHandlers() {
    this.__scrollableParent = getHorizontallyScrollableParent(this.target)

    this.__scrollableParent.addEventListener(
      'scroll',
      this._syncHorizontalPositionWithParent
    )
  }

  _detachParentScrollHandlers() {
    if (!this.__scrollableParent) return

    this.__scrollableParent.removeEventListener(
      'scroll',
      this._syncHorizontalPositionWithParent
    )
  }

  _observeTargetContentChanges() {
    this.__targetChangeObservers = FloatingHorizontalScrollBar.observerMap.map(
      ([Observer, config = {}]) => {
        const observer = new Observer(this._renderFloatingScrollAppearance)
        observer.observe(this.target, config)

        return observer
      }
    )
  }

  _unobserveTargetContentChanges() {
    if (!Array.isArray(this.__targetChangeObservers)) return

    this.__targetChangeObservers.forEach(observer => observer.disconnect())
    this.__targetChangeObservers = []
  }

  _DEFINE_runtimeOptimizedMethods() {
    this._DEFINE_syncElementScrollFromTarget()
    this._DEFINE_syncTargetScrollFromElement()
    this._DEFINE_renderFloatingScrollVisibility()
    this._DEFINE_renderFloatingScrollAppearance()
    this._DEFINE_syncHorizontalPositionWithParent()
  }

  _DEFINE_syncElementScrollFromTarget() {
    this._syncElementScrollFromTarget = debounce(() => {
      try {
        if (!isDirectlyHovered(this.scrollbar)) {
          this.scrollbar.scrollLeft = this.target.scrollLeft
        }
      } catch (err) {
        this.destroy()

        console.error(err)
      }
    }).bind(this)
  }

  _DEFINE_syncTargetScrollFromElement() {
    this._syncTargetScrollFromElement = debounce(() => {
      try {
        if (isDirectlyHovered(this.scrollbar)) {
          this.target.scrollLeft = this.scrollbar.scrollLeft
        }
      } catch (err) {
        this.destroy()

        console.error(err)
      }
    }).bind(this)
  }

  _renderFloatingScrollVisibilityImmediate() {
    try {
      if (!this.shown || !this.targetHasScrollBar) {
        return (this.visible = false)
      }

      // Relative to bottom of viewport
      const topPosition =
        (this.target.getBoundingClientRect().top - window.innerHeight) * -1
      const bottomPosition =
        (this.target.getBoundingClientRect().bottom - window.innerHeight) * -1

      const topIsAboveBottomViewport = topPosition > this.offsetBottom
      const bottomIsAboveBottomViewport = bottomPosition > this.offsetBottom

      const scrollBarOffset =
        this.targetScrollBarHeight - topPosition + this.offsetBottom
      const scrollBarIsAboveTopPosition =
        scrollBarOffset >= 0 && scrollBarOffset <= this.targetScrollBarHeight

      if (topIsAboveBottomViewport && !bottomIsAboveBottomViewport) {
        if (scrollBarIsAboveTopPosition) {
          this.positionY = `${this.offsetBottom - scrollBarOffset}px`
        } else {
          this.positionY = `${this.offsetBottom}px`
        }

        return (this.visible = true)
      }

      this.visible = false
    } catch (err) {
      this.destroy()

      console.error(err)
    }
  }

  _DEFINE_renderFloatingScrollVisibility() {
    this._renderFloatingScrollVisibility = debounce(
      this._renderFloatingScrollVisibilityImmediate
    ).bind(this)
  }

  _renderFloatingScrollAppearanceImmediate() {
    try {
      //  Quick return on unwanted rendering of floating horizontal scroll
      if (!this.target) return

      const { x: positionX } = this.target.getBoundingClientRect()
      const { scrollWidth: scrollableWidth, offsetWidth: width } = this.target

      this.scrollableWidth = `${scrollableWidth}px`
      this.positionX = `${positionX}px`
      this.width = `${width}px`
      this._renderFloatingScrollVisibilityImmediate()
    } catch (err) {
      this.destroy()

      console.error(err)
    }
  }

  _DEFINE_renderFloatingScrollAppearance() {
    this._renderFloatingScrollAppearance = debounce(
      this._renderFloatingScrollAppearanceImmediate
    ).bind(this)
  }

  _DEFINE_syncHorizontalPositionWithParent() {
    this._syncHorizontalPositionWithParent = debounce(e => {
      const { scrollLeft } = e.target

      if (!this.__previousParentHorizontalScroll) {
        this.__previousParentHorizontalScroll = scrollLeft
      }

      if (this.__previousParentHorizontalScroll !== scrollLeft) {
        this._renderFloatingScrollAppearanceImmediate()
      }
    }).bind(this)
  }

  static create(element) {
    return new FloatingHorizontalScrollBar(element, {
      key: instantiationKey
    }).initialize()
  }

  static get observerMap() {
    return [[window.ResizeObserver || Polyfill]]
  }

  static get isSupported() {
    return !FloatingHorizontalScrollBar.observerMap.some(([Observer]) => {
      return !Observer
    })
  }
}

export default FloatingHorizontalScrollBar
