export default function validateViews(ctx, storage) {
  const views = {}

  if (storage.activeShipmentsView) {
    const { name } = storage.activeShipmentsView
    // Restore active template from persistedState
    if (name) {
      const activeShipmentsView = ctx.state.views.views.find(
        template => template.name === name
      )
      if (activeShipmentsView) {
        views.activeShipmentsView = activeShipmentsView
      }
    }
  }

  return views
}
