<template>
  <fragment>
    <p
      v-for="(message, index) in messages"
      :key="index"
      v-html="message"/>
  </fragment>
</template>


<script>
import { numberWithLetters } from '~/utils/number'
import { Fragment } from 'vue-fragment'

export default {
  name: 'ResultCountMessages',
  components: {
    Fragment
  },
  props: {
    preFetchedCounts: {
      type: Object,
      required: true
    },
    currentDateRange: {
      type: Object,
      required: true
    },
    userHasPremiumSubscription: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    STATE: {
      DEFAULT: Symbol('default'),
      PARTIAL_RESULTS: Symbol('partial results'),
      ALL_RESULTS: Symbol('all results'),
      ZERO_RESULTS: Symbol('zero results')
    }
  }),
  computed: {
    messages() {
      switch (this.currentState) {
        case this.STATE.PARTIAL_RESULTS:
          return this.partialResultsMessage
        case this.STATE.ALL_RESULTS:
          return this.allResultsMessage
        case this.STATE.ZERO_RESULTS:
          return this.zeroResultsMessage
        default:
          return this.defaultMessage
      }
    },
    currentState() {
      const queryCountIsPrefetched =
        this.preFetchedCounts.shipments &&
        'allData' in this.preFetchedCounts.shipments &&
        'dateRange' in this.preFetchedCounts.shipments

      if (queryCountIsPrefetched) {
        const { allData, dateRange } = this.preFetchedCounts.shipments

        if (!allData && !dateRange) return this.STATE.ZERO_RESULTS
        if (allData === dateRange) return this.STATE.ALL_RESULTS
        if (allData > dateRange) return this.STATE.PARTIAL_RESULTS
      }

      return this.STATE.DEFAULT
    },
    partialResultsMessage() {
      const { allData, dateRange } = this.preFetchedCounts.shipments
      const shipmentsRemainingCount = numberWithLetters(allData - dateRange, 1)

      let increaseResultsMessage = 'Upgrade to the premium plan to access '

      if (this.userHasPremiumSubscription) {
        increaseResultsMessage = 'Adjust your selected date range to access '
      }

      increaseResultsMessage +=
        `<strong>${shipmentsRemainingCount}</strong> ` +
        'more shipments over all ImportGenius data.'

      return [...this.resultCountMessage, increaseResultsMessage]
    },
    allResultsMessage() {
      return [...this.resultCountMessage]
    },
    zeroResultsMessage() {
      return [
        'This search will not produce results.',
        'Try modifying your search terms or broaden your date selection.'
      ]
    },
    defaultMessage() {
      return []
    },
    resultCountMessage() {
      const { dateRange } = this.preFetchedCounts.shipments
      const shipmentsDateRangeCount = numberWithLetters(dateRange, 1)
      const from = this.$date.format(this.currentDateRange.from, 'yyyy-MM-dd')
      const to = this.$date.format(this.currentDateRange.to, 'yyyy-MM-dd')

      let countMessage =
        `Your search will result in <strong>${shipmentsDateRangeCount}</strong> ` +
        this.determineCaseFromCount('Shipment', shipmentsDateRangeCount)

      if (+dateRange) {
        if (this.aggregateCounts) countMessage += ' across'

        this.aggregateCounts.forEach(({ key, total }, index) => {
          const aggDateRangeCount = numberWithLetters(total.dateRange, 1)
          const aggCountDisplay = `<strong>${aggDateRangeCount}</strong>`
          const keyDisplay = this.determineCaseFromCount(key, aggDateRangeCount)

          if (
            index === this.aggregateCounts.length - 1 &&
            this.aggregateCounts.length > 1
          ) {
            countMessage += ' and '
          } else if (index !== 0) {
            countMessage += ', '
          }

          countMessage += ` ${aggCountDisplay} ${keyDisplay}`
        })
      }

      countMessage += ` from <strong>${from}</strong> to <strong>${to}</strong>`
      countMessage += '.'

      return [countMessage]
    },
    aggregateCounts() {
      const { aggregates } = this.preFetchedCounts

      if (!aggregates) return []

      return Object.keys(aggregates).map(key => {
        return { key, total: aggregates[key] }
      })
    }
  },
  methods: {
    determineCaseFromCount(word, count) {
      word = word.toLowerCase()

      return count != 1 ? word + 's' : word
    }
  }
}
</script>
