<template>
  <div
    ref="alertBanner"
    :class="[
      {
        'alert-dismissible': isDismissible,
        'with-icon': hasIcon,
      },
      alert, alertAnimation
    ]"
    class="alert show">
    <button
      v-if="isDismissible"
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
      @click="close">
      <span aria-hidden="true">&times;</span>
    </button>
    <i
      v-if="hasIcon"
      :class="{'alert-icon': hasIcon}"/>
    <slot>
      <p>{{ alertMessage }}</p>
    </slot>

  </div>
</template>

<script>
import delay from 'delay'

export default {
  name: 'AlertBanner',
  props: {
    alertMessage: {
      type: String,
      default: ''
    },
    isDismissible: {
      type: Boolean,
      default: false
    },
    alertType: {
      type: String,
      default: 'info'
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    animation: {
      type: Boolean,
      default: false
    },
    animate: {
      type: Object,
      default: () => {
        return {
          type: 'slide',
          from: 'top'
        }
      }
    }
  },
  data() {
    return {
      hidden: false,
      animationDismiss: false
    }
  },
  computed: {
    alertAnimation() {
      if (this.animation) {
        const type = this.animate.type
        const from = this.animate.from
        const dismiss = this.animationDismiss
        return `alert-banner-${type}-from-${from}${dismiss ? '-dismiss' : ''}`
      }

      return ''
    },
    alert() {
      switch (this.alertType) {
        case 'success':
          return 'alert-success'
        case 'warning':
          return 'alert-warning'
        case 'danger':
          return 'alert-danger'
        default:
          return 'alert-info'
      }
    }
  },
  methods: {
    async close() {
      this.animationDismiss = true

      // add delay before emitting the close-alert-banner event to keep the animation visible
      await delay(100)

      this.$emit('close-alert-banner')
    }
  }
}
</script>

<style scoped>
.alert-banner-slide-from-top {
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.alert-banner-slide-from-top-dismiss {
  animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes slide-out-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-1000px);
    opacity: 0;
  }
}
@-webkit-keyframes slide-out-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-1000px);
    opacity: 0;
  }
}
@-webkit-keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
