<template>
  <fieldset>
    <div
      :class="{ 'first-condition': isFirstCondition, multiselectdown }"
      class="condition">
      <div
        v-if="!isFirstCondition"
        class="logic-tag"> {{ condition }} </div>
      <fields-dropdown
        ref="fieldsDropdown"
        v-model="selectedField"
        @input="clearInput"
        @open="handleMultiselectFocus"
        @close="handleMultiselectFocus" />
      <search-type-dropdown
        ref="searchTypeDropdown"
        v-model="selectedSearchType"
        @input="focusInput"
        @open="handleMultiselectFocus"
        @close="handleMultiselectFocus"/>
      <multiselect-dropdown
        v-show="isMultiselect"
        ref="keywordsDropdown"
        :class="{ error }"
        :options="options"
        :taggable="false"
        :hide-options-when-empty="true"
        :clear-input-on-select="!hasDynamicOptions"
        :placeholder-text="placeholder"
        @input="handleMultipleKeywords"
        @keyword-change="inputChange"
        @open="handleMultiselectFocus"
        @close="handleMultiselectFocus"/>
      <input
        v-show="!isMultiselect"
        ref="keywordInput"
        :class="{ error }"
        v-model="keyword"
        :placeholder="placeholder"
        class="keyword-input"
        type="text"
        @input="focusInput"
        @keyup.enter="$bus.$emit('startAdvancedSearch')"
        @focus="handleMultiselectFocus(true)"
        @blur="handleMultiselectFocus(false)">
      <i
        class="removed-icon"
        @click="$emit('remove')"/>
    </div>
    <div
      v-if="displayHelpText"
      :class="{ 'multi-line': multiselectdown }"
      class="help-text">
      <span>{{ conditionHelpText.text }}</span>
    </div>
  </fieldset>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import FieldsDropdown from '~/components/layouts/AdvancedSearchModal/FieldsDropdown'
import SearchTypeDropdown from '~/components/layouts/AdvancedSearchModal/SearchTypeDropdown'
import MultiselectDropdown from '~/components/common/MultiselectDropdown'
import { advancedSearchHelpText } from '~/utils/advancedSearch'
import { statesOfAmerica, countries } from '~/utils/address'
import { debounce, find } from 'lodash'

export default {
  name: 'Condition',
  components: {
    FieldsDropdown,
    SearchTypeDropdown,
    MultiselectDropdown
  },
  props: {
    condition: {
      type: String,
      default: ''
    },
    isFirstCondition: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedField: 'ProductDescription',
      selectedSearchType: 'contains',
      keyword: '',
      error: false,
      isFocus: false,
      isMultiselectFocus: false,
      activeCondition: true,
      definedFields: [
        'countryoforigin',
        'consigneestate',
        'carrierstate',
        'vesselcountry',
        'consigneezipcode',
        'carrierzip'
      ],
      multiselectdown: false,
      suggestions: []
    }
  },
  computed: {
    ...mapState('search', {
      suggestionFields: state => state.suggestionFields,
      suggestedKeywords: state => state.keywords
    }),

    isMultiselect() {
      return this.hasDynamicOptions || this.hasStaticOptions
    },
    hasDynamicOptions() {
      return ['Shipper', 'Consignee', 'ProductDescription'].includes(
        this.selectedField
      )
    },
    hasStaticOptions() {
      return this.definedFields.includes(this.selectedField.toLowerCase())
    },
    displayHelpText() {
      return this.activeCondition || this.isFocus || this.isMultiselectFocus
    },
    options() {
      if (this.hasDynamicOptions && this.keyword) {
        return this.suggestions
      }

      switch (this.selectedField.toLowerCase()) {
        case 'vesselcountry':
        case 'countryoforigin':
          return countries
        case 'consigneestate':
        case 'carrierstate':
          return statesOfAmerica
        default:
          return []
      }
    },
    keywords() {
      return this.$refs.keywordsDropdown.getValues()
    },
    placeholder() {
      return advancedSearchHelpText.find(
        condition => condition.field === this.selectedField
      ).placeholder
    },
    conditionHelpText() {
      return advancedSearchHelpText.find(
        condition => condition.field === this.selectedField
      )
    }
  },
  mounted() {
    this.currentActiveCondition = true
    this.setDefaultSelectedField()
  },
  methods: {
    ...mapActions('search', ['fetchSuggestionFields', 'prefetchKeywords']),
    ...mapGetters('userSubscriptions', ['hasPlanLimited']),
    setDefaultSelectedField() {
      const selectedField = this.hasPlanLimited
        ? 'Consignee'
        : 'ProductDescription'
      this.selectedField = selectedField
    },
    validate({ updateState = true } = {}) {
      let updatedErrorState = false

      updatedErrorState = this.isMultiselect && !this.keywords.length

      if (!this.isMultiselect || !this.keywords.length) {
        this.keyword = this.keyword.trim()
        updatedErrorState =
          !this.keyword ||
          (this.selectedSearchType === 'fuzzy' &&
            this.keyword.split(' ').length < 3)
      }

      if (!updateState) return !updatedErrorState

      this.error = updatedErrorState

      return !this.error
    },
    getValue(isFinal = false) {
      if (this.isMultiselect && this.keywords.length) {
        let allkeywords = {
          keyword: Object.assign([], this.keywords),
          type: this.selectedSearchType,
          field: this.selectedField
        }

        if (isFinal && this.keyword) {
          allkeywords.keyword.push(this.keyword)
        }

        return allkeywords
      }

      return {
        keyword: [this.keyword],
        type: this.selectedSearchType,
        field: this.selectedField
      }
    },
    focus() {
      this.$refs.fieldsDropdown.$refs.multiselect.$el.focus()
    },
    focusInput() {
      this.$bus.$emit('conditionChanged')

      if (this.isMultiselect) {
        this.$refs.keywordsDropdown.$refs.multiselect_dropdown.$el.focus()
        return
      }

      this.$refs.keywordInput.focus()
    },
    setValue(value) {
      let { keyword, type, field } = value

      /*
        backwards compatibility for saved search, search history, and bookmarks
        since regular and exact search type are deprecated
      */
      if (type === 'exact') {
        type = 'exact_phrase'
      }

      if (type === 'regular') {
        type = 'contains'
      }

      this.selectedField = field
      this.selectedSearchType = type

      if (!keyword || !keyword.length) {
        return
      }

      if (this.isMultiselect) {
        const selected = keyword.map(item => this.getOptionObject(item))

        if (selected) {
          this.multiselectdown = selected.length > 1
          this.$refs.keywordsDropdown.setValue(selected)
        }

        return
      }

      this.keyword = keyword[0]
    },
    getOptionObject(keyword) {
      return this.hasStaticOptions
        ? this.options.find(option => option.name === keyword)
        : {
            name: keyword,
            code: keyword
          }
    },
    handleMultipleKeywords(keywords) {
      this.multiselectdown = keywords.length > 1
      this.$bus.$emit('conditionChanged', !keywords.length)
    },
    clearInput() {
      this.$refs.searchTypeDropdown.$refs.multiselect.$el.focus()
      this.multiselectdown = false
      this.$refs.keywordsDropdown.setValue([])
      this.clearKeyword(true)
    },
    inputChange(value) {
      if (this.hasDynamicOptions) {
        this.keyword = value

        if (value) {
          this.fetchKeywordsSuggestions()
          this.$bus.$emit('conditionChanged')
          return
        }

        this.clearKeyword()
      }
    },
    fetchKeywordsSuggestions: debounce(
      async function fetchKeywordsSuggestions() {
        this.suggestions = [
          { name: this.keyword, code: this.keyword, isInput: true }
        ]

        if (!this.keyword) {
          return
        }

        await this.fetchSuggestionFields(this.keyword)

        let field =
          this.selectedField === 'ProductDescription'
            ? 'ProductKeywords'
            : this.selectedField

        if (this.suggestionFields.includes(field)) {
          await this.prefetchKeywords({
            keyword: this.keyword,
            fields: [field]
          })

          if (this.suggestedKeywords[field]) {
            this.suggestions = this.suggestedKeywords[field].map(item => {
              return (
                item.keyword !== this.keyword && {
                  name: item.keyword,
                  code: item.keyword
                }
              )
            })

            this.suggestions.unshift({
              name: this.keyword,
              code: this.keyword,
              isInput: true
            })
          }
        }
      },
      300
    ),
    clearKeyword(clear = false) {
      this.keyword = ''
      this.suggestions = []

      this.$bus.$emit('conditionChanged', clear)
    },
    handleMultiselectFocus(isFocus = false) {
      this.isMultiselectFocus = isFocus

      if (isFocus) {
        this.showAndHideHelpText(false)
        return
      }

      this.showAndHideHelpText(true)
    },
    showAndHideHelpText(active = true) {
      const parentRef =
        this.$parent.$parent.$parent.$options.name === 'ConditionGroup'
          ? this.$parent.$parent.$parent
          : this.$parent.$parent.$options.name === 'ConditionGroup'
            ? this.$parent.$parent
            : this.$parent

      parentRef.conditions.map((c, index) => {
        const condition = parentRef.$refs[`condition${index}`]
        if (condition[0].$options.name === 'ConditionGroup') {
          const childrenLength = condition[0].$children.length

          if (
            condition[0].$children[childrenLength - 1].$options.name ===
              'Condition' &&
            condition[0].$children[childrenLength - 1].currentActiveCondition
          ) {
            condition[0].$children[childrenLength - 1].activeCondition = active
          } else {
            condition[0].$children.map(subChildren => {
              const activeChildren = find(subChildren.$children, {
                currentActiveCondition: true
              })

              if (activeChildren) {
                activeChildren.activeCondition = active
              }
            })
          }
        } else if (condition[0].currentActiveCondition) {
          condition[0].activeCondition = active
        }
      })
    }
  }
}
</script>
