export const SearchTypes = {
  EXACT_PHRASE: 'exact_phrase',
  CONTAINS: 'contains',
  STARTS_WITH: 'starts_with',
  ENDS_WITH: 'ends_with',
  RANGE: 'range',
  /* 
    The following are deprecated.
    These are retained only for saved search, 
    search history, & bookmarks backwards compatibility
  */
  REGULAR: 'regular',
  EXACT: 'exact',
  FUZZY: 'fuzzy'
}
