/*
 * What:
 *  - Polyfill for date-fns methods not available on currently installed version
 * Future actions:
 *  - Upgrade date-fns package to latest version, update polyfill subscribers
 *    and remove this module
 */

function isInSeconds(timestamp) {
  return String(timestamp).length === 10
}

export function fromUnixTime(timestamp) {
  timestamp = +timestamp

  if (isInSeconds(timestamp)) timestamp *= 1000

  return new Date(timestamp)
}

export default { fromUnixTime }
