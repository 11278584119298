import axios from 'axios'

export default class DecoratedAuth {
  constructor(auth, axios) {
    this.auth = auth
    this.axios = axios
    this.options = {
      endpoints: {
        user: false
      },
      tokenRequired: true,
      tokenType: 'Bearer',
      globalToken: true,
      tokenName: 'Authorization',
      _name: 'local'
    }
  }

  async autologin() {
    // Ditch any leftover local tokens before attempting to log in

    try {
      const { data } = await axios.post('/api/autologin')
      if (data.response.access_token) {
        if (this.options.tokenRequired) {
          const token = `${this.options.tokenType} ${
            data.response.access_token
          }`
          this.auth.setToken(this.options._name, token)
          this._setToken(token)
        }
        this._fetchUser()
      } else {
        this._logoutLocally()
      }
    } catch (e) {
      const { status, data } = e.response
      if (
        status === 403 &&
        data.response == 'Sorry, but you have no active subscriptions'
      ) {
        this.auth.ctx.store.commit('setRedirectNonMigratedAccount', true)
      } else {
        this._logoutLocally()
      }
    }
  }

  _fetchUser() {
    if (this.options.tokenRequired && !this.auth.getToken(this.options._name)) {
      return
    }
    if (!this.options.endpoints.user) {
      this.auth.setUser({})
    }
  }
  _setToken(tokens) {
    if (this.options.globalToken) {
      this.auth.ctx.app.$axios.setHeader(this.options.tokenName, tokens)
    }
  }

  async _logoutLocally() {
    if (this.options.tokenRequired) {
      this._clearToken()
    }

    this.auth.setUser(false)
    this.auth.setToken(this.auth.$state.strategy, false)
    this.auth.setRefreshToken(this.auth.$state.strategy, false)
    this.auth.ctx.store.commit('setRedirectToConsole', true)
  }

  _clearToken() {
    if (this.options.globalToken) {
      // Clear Authorization token for all axios requests
      this.auth.ctx.app.$axios.setHeader(this.auth.options.tokenName, false)
    }
  }
}
