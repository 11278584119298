<template>
  <div class="option-fields">
    <el-dialog
      :width="width"
      :visible.sync="isOpen"
      :before-close="closeUpgradeModal"
      class="upgrade-options"
      title="Upgrade your account plan">

      <iframe-modal
        v-if="isIframeOpen"
        :visible.sync="isIframeOpen"
        :source="source"
        label=""/>

      <component
        v-bind="{ plan }"
        :is="plan.type === 'Limited' ? 'plus-account-modal' : 'premium-account-modal'"/>

      <div
        slot="footer"
        class="flex-container justify-right">
        <button
          class="btn btn-text secondary"
          @click="closeUpgradeModal">No, Thanks</button>
        <button
          class="btn btn-default btn-primary"
          @click="openIframeModal">Upgrade Now</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PlusAccountModal from './UpgradeSubscriptionPlanType/PlusAccountModal'
import PremiumAccountModal from './UpgradeSubscriptionPlanType/PremiumAccountModal'
import IframeModal from './IframeModal'
import { closeUpgradeModal } from '~/mixins/upgradeModal/methods'

export default {
  name: 'UpgradeSubscriptionModal',
  components: {
    IframeModal,
    PlusAccountModal,
    PremiumAccountModal
  },
  mixins: [closeUpgradeModal],
  props: {
    label: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: true
    },
    plan: {
      type: Object,
      required: true
    },
    width: {
      type: String,
      default: '800px'
    },
    height: {
      type: String,
      default: '500px'
    },
    title: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: this.show,
      isIframeOpen: false
    }
  },
  watch: {
    isIframeOpen(open) {
      if (!open) this.closeUpgradeModal()
    }
  },
  methods: {
    openIframeModal() {
      this.isIframeOpen = true
    }
  }
}
</script>

<style lang="scss" src="~/assets/scss/components/upgrade-options/upgrade-options.scss"/>
