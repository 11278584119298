import { Notification } from 'element-ui'

/**
 *
 * @param {string} message description of notification
 * @param {string} title title of notification
 * @param {string} type type of notification ['warning', 'info', 'error', 'success']
 * Added behaviour if message is same as last, do not run
 * After 20 seconds, last message is reset
 * Added behaviour if message is found in error list, translate to new copy
 */
const defaultMessage =
  'Whoops, it looks like we encountered a hiccup.<br/>Please <span class="refresh" onClick="window.location.href=window.location.href">refresh the page</span>.'

let lastMessage = ''
let clearLast = 0

const errorList = [
  {
    key: 'Request Timeout',
    copy:
      'There was an error on our end. <br/> Please <span class="refresh" onClick="window.location.href=window.location.href">refresh and retry your search</span>.'
  },
  { key: 'Not Found', copy: 'Your chosen item does not exist. ' },
  {
    key: 'Network Error',
    copy:
      'There was an error on our end. <br/>Please <span class="refresh" onClick="window.location.href=window.location.href">refresh and retry your search</span>.'
  }
]

export default function({
  message = defaultMessage,
  title = '',
  type = ''
} = {}) {
  const msg = message ? message.toLowerCase() : ''

  for (let i = 0; i < errorList.length; i++) {
    if (msg.indexOf(errorList[i].key.toLowerCase()) > -1) {
      message = errorList[i].copy
      break
    }
  }

  if (message != lastMessage)
    Notification({
      type,
      title,
      message,
      dangerouslyUseHTMLString: true
    })

  lastMessage = message

  //clears last message after 20 seconds
  clearTimeout(clearLast)
  clearLast = setTimeout(() => {
    lastMessage = ''
  }, 20 * 1000)
}
