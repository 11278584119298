import { SubscriptionFeatures } from '~/utils/constants/subscription'
import { numberWithLetters } from '~/utils/number'

export const plans = [
  {
    type: 'Limited',
    name: 'Limited@',
    amount: 99,
    planFeatures: [
      {
        feature: '3 months of U.S. Import Data',
        description:
          'Access data on U.S. ocean freight imports for all companies.'
      },
      {
        feature: '10 Company Searches per day',
        description:
          'You can filter and view by the consignee, shipper, and 40+ other fields we record per shipment'
      },
      {
        feature: 'Download 1k records per month',
        description: '(XLSX, XLS, and CSV)'
      },
      {
        feature: '3 Contact requests per month',
        description:
          'Our research team can give you the contact details of key decision-makers on companies you are interested in.'
      },
      {
        feature: '3 Company Email Alerts',
        description:
          'Get automated emails whenever a company you are interested in gets a new shipment'
      },
      {
        feature: 'Customer Support',
        description:
          'Our Customer Service Agents are available when you need them. Call in to receive helpful tips and training.'
      }
    ]
  },
  {
    type: 'Plus',
    name: 'Plus&',
    amount: 199,
    planFeatures: [
      {
        feature: '12 months of U.S. Import Data',
        description:
          'Access data on U.S. ocean freight imports for all companies.'
      },
      {
        feature: 'Complete U.S. Export Data',
        description:
          'U.S. ocean freight exports from January 1, 2014 to present for all companies'
      },
      {
        feature: '25 Company Searches per day',
        description:
          'You can filter and view by the consignee, shipper, and 40+ other fields we record per shipment'
      },
      {
        feature: 'Download 10k records per month',
        description: '(XLSX, XLS, and CSV)'
      },
      {
        feature: '25 Contact requests per month',
        description:
          'Our research team can give you the contact details of key decision-makers on companies you are interested in.'
      },
      {
        feature: '5 Company Email Alerts',
        description:
          'Get automated emails whenever a company you are interested in gets a new shipment'
      },
      {
        feature: 'Visual Mapping',
        description:
          'See all the trade relationships of companies you are interested in with Visual Mapping'
      },
      {
        feature: 'Customer Support',
        description:
          'Our Customer Service Agents are available when you need them. Call in to receive helpful tips and training.'
      }
    ]
  }
]

export function formatPlanDescription(subscription) {
  const { plan, features } = subscription

  const dateRange = features[SubscriptionFeatures.DATE_RANGE].includes('years')
    ? `${parseInt(features[SubscriptionFeatures.DATE_RANGE]) * 12} months`
    : features[SubscriptionFeatures.DATE_RANGE]

  return {
    type: plan.type,
    name: plan.name,
    amount: plan.amount,
    planFeatures: [
      {
        feature: `${dateRange} of U.S. Import Data`,
        description:
          'Access data on U.S. ocean freight imports for all companies.'
      },
      ...(plan.type === 'Plus'
        ? [
            {
              feature: 'Complete U.S. Export Data',
              description:
                'U.S. ocean freight exports from January 1, 2014 to present for all companies'
            }
          ]
        : []),
      {
        feature: `${numberWithLetters(
          features[SubscriptionFeatures.SEARCHES],
          0,
          false
        )} Company Searches per day`,
        description:
          'You can filter and view by the consignee, shipper, and 40+ other fields we record per shipment'
      },
      ...(parseInt(features[SubscriptionFeatures.DOWNLOAD_RESULTS])
        ? [
            {
              feature: `Download ${numberWithLetters(
                features[SubscriptionFeatures.DOWNLOAD_RESULTS],
                0,
                false
              )} records per month`,
              description: '(XLSX, XLS, and CSV)'
            }
          ]
        : []),
      ...(parseInt(features[SubscriptionFeatures.CONTACT_REQUESTS])
        ? [
            {
              feature: `${numberWithLetters(
                features[SubscriptionFeatures.CONTACT_REQUESTS],
                0,
                false
              )} Contact requests per month`,
              description:
                'Our research team can give you the contact details of key decision-makers on companies you are interested in.'
            }
          ]
        : []),
      ...(parseInt(features[SubscriptionFeatures.EMAIL_ALERTS])
        ? [
            {
              feature: `${numberWithLetters(
                features[SubscriptionFeatures.EMAIL_ALERTS],
                0,
                false
              )} Company Email Alerts`,
              description:
                'Get automated emails whenever a company you are interested in gets a new shipment'
            }
          ]
        : []),
      {
        feature: 'Customer Support',
        description:
          'Our Customer Service Agents are available when you need them. Call in to receive helpful tips and training.'
      }
    ]
  }
}
