import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e5eac3e0 = () => interopDefault(import('../src/pages/bookmarks.vue' /* webpackChunkName: "pages/bookmarks" */))
const _54b06b0c = () => interopDefault(import('../src/pages/download-history.vue' /* webpackChunkName: "pages/download-history" */))
const _0cec2afc = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _ea79e106 = () => interopDefault(import('../src/pages/shipment-alerts.vue' /* webpackChunkName: "pages/shipment-alerts" */))
const _7bd780fb = () => interopDefault(import('../src/pages/company/_id.vue' /* webpackChunkName: "pages/company/_id" */))
const _23ef770e = () => interopDefault(import('../src/pages/company/_id/about.vue' /* webpackChunkName: "pages/company/_id/about" */))
const _6b5ab6e6 = () => interopDefault(import('../src/pages/company/_id/importers.vue' /* webpackChunkName: "pages/company/_id/importers" */))
const _add694a6 = () => interopDefault(import('../src/pages/company/_id/notes.vue' /* webpackChunkName: "pages/company/_id/notes" */))
const _8453c386 = () => interopDefault(import('../src/pages/company/_id/overview.vue' /* webpackChunkName: "pages/company/_id/overview" */))
const _8a0999f6 = () => interopDefault(import('../src/pages/company/_id/shipments.vue' /* webpackChunkName: "pages/company/_id/shipments" */))
const _5cfffa13 = () => interopDefault(import('../src/pages/company/_id/suppliers.vue' /* webpackChunkName: "pages/company/_id/suppliers" */))
const _16432568 = () => interopDefault(import('../src/pages/company/_id/visualMap.vue' /* webpackChunkName: "pages/company/_id/visualMap" */))
const _2aad43ff = () => interopDefault(import('../src/pages/shipment-alert/_shipmentAlertId/_resultId.vue' /* webpackChunkName: "pages/shipment-alert/_shipmentAlertId/_resultId" */))
const _434487e5 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _27f1785c = () => interopDefault(import('../src/pages/index/analytics.vue' /* webpackChunkName: "pages/index/analytics" */))
const _1b3a4696 = () => interopDefault(import('../src/pages/index/search/_searchId.vue' /* webpackChunkName: "pages/index/search/_searchId" */))
const _69fdc79d = () => interopDefault(import('../src/pages/_country/_tradeType/shipment/_id/index.vue' /* webpackChunkName: "pages/_country/_tradeType/shipment/_id/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bookmarks",
    component: _e5eac3e0,
    name: "bookmarks___en"
  }, {
    path: "/download-history",
    component: _54b06b0c,
    name: "download-history___en"
  }, {
    path: "/login",
    component: _0cec2afc,
    name: "login___en"
  }, {
    path: "/shipment-alerts",
    component: _ea79e106,
    name: "shipment-alerts___en"
  }, {
    path: "/company/:id?",
    component: _7bd780fb,
    name: "company-id___en",
    children: [{
      path: "about",
      component: _23ef770e,
      name: "company-id-about___en"
    }, {
      path: "importers",
      component: _6b5ab6e6,
      name: "company-id-importers___en"
    }, {
      path: "notes",
      component: _add694a6,
      name: "company-id-notes___en"
    }, {
      path: "overview",
      component: _8453c386,
      name: "company-id-overview___en"
    }, {
      path: "shipments",
      component: _8a0999f6,
      name: "company-id-shipments___en"
    }, {
      path: "suppliers",
      component: _5cfffa13,
      name: "company-id-suppliers___en"
    }, {
      path: "visualMap",
      component: _16432568,
      name: "company-id-visualMap___en"
    }]
  }, {
    path: "/shipment-alert/:shipmentAlertId?/:resultId?",
    component: _2aad43ff,
    name: "shipment-alert-shipmentAlertId-resultId___en"
  }, {
    path: "/",
    component: _434487e5,
    name: "index___en",
    children: [{
      path: "analytics",
      component: _27f1785c,
      name: "index-analytics___en"
    }, {
      path: "search/:searchId?",
      component: _1b3a4696,
      name: "index-search-searchId___en"
    }]
  }, {
    path: "/:country/:tradeType?/shipment/:id",
    component: _69fdc79d,
    name: "country-tradeType-shipment-id___en"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
