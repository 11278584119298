var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',[_c('div',{class:{
      'first-condition': _vm.isFirstCondition,
      'condition': _vm.depth !== 0,
      'with-subgroup': _vm.depth !== 0
  }},[(!_vm.isFirstCondition && _vm.depth !== 0)?_c('div',{staticClass:"logic-tag"},[_vm._v(_vm._s(_vm.condition))]):_vm._e(),_vm._v(" "),_c('div',{class:{
        'search-subgroup': _vm.depth !== 0,
        'search-group': _vm.depth === 0
    }},[_c('fieldset',[_c('div',{staticClass:"group-condition-switcher"},[_c('conditions-dropdown',{ref:"conditionsDropdown",on:{"input":function($event){return _vm.$bus.$emit('conditionChanged')}},model:{value:(_vm.selectedCondition),callback:function ($$v) {_vm.selectedCondition=$$v},expression:"selectedCondition"}}),_c('span',{staticClass:"option-note"},[_vm._v("of the following conditions match")]),_vm._v(" "),(_vm.depth !== 0)?_c('i',{staticClass:"removed-icon",on:{"click":function($event){_vm.$emit('remove');_vm.$bus.$emit('conditionChanged');}}}):_vm._e()],1)]),_vm._v(" "),_vm._l((_vm.conditions),function(condition,$index){return [(condition.type === 'condition')?[_c('condition',{key:condition.id,ref:("condition" + $index),refInFor:true,attrs:{"condition":_vm.selectedCondition,"is-first-condition":$index === 0,"is-active":_vm.isActive},on:{"remove":function($event){return _vm.remove($index)}}})]:[_c('condition-group',{key:condition.id,ref:("condition" + $index),refInFor:true,attrs:{"condition":_vm.selectedCondition,"is-first-condition":$index === 0,"depth":_vm.depth + 1},on:{"remove":function($event){return _vm.remove($index)}}})]]})],2),_vm._v(" "),_c('div',{staticClass:"condition-buttons"},[_c('button',{staticClass:"btn btn-text",on:{"click":function($event){return _vm.addCondition(true)}}},[_vm._v("Add Condition")]),_vm._v(" "),(_vm.depth < 2)?_c('button',{staticClass:"btn btn-text secondary",on:{"click":function($event){return _vm.addGroup(true)}}},[_vm._v("Add Group")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }