export default async function verifyCookieLoginId({
  $auth,
  $axios,
  store,
  $cookies
}) {
  const loginCookie = $cookies.get('isng_access_login_id')

  if (!loginCookie) {
    $axios.setToken(null, 'Bearer')

    if ($auth.loggedIn) await $auth.logout()

    return
  }

  await store.dispatch('accounts/verifyCookieLoggedIn')
}
