<template>
  <span class="el-pagination__sizes">
    <el-select
      :value="$parent.internalPageSize"
      @input="handleChange">
      <el-option
        v-for="(item, index) in pageSizes"
        :key="index"
        :value="item"
        :label="item + ' ' + t('el.pagination.pagesize')"/>
    </el-select>
  </span>
</template>

<script>
import Locale from 'element-ui/lib/mixins/locale'
import { Select, Option } from 'element-ui'

export default {
  components: {
    ElSelect: Select,
    ElOption: Option
  },
  mixins: [Locale],

  props: {
    pageSizes: {
      required: true,
      type: Array
    }
  },

  watch: {
    pageSizes: {
      immediate: true,
      handler(value) {
        if (Array.isArray(value)) {
          this.$parent.internalPageSize =
            value.indexOf(this.$parent.pageSize) > -1
              ? this.$parent.pageSize
              : this.pageSizes[0]
        }
      }
    }
  },

  methods: {
    handleChange(val) {
      if (val !== this.$parent.internalPageSize) {
        this.$parent.internalPageSize = val = parseInt(val, 10)
      }
    }
  }
}
</script>
