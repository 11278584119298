export default ({ params, store, redirect }) => {
  const { id: companyId } = params
  const remainingCompanyViews = store.getters['userCredits/companyViewsCredits']
  const {
    companyIds: viewedCompanyIds
  } = store.state.userCredits.companyProfileViews

  if (!remainingCompanyViews && !viewedCompanyIds.includes(companyId)) {
    redirect(
      REDIRECT_URLS.UPGRADE_SUBSCRIPTION_PLACEHOLDER /*eslint-disable-line no-undef */
    )
  }
}
