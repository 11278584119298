<template>
  <span 
    v-if="$parent.total === 'number'" 
    class="el-pagination__total">
    {{
      t('el.pagination.total', {
        total: this.$numeral(this.$parent.total).format('0,0')
      })
    }}
  </span>
</template>
<script>
import Locale from 'element-ui/lib/mixins/locale'

export default {
  mixins: [Locale]
}
</script>
