<template>
  <div
    :class="optClass"
    class="credit-widget">
    <h4>{{ label }}</h4>
    <div class="progress">
      <div
        :style="`width: ${100 - +values.percentage}%`"
        :class="progressBarColor"
        class="progress-bar"/>
    </div>
    <div class="credit-counts">
      <span v-if="values.percentage === 'Not Available'">Not Available</span>
      <template v-else>
        <span>{{ values.credits | numberWithLetters(2) }} {{ creditsLabel }} left</span>
        <span>{{ values.percentage }}% used</span>
      </template>
    </div>
  </div>
</template>

<script>
import { numberWithLetters } from '~/utils/number'

export default {
  name: 'CreditWidget',
  filters: {
    numberWithLetters
  },
  props: {
    label: {
      type: String,
      required: true
    },
    values: {
      type: Object,
      required: true
    },
    creditsLabel: {
      type: String,
      required: true
    },
    optClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    progressBarColor() {
      if (this.values.percentage > 74) {
        return 'red-bar'
      } else if (this.values.percentage > 49 && this.values.percentage < 75) {
        return 'gold-bar'
      } else {
        return 'green-bar'
      }
    }
  }
}
</script>
