export default function installCustomerio(store) {
  if (!process.env.CUSTOMERIO_SITE_ID) return
  ;(() => {
    if (!global._cio) {
      global._cio = []
      var a, b, c
      a = function(f) {
        return function() {
          global._cio.push([f].concat(Array.prototype.slice.call(arguments, 0)))
        }
      }
      b = ['load', 'identify', 'sidentify', 'track', 'page']
      for (c = 0; c < b.length; c++) {
        global._cio[b[c]] = a(b[c])
      }
      var t = document.createElement('script'),
        s = document.getElementsByTagName('script')[0]
      t.async = true
      t.id = 'cio-tracker'
      t.setAttribute('data-site-id', process.env.CUSTOMERIO_SITE_ID)
      t.src = 'https://assets.customer.io/assets/track.js'
      s.parentNode.insertBefore(t, s)
    }

    // if user is available, identify it
    // if user is not available, identify must be called once available(after login)
    if (store.state.auth && store.state.auth.user) {
      const user = store.state.auth.user
      global._cio.identify({
        id: user.clientId,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName
      })
    }
  })()
}
