export default {
  computed: {
    isModalOpen: {
      get() {
        return this.$store.getters['userSubscriptions/upgradeModalIsOpened']
      },
      set(isOpen) {
        this.$store.commit('userSubscriptions/setOpenUpgradeModal', isOpen)
      }
    }
  }
}
