export default {
  methods: {
    async generateDownloadLink({ id, zip = false }) {
      const { country, tradeType } = this.$store.state
      const { data } = await this.$axios.get(
        `${SHIPMENTS_API_URL}/v1/${country}/${tradeType}/downloads/${id}/download`,
        {
          params: {
            zip
          }
        }
      )
      window.location.href = data.url
    }
  }
}
