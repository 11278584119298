<template>
  <multiselect
    ref="multiselect"
    :value="parsedValue"
    :options="options"
    :searchable="true"
    :show-labels="false"
    :allow-empty="false"
    placeholder="Search Field"
    class="custom-select"
    @input="onInput"
    @open="$emit('open', true)"
    @close="$emit('close', false)">
    <template
      slot="singleLabel"
      slot-scope="{ option }">
      <span>{{ option }}</span>
    </template>
    <template
      slot="option"
      slot-scope="{ option }">
      <span>{{ option }}</span>
    </template>
    <span
      slot="noResult"
      class="field-not-found">No fields found.</span>
  </multiselect>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('views', ['fields']),
    ...mapGetters('views', ['availableFields']),
    parsedValue() {
      if (this.fields[this.value]) return this.fields[this.value].displayName
      return this.availableFields[0].displayName
    },
    options() {
      return Object.values(this.availableFields).map(v => v.displayName)
    }
  },
  methods: {
    onInput(value) {
      this.$emit(
        'input',
        Object.keys(this.availableFields).find(
          key => this.availableFields[key].displayName === value
        )
      )
    }
  }
}
</script>
