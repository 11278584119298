export class ObserversNotSupportedError extends Error {
  constructor(
    message = 'Observers API is not supported by the client browser'
  ) {
    super(message)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }

  get name() {
    return this.constructor.name
  }
}

export class InitializationFailedError extends Error {
  constructor(message) {
    super(message)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }

  get name() {
    return this.constructor.name
  }
}

export class IllegalInstantiationError extends Error {
  constructor(
    message = 'FloatingHorizontalScrollBar can only be instantiated with create() builder method'
  ) {
    super(message)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }

  get name() {
    return this.constructor.name
  }
}

export default {
  ObserversNotSupportedError,
  InitializationFailedError,
  IllegalInstantiationError
}
