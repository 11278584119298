import _ from 'lodash'

export const state = () => ({
  description: '',

  address: '',

  otherInfo: [],

  contactInfo: [],

  contactFilter: 'MostActive',

  locations: []
})

export const mutations = {
  resetState(currentState) {
    Object.assign(currentState, state())
  },
  setDescription(state, description) {
    state.description = description
  },
  setOtherInfo(state, otherInfo) {
    state.otherInfo = otherInfo
  },
  addOtherInfo(state, otherInfo) {
    state.otherInfo = [...state.otherInfo, otherInfo]
  },
  editOtherInfo(state, otherInfo) {
    state.otherInfo = _.map(state.otherInfo, info => {
      if (info.id === otherInfo.id) {
        return otherInfo
      }

      return info
    })
  },
  deleteOtherInfo(state, id) {
    state.otherInfo = _.filter(state.otherInfo, info => info.id !== id)
  },
  setContactInfo(state, contactInfo) {
    state.contactInfo = contactInfo
  },
  addContactInfo(state, contactInfo) {
    state.contactInfo = [...state.contactInfo, contactInfo]
  },
  editContactInfo(state, contactInfo) {
    state.contactInfo = _.map(state.contactInfo, info => {
      if (info.id === contactInfo.id) {
        return contactInfo
      }

      return info
    })
  },
  deleteContactInfo(state, id) {
    state.contactInfo = _.filter(state.contactInfo, info => info.id !== id)
  },
  setLocations(state, locations) {
    state.locations = locations
  },
  setAddressInfo(state, address) {
    state.address = address
  }
}

export const actions = {
  async initialize({ dispatch }) {
    await dispatch('fetchCompanyAbout')
  },
  async fetchCompanyAbout({ commit, rootState }) {
    try {
      const { data } = await this.$axios.$get(
        `${COMPANY_API_URL}/companies/${
          rootState.company.companyId
        }/information`,
        { progress: false }
      )

      const { description, otherInfo, contactInfo, address } = data

      commit('setDescription', description)
      commit('setOtherInfo', otherInfo)
      commit('setContactInfo', contactInfo)
      commit('setAddressInfo', address)
    } catch (error) {
      if (this.$axios.isCancel(error)) return
      throw error
    }
  },
  async editDescription({ commit, rootState }, description) {
    await this.$axios.$put(
      `${COMPANY_API_URL}/companies/${
        rootState.company.companyId
      }/information/description`,
      {
        description
      }
    )

    commit('setDescription', description)
  },
  async addContactInfo({ rootState, commit }, payload) {
    const { data } = await this.$axios.$post(
      `${COMPANY_API_URL}/companies/${
        rootState.company.companyId
      }/information/contacts`,
      payload
    )

    commit('addContactInfo', data)
  },
  async editContactInfo({ rootState, commit }, { id, label, description }) {
    const { data } = await this.$axios.$put(
      `${COMPANY_API_URL}/companies/${
        rootState.company.companyId
      }/information/contacts/${id}`,
      {
        label,
        description
      }
    )

    commit('editContactInfo', data)
  },
  async deleteContactInfo({ rootState, commit }, { id }) {
    await this.$axios.$delete(
      `${COMPANY_API_URL}/companies/${
        rootState.company.companyId
      }/information/contacts/${id}`
    )

    commit('deleteContactInfo', id)
  },
  async addOtherInfo({ rootState, commit }, payload) {
    const { data } = await this.$axios.$post(
      `${COMPANY_API_URL}/companies/${
        rootState.company.companyId
      }/information/others`,
      payload
    )

    commit('addOtherInfo', data)
  },
  async editOtherInfo({ rootState, commit }, { id, label, description }) {
    const { data } = await this.$axios.$put(
      `${COMPANY_API_URL}/companies/${
        rootState.company.companyId
      }/information/others/${id}`,
      {
        label,
        description
      }
    )

    commit('editOtherInfo', data)
  },
  async deleteOtherInfo({ rootState, commit }, { id }) {
    await this.$axios.$delete(
      `${COMPANY_API_URL}/companies/${
        rootState.company.companyId
      }/information/others/${id}`
    )

    commit('deleteOtherInfo', id)
  }
}
