import FloatingHorizontalScrollBar from './FloatingHorizontalScrollBar'

/*
 * Usage:
 *  Suggested registration:
 *    Vue.directive(floatHorizontal.name, floatHorizontal)
 *  Component binding:
 *    - pass `v-scroll-float-horizontal` directive to component
 *    - accepts object with properties:
 *      - enabled: determines visibility
 *      - zIndex: custom floating scrollbar z-index property
 *      - offsetBottom: custom bottom offset of the floating scrollbar. Useful
 *          for instances where there is something on top of the targets bottom
 *          part - where the floating scrollbar is being rendered
 *    - scroll element will be created on first truthy enabled property and
 *      reused for succeeding updates
 *    - not passing an enabled property defaults to `true`
 */

const name = 'scroll-float-horizontal'
const instanceHelper = new function() {
  this.storageName = '$scrollFloatHorizontal'

  this.exists = function(context) {
    return this.storageName in context
  }

  this.initialize = function(el, context) {
    context[this.storageName] = FloatingHorizontalScrollBar.create(el)

    return this.extract(context)
  }

  this.extract = function(context) {
    return Promise.resolve(context[this.storageName])
  }

  /* What: Function to ensure that expected changes are committed after the DOM
   *  has rendered. Abandons attempts when condition is not met in
   *  given repetitions.
   * How: calls the execute argument in an interval while condition is not met
   * Why: Vue.$nextTick(callback) and setTimeout(callback, 0) are not reliable
   *  as they run the callback as soon as the thread is freed, not when DOM is
   *  fully rendered
   * Whom: Setters/Method calls that depend on fully rendered DOM elements
   */
  this.forceState = function(
    execute = () => {},
    expectedCondition = () => true,
    { repeat = 4, interval = 500 } = {}
  ) {
    try {
      if (!repeat || expectedCondition()) return

      setTimeout(() => {
        execute()
        this.forceState(execute, expectedCondition, {
          repeat: --repeat,
          interval
        })
      }, interval)
    } catch (err) {
      console.error(err)
    }
  }

  this.configure = function(el, options, context) {
    const { enabled = true, zIndex = 1, offsetBottom = 0 } = options

    if (enabled) {
      if (!this.exists(context)) this.initialize(el, context)

      this.extract(context).then(instance => {
        if (instance.isDestroyed) return this.destroy(context)

        instance.zIndex = zIndex
        instance.offsetBottom = offsetBottom

        instance.show()
        this.forceState(
          () => context.$nextTick(() => instance.reRender()),
          () => instance.isDestroyed || instance.visible || !instance.shown
        )
      })
    } else {
      if (!this.exists(context)) return

      this.extract(context).then(instance => {
        if (instance.isDestroyed) return this.destroy(context)

        instance.zIndex = zIndex
        instance.offsetBottom = offsetBottom

        instance.hide()
        this.forceState(
          () => context.$nextTick(() => instance.reRender()),
          () => instance.isDestroyed || !instance.visible || instance.shown
        )
      })
    }
  }

  this.destroy = function(context) {
    if (!this.exists(context)) return

    this.extract(context).then(instance => {
      if (!instance.isDestroyed) instance.destroy()

      delete context[this.storageName]
    })
  }
}()

export default {
  name,
  deep: true,
  bind(el, { value: options }, { context }) {
    try {
      if (!FloatingHorizontalScrollBar.isSupported) {
        return console.warn(
          `'${name}' directive not initialized as it requires features not supported by the client's browser`
        )
      }

      instanceHelper.configure(el, options, context)
    } catch (err) {
      console.error(err)
    }
  },
  update(el, { value: options }, { context }) {
    try {
      instanceHelper.configure(el, options, context)
    } catch (err) {
      console.error(err)
    }
  },
  unbind(_el, _binding, { context }) {
    try {
      instanceHelper.destroy(context)
    } catch (err) {
      console.error(err)
    }
  }
}
