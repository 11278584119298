<template>
  <span class="el-pagination__jump">
    {{ t('el.pagination.goto') }}
    <input
      :max="internalCurrentPage"
      v-model="page"
      :style="{ width }"
      class="el-pagination__editor"
      type="number"
      min="1"
      @blur="checkValue"
      @keyup.enter="$event.target.blur">
    {{ t('el.pagination.pageClassifier') }}
  </span>
</template>
<script>
import Locale from 'element-ui/lib/mixins/locale'

const BASE_WIDTH = 30

export default {
  name: 'Jumper',
  mixins: [Locale],

  data() {
    return {
      width: BASE_WIDTH,
      page: 1
    }
  },

  computed: {
    internalCurrentPage() {
      return this.$parent.internalCurrentPage
    }
  },

  watch: {
    internalCurrentPage: {
      immediate: true,
      handler(value) {
        this.page = value
      }
    },
    page: {
      immediate: true,
      handler(value) {
        const additionalChar = value.toString().length - 1
        const pxPerDigit = 5
        const additionalPx = additionalChar * pxPerDigit

        this.width = BASE_WIDTH + additionalPx + 'px'
      }
    }
  },

  methods: {
    checkValue(event) {
      const num = parseInt(event.target.value, 10)
      if (isNaN(num)) {
        this.page = this.internalCurrentPage
        return
      }
      this.$parent.internalCurrentPage = this.$parent.getValidCurrentPage(num)
      this.page = this.$parent.internalCurrentPage
    }
  }
}
</script>
<style lang="scss" scoped>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
