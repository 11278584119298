<template>

  <!-- This component is currently not used and is outdated. -->

  <el-dialog
    :visible.sync="isOpen"
    class="keywords-modal with-footer"
    title="Advanced Search"
    @opened="onOpened"
    @closed="closeModal">
    <div class="modal-body">
      <condition-group
        ref="keywords"
        :depth="0"
        @clear="clearAll"/>
    </div>
    <div
      v-if="searchCredits <= 5"
      class="alert small alert-info">
      <div>
        <strong>
          {{ searchCredits }} search{{ searchCredits > 1 ? 'es' : '' }}
        </strong> remaining.
        <a
          href="#"
          class="alert-link"
          @click="openUpgradeModalAndCloseThis">Upgrade now</a>
        <span>to get more searches per day. </span>
      </div>
    </div>
    <div class="modal-footer flex-container justify-space-between">
      <div class="modal-message">
        <result-count-messages
          v-if="!$actions.pending('search/preFetchResultCount')"
          :pre-fetched-counts="preFetchedCounts"
          :current-date-range="dateRange"
          :user-has-premium-subscription="hasPremiumSubscription"/>
        <ContentLoader
          v-else
          :height="23">
          <rect
            x="0"
            y="0%"
            width="95%"
            height="40%"/>
          <rect
            x="0"
            y="60%"
            width="75%"
            height="40%"/>
        </ContentLoader>
      </div>
      <div class="button-group">
        <button
          class="btn btn-text secondary"
          @click.prevent="clearAll">Clear All</button>
        <button
          :class="prefetchedQueryCountIsZero ? 'disabled' : ''"
          type="button"
          class="btn btn-primary"
          @click.prevent="advancedSearch">Run Search</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import ConditionGroup from './ConditionGroup'
import ResultCountMessages from './ResultCountMessages'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { ContentLoader } from '~/components/custom/vue-content-loader'
import { debounce } from 'lodash'
import { openUpgradeModal } from '~/mixins/upgradeModal/methods'

export default {
  name: 'AdvancedSearchModal',
  components: {
    ConditionGroup,
    ContentLoader,
    ResultCountMessages
  },
  mixins: [openUpgradeModal],
  data() {
    return {
      isOpen: false,
      isNewSearch: true
    }
  },
  computed: {
    ...mapState('search', ['searchKeywords', 'preFetchedCounts']),
    ...mapState(['dateRange']),
    ...mapGetters(['currentSearchURL']),
    ...mapGetters('search', ['hasSearch']),
    ...mapGetters('userCredits', ['searchCredits']),
    ...mapGetters('userSubscriptions', [
      'hasPremiumSubscription',
      'hasPlanLimited'
    ]),
    advancedSearchKeywords() {
      const initialSearch = this.hasPlanLimited
        ? {
            o: 'AND',
            i: [
              {
                keyword: '',
                type: 'contains',
                field: 'Consignee'
              }
            ]
          }
        : {
            o: 'AND',
            i: [
              {
                keyword: '',
                type: 'contains',
                field: 'ProductDescription'
              }
            ]
          }
      return this.hasSearch ? this.searchKeywords : initialSearch
    },
    prefetchedQueryCountIsZero() {
      return (
        this.preFetchedCounts.shipments &&
        !this.preFetchedCounts.shipments.dateRange
      )
    }
  },
  watch: {
    isOpen(to) {
      if (to === false) this.clearPreFetchedResultCounts()
    }
  },
  created() {
    this.$bus.$on('startAdvancedSearch', this.advancedSearch)
    this.$bus.$on('conditionChanged', this.prefetchResultCount)
  },
  destroyed() {
    this.$bus.$off('startAdvancedSearch', this.advancedSearch)
    this.$bus.$off('conditionChanged', this.prefetchResultCount)
  },
  mounted() {
    this.$bus.$on('openAdvancedSearchModal', this.openModal)
  },
  methods: {
    ...mapActions('search', [
      'preFetchResultCount',
      'setDefaultMiscFilters',
      'search'
    ]),
    ...mapMutations('search', [
      'clearPreFetchedResultCounts',
      'setPreFetchSearchKeywords',
      'setSearchKeywords'
    ]),
    openUpgradeModalAndCloseThis() {
      this.openUpgradeModal()
      this.isOpen = false
    },
    openModal(newSearch) {
      this.isOpen = true
      this.isNewSearch = newSearch
    },
    closeModal() {
      this.isOpen = false
      this.clearAll()
    },
    onOpened() {
      if (!this.isNewSearch)
        this.$refs.keywords.setValue(this.advancedSearchKeywords)
    },
    clearAll() {
      this.$refs.keywords.clear()
      this.clearPreFetchedResultCounts()
    },
    prefetchResultCount: debounce(function prefetchResultCount(
      clearSearch = false
    ) {
      const isValid = this.$refs.keywords.validate({ updateState: false })

      if (!isValid || clearSearch) {
        this.clearPreFetchedResultCounts()
        return
      }

      const advancedSearchKeywords = this.$refs.keywords.getValue(true)
      this.setPreFetchSearchKeywords(advancedSearchKeywords)

      this.preFetchResultCount().then(() => {
        this.$refs.keywords.validate({ updateState: true })
      })
    },
    500),
    advancedSearch() {
      const isValid = this.$refs.keywords.validate()

      if (!isValid) return

      const advancedSearchKeywords = this.$refs.keywords.getValue(true)

      this.setSearchKeywords(advancedSearchKeywords)
      this.setDefaultMiscFilters()
      this.search().then(() => this.$router.push(this.currentSearchURL))

      this.closeModal()
    }
  }
}
</script>

<style src="~/assets/scss/components/advanced-search/advanced-search.scss" lang="scss"/>
