import { countries } from '~/utils/address'
import { find } from 'lodash'

export const state = () => ({
  user: {},
  isGravatar: false,
  isCardDeclined: false,
  passwordErrorMessage: '',
  alerts: []
})

export const getters = {
  userCreatedDate(state) {
    if (state.user) {
      return state.user.created
    }

    return null
  },
  email(state) {
    if (state.user) {
      return state.user.email
    }

    return null
  },
  name(state) {
    return state.user
      ? `${state.user.firstname || ''} ${state.user.lastname || ''}`
      : null
  },
  phone(state) {
    return state.user ? state.user.phone : null
  }
}

export const mutations = {
  setAccountInfo(state, data) {
    state.user = {
      ...data,
      profilePicture: ''
    }
  },
  setPasswordErrorMessage(state, data) {
    state.passwordErrorMessage = data
  },
  setIsGravatar(state, isGravatar) {
    state.isGravatar = isGravatar
  },
  setUserProfilePicture(state, pictureUrl) {
    state.user.image_url = pictureUrl
  },
  updateUserDetails(state, accountInfo) {
    state.user = {
      ...state.user,
      ...accountInfo
    }
  },
  updatePaymentInfo(state, data) {
    const { paymentDetails, billingInfo, billingAddress } = data
    state.user.paymentDetails = Object.assign({}, paymentDetails)
    state.user.billingInfo = Object.assign({}, billingInfo)
    state.user.billingAddress = Object.assign({}, billingAddress)
  },
  setAlerts(state, alerts) {
    state.alerts = alerts
  }
}

export const actions = {
  async fetchAccountInfo({ commit }) {
    try {
      const { data } = await this.$axios.$get(
        `${USERS_API_URL}/client/account-info`
      )
      const hasCountry = isNaN(data.country)
      const country = hasCountry
        ? find(countries, { code: data.country.toUpperCase() })
        : ''
      const accountInfo = {
        firstname: data.firstname,
        lastname: data.lastname,
        business: data.business,
        country: country,
        phone: data.phone,
        email: data.email,
        username: data.username,
        image_url: data.image_url,
        contract: data.contract,
        availableCountries: data.availableCountries,
        created: data.created,
        requireInfoUpdate: data.requireInfoUpdate,
        requirePasswordChange: data.require_password_change
      }
      commit('setAccountInfo', accountInfo)
    } catch (error) {
      if (this.$axios.isCancel(error)) return
      throw error
    }
  },
  async fetchAlerts({ commit }) {
    const { clientId } = this.$auth.user

    try {
      const { data } = await this.$axios.$get(
        `${USERS_API_URL}/client/${clientId}/alerts`
      )

      const parsedData = data.map(alert => {
        const updatedAlert = { ...alert }
        updatedAlert.banner_data = JSON.parse(updatedAlert.banner_data)
        return updatedAlert
      })

      commit('setAlerts', parsedData)
    } catch (err) {
      console.error(err)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async updateAlerts({ commit }, payload) {
    const { clientId } = this.$auth.user
    return await this.$axios.$put(
      `${USERS_API_URL}/client/${clientId}/alerts`,
      payload
    )
  },
  async updateAccountInfo({ commit }, accountInfo) {
    const { clientId } = this.$auth.user
    try {
      const { data } = await this.$axios.$patch(
        `${USERS_API_URL}/client/${clientId}/account-info`,
        {
          ...accountInfo
        }
      )

      if (data.hasOwnProperty('country')) {
        data.country = find(countries, { code: data.country.toUpperCase() })
      }

      commit('updateUserDetails', data)
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async updateImage({ commit }, imageUrl) {
    const { clientId } = this.$auth.user
    try {
      const { data } = await this.$axios.$patch(
        `${USERS_API_URL}/client/${clientId}/account-info`,
        {
          ...imageUrl
        }
      )

      commit('setUserProfilePicture', data)
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async updatePassword({ commit }, password) {
    const { clientId } = this.$auth.user
    try {
      const { data } = await this.$axios.$put(
        `${USERS_API_URL}/v1/client/${clientId}/change-password`,
        {
          ...password
        }
      )

      return data
    } catch (error) {
      const response = error.response ? error.response.data : ''

      if (response.error.reason === 'Incorrect current password') {
        commit('setPasswordErrorMessage', response.error.reason)
        return
      }

      if (response.error.reason === 'Password must differ from old password') {
        commit('setPasswordErrorMessage', response.error.reason)
        return
      }

      throw error
    }
  },
  async verify() {
    const { data } = await this.$axios.$get('/api/verify')

    return data.username || ''
  },
  async verifyCookieLoggedIn({ dispatch }) {
    const username = (await dispatch('verify')) || ''

    const loggedInUsername = this.$auth.user ? this.$auth.user.username : ''

    if (
      !username.trim() ||
      !loggedInUsername.trim() ||
      (username.trim() !== loggedInUsername.trim() &&
        this.route.path !== '/login')
    ) {
      this.$axios.setToken(null, 'Bearer')
      await this.$auth.logout()
    }
  }
}
