export default function watchTitle() {
  let isRefreshing = false

  const observer = new MutationObserver(function(mutations) {
    const newValue = mutations[0].target.nodeValue
    if (newValue || isRefreshing) {
      isRefreshing = false
      return
    }
    isRefreshing = true
    $nuxt.$meta().refresh()
  })

  const target = document.querySelector('head > title')
  const config = { subtree: true, characterData: true, childList: true }
  observer.observe(target, config)
}
