<template>
  <span>
    <transition
      :name="transition"
      @after-enter="handleAfterEnter"
      @after-leave="handleAfterLeave">
      <div
        v-show="!disabled && showPopper"
        ref="popper"
        :class="[popperClass, content && 'el-popover--plain']"
        :style="{ width: width + 'px' }"
        :id="tooltipId"
        :aria-hidden="(disabled || !showPopper) ? 'true' : 'false'"
        class="el-popover el-popper"
        role="tooltip">
        <div 
          v-if="title" 
          class="el-popover__title" 
          v-text="title"/>
        <slot>{{ content }}</slot>
      </div>
    </transition>
    <slot name="reference"/>
  </span>
</template>
<script>
import tabbable from 'tabbable'
import Popper from 'element-ui/lib/utils/vue-popper'
import { on, off } from 'element-ui/lib/utils/dom'
import { addClass, removeClass } from 'element-ui/lib/utils/dom'
import { generateId } from 'element-ui/lib/utils/util'
export default {
  name: 'ElPopover',
  mixins: [Popper],
  props: {
    trigger: {
      type: String,
      default: 'click',
      validator: value =>
        ['click', 'focus', 'hover', 'manual'].indexOf(value) > -1
    },
    openDelay: {
      type: Number,
      default: 0
    },
    title: String,
    disabled: Boolean,
    content: String,
    reference: {},
    popperClass: String,
    width: {},
    visibleArrow: {
      default: true
    },
    arrowOffset: {
      type: Number,
      default: 0
    },
    transition: {
      type: String,
      default: 'fade-in-linear'
    }
  },
  computed: {
    tooltipId() {
      return `el-popover-${generateId()}`
    }
  },
  watch: {
    showPopper(val) {
      if (this.disabled) {
        return
      }

      if (!val) {
        off(document, 'click', this.handleDocumentClick)
        return this.$emit('hide')
      }
      this.$emit('show')
      if (this.trigger === 'click') {
        on(document, 'click', this.handleDocumentClick)
      }
    }
  },
  mounted() {
    let reference = (this.referenceElm = this.reference || this.$refs.reference)
    const popper = this.popper || this.$refs.popper
    if (!reference && this.$slots.reference && this.$slots.reference[0]) {
      reference = this.referenceElm = this.$slots.reference[0].elm
    }
    // 可访问性
    if (reference) {
      addClass(reference, 'el-popover__reference')
      reference.setAttribute('aria-describedby', this.tooltipId)
      reference.setAttribute('tabindex', 0) // tab序列
      popper.setAttribute('tabindex', -1)
      if (this.trigger !== 'click') {
        on(reference, 'focusin', () => {
          this.handleFocus()
          const instance = reference.__vue__
          if (instance && typeof instance.focus === 'function') {
            instance.focus()
          }
        })
        on(popper, 'focusin', this.handleFocus)
        on(reference, 'focusout', this.handleBlur)
        on(popper, 'focusout', this.handleBlur)
      }
      on(reference, 'keydown', this.handleReferenceKeydown)
      on(reference, 'click', this.handleClick)
      on(popper, 'keydown', this.handlePopperKeydown)
    }
    if (this.trigger === 'click') {
      on(reference, 'click', this.doToggle)
      // on(document, 'click', this.handleDocumentClick)
    } else if (this.trigger === 'hover') {
      on(reference, 'mouseenter', this.handleMouseEnter)
      on(popper, 'mouseenter', this.handleMouseEnter)
      on(reference, 'mouseleave', this.handleMouseLeave)
      on(popper, 'mouseleave', this.handleMouseLeave)
    } else if (this.trigger === 'focus') {
      if (reference.querySelector('input, textarea')) {
        on(reference, 'focusin', this.doShow)
        on(reference, 'focusout', this.doClose)
      } else {
        on(reference, 'mousedown', this.doShow)
        on(reference, 'mouseup', this.doClose)
      }
    }
  },
  beforeDestroy() {
    this.cleanup()
  },
  deactivated() {
    this.cleanup()
  },
  destroyed() {
    const reference = this.reference
    off(reference, 'click', this.doToggle)
    off(reference, 'mouseup', this.doClose)
    off(reference, 'mousedown', this.doShow)
    off(reference, 'focusin', this.doShow)
    off(reference, 'focusout', this.doClose)
    off(reference, 'mousedown', this.doShow)
    off(reference, 'mouseup', this.doClose)
    off(reference, 'mouseleave', this.handleMouseLeave)
    off(reference, 'mouseenter', this.handleMouseEnter)
    off(reference, 'keydown', this.handleReferenceKeydown)
    off(document, 'click', this.handleDocumentClick)
  },
  methods: {
    doToggle() {
      this.showPopper = !this.showPopper
    },
    doShow() {
      this.showPopper = true
    },
    doClose() {
      this.showPopper = false
    },
    handleFocus() {
      addClass(this.referenceElm, 'focusing')
      if (this.trigger === 'click' || this.trigger === 'focus') this.doShow()
    },
    handleClick() {
      removeClass(this.referenceElm, 'focusing')
    },
    handleBlur() {
      removeClass(this.referenceElm, 'focusing')
      if (this.trigger === 'click' || this.trigger === 'focus')
        this.showPopper = false
    },
    handleMouseEnter() {
      clearTimeout(this._timer)
      if (this.openDelay) {
        this._timer = setTimeout(() => {
          this.showPopper = true
        }, this.openDelay)
      } else {
        this.showPopper = true
      }
    },
    handleReferenceKeydown(event) {
      // if (ev.keyCode === 27 && this.trigger !== 'manual') {
      //   // esc
      //   return this.doClose()
      // }
      const keyCode = event.key.toUpperCase()
      if (keyCode === 'ESCAPE') {
        if (this.trigger !== 'manual') {
          this.doClose()
        }
        return event.preventDefault()
      }

      if (keyCode === 'TAB') {
        // Focus first tabbable element in popover
        if (!event.shiftKey && !this.disabled && this.showPopper) {
          const popper = this.popper || this.$refs.popper
          const [tabbableEl] = tabbable(popper)
          if (tabbableEl && typeof tabbableEl.focus === 'function') {
            event.preventDefault()
            tabbableEl.focus()
          }
        }
      }
    },
    handlePopperKeydown(event) {

      if(!event || !event.key) return
      
      // tabs out of popper element and closes popper
      const keyCode = event.key.toUpperCase()
      if (keyCode !== 'TAB') {
        return
      }
      const popper = this.popper || this.$refs.popper
      const tabbableElements = tabbable(popper)
      const { target } = event

      if (event.shiftKey && target === tabbableElements[0]) {
        this.referenceElm.focus()
      } else if (
        !event.shiftKey &&
        target === tabbableElements[tabbableElements.length - 1]
      ) {
        const bodyTabbableElements = tabbable(document.body)
        let nextIndex = bodyTabbableElements.indexOf(this.referenceElm) + 1
        if (nextIndex === bodyTabbableElements.length) {
          nextIndex = 0
        }
        bodyTabbableElements[nextIndex].focus()
        this.showPopper = false
      } else {
        return
      }

      event.preventDefault()
    },
    handleMouseLeave() {
      clearTimeout(this._timer)
      this._timer = setTimeout(() => {
        this.showPopper = false
      }, 200)
    },
    handleDocumentClick(e) {
      let reference = this.reference || this.$refs.reference
      const popper = this.popper || this.$refs.popper
      if (!reference && this.$slots.reference && this.$slots.reference[0]) {
        reference = this.referenceElm = this.$slots.reference[0].elm
      }

      if (
        !this.$el ||
        !reference ||
        this.$el.contains(e.target) ||
        reference.contains(e.target) ||
        !popper ||
        popper.contains(e.target)
      )
        return
      this.showPopper = false
    },
    handleAfterEnter() {
      this.$emit('after-enter')
    },
    handleAfterLeave() {
      this.$emit('after-leave')
      this.doDestroy()
    },
    cleanup() {
      if (this.openDelay) {
        clearTimeout(this._timer)
      }
    }
  }
}
</script>
