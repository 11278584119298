import { startCase, toLower } from 'lodash'
const blankStrings = ['', '-NOT AVAILABLE-']

export const isBlankString = string =>
  typeof string !== 'string' || blankStrings.indexOf(string) > -1

export const escapeRegExp = string =>
  string.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')

export const fixCapitalization = string => {
  const separators = ' /,().'.split('')
  let newStr = string.toLowerCase()

  separators.forEach(separator => {
    newStr = newStr
      .split(separator)
      .map(str => str.charAt(0).toUpperCase() + str.slice(1))
      .join(separator)
  })

  return newStr
}

export const toUpperFirst = str => {
  return startCase(toLower(str))
}
