<template>
  <div
    v-loading="isComponentLoading"
    :class="{'tour-active': tourActive, 'height-100': isSidebarShown}"
    class="site-wrapper">
    <include-exclude-mobile v-if="$device.isMobile"/>
    <beta-modal
      v-if="isBetaModalOpen"
      @close="showFirstLoginTour"/>
    <preview-modal />
    <navbar/>

    <upgrade-subscription-modal
      v-if="isModalOpen"
      :show.sync="isModalOpen"
      :source="planTypeUrl"
      :plan="subscriptionPlan"
      label="" />

    <div class="container site-container">
      <nuxt class="default-layout"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { formatPlanDescription } from '~/utils/plans'
import IncludeExcludeMobile from '~/components/layouts/IncludeExcludeMobile'
import AlertBanner from '~/components/common/AlertBanner'
import Navbar from '~/components/layouts/Navbar'
import BetaModal from '~/components/layouts/BetaModal'
import AdvancedSearchModal from '~/components/layouts/AdvancedSearchModal'
import PreviewModal from '~/components/common/PreviewModal'
import cioPageTracking from '~/plugins/mixins/cioPageTracking'
import UpgradeSubscriptionModal from '../components/common/UpgradeSubscriptionModal'
import { initReferrerCookie } from '~/middleware/check-referrer'
import { upgradeModal, offlineTracking } from '~/mixins'

export default {
  components: {
    UpgradeSubscriptionModal,
    Navbar,
    IncludeExcludeMobile,
    BetaModal,
    AdvancedSearchModal,
    PreviewModal,
    AlertBanner
  },

  mixins: [cioPageTracking, upgradeModal, offlineTracking],
  middleware: [initReferrerCookie],
  data() {
    return {
      showUpgradeModal: false,
      planTypeUrl: '',
      isOpen: true,
      openMigrationModal: false
    }
  },
  computed: {
    ...mapState(['inFullscreen']),
    ...mapGetters('userSubscriptions', [
      'subscription',
      'subscriptionPlanType',
      'hasActiveLegacySubscription',
      'hasPendingNonUsSubscription'
    ]),
    ...mapState(['isBetaModalOpen', 'isSidebarShown']),
    ...mapState('tours', ['tourActive']),
    ...mapState('auth', ['user']),
    ...mapState('search', ['isComponentLoading']),

    isSearching() {
      return this.$actions.pending('search/search')
    },

    subscriptionPlan() {
      return formatPlanDescription(this.subscription)
    }
  },

  watch: {
    $route(to, from) {
      const root = document.getElementsByTagName('html')[0]

      if (to.path !== from.path) {
        if (!to.path.includes('/search')) {
          this.setSidebarContent(false)

          root.classList.remove('overflow-hidden')
        }
        this.setInFullscreen(false)
      }

      if (to.query.view === 'analytics' || to.query.view === 'overview') {
        root.style.height = 'auto'
        return
      }

      root.style.height = null
    },

    isSearching(searching) {
      if (searching) {
        document.documentElement.style.overflow = 'hidden'
        return
      }
      document.documentElement.style.overflow = 'auto'
    },

    isModalOpen(isOpen) {
      if (isOpen) {
        if (!this.subscriptionPlan) return ''
        if (this.subscriptionPlan.type === 'Limited') {
          this.planTypeUrl = `${PROMO_URL}/show/10` /*eslint-disable-line no-undef */
          return
        }
        this.planTypeUrl = `${PROMO_URL}/show/11` /*eslint-disable-line no-undef */
      }
    },

    inFullscreen(isFullscreen) {
      const root = document.getElementsByTagName('html')[0]
      if (isFullscreen) {
        root.classList.add('overflow-hidden')
        return
      }

      root.classList.remove('overflow-hidden')
    }
  },

  mounted() {
    // @TODO https://github.com/nuxt/nuxt.js/issues/2738
    if (window) {
      window.history.scrollRestoration = 'auto'
    }

    this.countDownRefresh()
    this.$keyboardEvents.register()

    this.$bus.$on('changeSelectedPlan', this.changeSelectedPlan)

    // initialize migration modal
    this.fetchRecurlySubscriptions()
  },

  beforeDestroy() {
    this.$keyboardEvents.unregister()
  },

  destroyed() {
    this.$bus.$off('changeSelectedPlan', this.changeSelectedPlan)
  },

  methods: {
    ...mapActions('userSubscriptions', ['fetchRecurlySubscriptions']),
    ...mapMutations(['setSidebarContent', 'setInFullscreen']),
    ...mapMutations('tours', ['toggleTour']),
    ...mapActions(['countDownRefresh']),
    ...mapActions('accounts', ['fetchAlerts', 'updateAlerts']),

    changeSelectedPlan(plan) {
      let source = `${PROMO_URL}/show/11` /*eslint-disable-line no-undef */
      if (
        this.subscriptionPlanType.name.trim().search('limited') &&
        plan === 'Plus'
      ) {
        source = `${PROMO_URL}/show/10` /*eslint-disable-line no-undef */
      }
      this.planTypeUrl = source
    },

    showFirstLoginTour() {
      this.toggleTour({
        tour: 'FirstLoginTour',
        show: !this.user.login_tour_state
      })
    }

    // beforeEnter() {
    //   this.$store.commit('setIsSideBarShown', false)
    // },

    // afterLeave() {
    //   this.$store.commit('setIsSideBarShown', true)
    // }
  }
}
</script>

<style src="~/assets/scss/sections/sidebar/advanced-search-drawer.scss" lang="scss"/>
<style src="~/assets/scss/sections/sidebar/sidebar.scss" lang="scss"/>
