<template>
  <el-popover 
    ref="popover" 
    trigger="click" 
    placement="bottom" 
    popper-class="tour-links-popover">
    <a 
      slot="reference" 
      class="icon" 
      rel="popover" 
      role="button" 
      @click.prevent>
      <i class="help-icon" />
    </a>
    <ul class="block-section">
      <li 
        v-for="tour in tours" 
        :key="tour.tourName" 
        @click="startTour(tour)">{{ tour.displayName }}</li>
    </ul>
  </el-popover>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  data() {
    return {
      tours: [
        {
          displayName: 'First Login Tour',
          tourName: 'FirstLoginTour',
          route: '/'
        },
        {
          displayName: '0 Results Tour',
          tourName: 'NoResultsTour',
          route: '/'
        }
      ]
    }
  },
  methods: {
    ...mapMutations('tours', ['toggleTour']),
    startTour(tour) {
      this.$refs.popover.doClose()
      this.toggleTour({
        tour: tour.tourName,
        show: true
      })
      this.$router.push(tour.route)
    }
  }
}
</script>
