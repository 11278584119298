export const state = () => ({
  // state to control tour-active class in site-wrapper
  tourActive: false,
  savedView: null,

  FirstLoginTour: {
    show: false,
    currentStep: -1
  },

  NoResultsTour: {
    show: false,
    currentStep: -1
  }
})

export const mutations = {
  toggleTour(state, { tour, show }) {
    state[tour].show = !!show
  },
  setTourStep(state, { tour, step }) {
    state[tour].currentStep = step
  },
  setTourActive(state, tourActive) {
    state.tourActive = tourActive
  },
  saveView(state, view) {
    state.savedView = view
  }
}
