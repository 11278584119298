export default ({ store }) => {
  const { CONSOLE_APP_URL } = REDIRECT_URLS //eslint-disable-line no-undef
  const { contract } = store.state.accounts.user

  if (contract == 0 && CONSOLE_APP_URL) {
    window.location.assign(
      `${CONSOLE_APP_URL}/account/terms?code=ncon&returl=${
        window.location.href
      }`
    )
  }
}
