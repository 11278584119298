import closeUpgradeModal from './closeUpgradeModal'
import openUpgradeModal from './openUpgradeModal'

export { closeUpgradeModal, openUpgradeModal }

export default {
  methods: {
    ...closeUpgradeModal.methods,
    ...openUpgradeModal.methods
  }
}
