export default function installSwaychat() {
  if (
    window['_SwayChat'] ||
    !process.env.DEFAULT_ORG ||
    !process.env.DEFAULT_WIDGET ||
    !process.env.WIDGET_HOST_URL
  ) {
    return
  }

  ;(function(s, w, a, y) {
    var t = '_SwayChat'
    w[t] =
      w[t] ||
      function() {
        ;(w[t].q = w[t].q || []).push(arguments)
      }
    var c = a.createElement(s),
      h = a.getElementsByTagName(s)[0]
    c.async = 1
    c.src = y
    h.parentNode.insertBefore(c, h)
  })(
    'script',
    window,
    document,
    `//${process.env.WIDGET_HOST_URL}/assets/widget.js`
  )
  window._SwayChat('startUpMode', 'link')
  window._SwayChat(
    'create',
    process.env.DEFAULT_ORG,
    process.env.DEFAULT_WIDGET
  )
}
