import posthog from 'posthog-js'

export default function({ env }, inject) {
  // Init PostHog
  posthog.init(env.POSTHOG_API_KEY, {
    api_host: env.POSTHOG_API_HOST,
    autocapture: false,
    capture_pageview: false,
    persistence: 'localStorage+cookie'
  })

  // Inject PostHog into the application and make it available via this.$posthog (or app.$posthog)
  inject('posthog', posthog)
}
