import _ from 'lodash'
import uuid from 'uuid/v4'

export const state = () => ({
  page: 1,
  pageLimit: 5,
  remainingPages: 0,
  totalNotes: 0,

  syncing: false,

  notes: []
})

export const mutations = {
  resetState(currentState) {
    Object.assign(currentState, state())
  },
  setTotalNotes(state, totalNotes) {
    state.totalNotes = totalNotes
  },
  setRemainingPages(state, remainingPages) {
    state.remainingPages = remainingPages
  },
  setPage(state, page) {
    state.page = page
  },
  prependNotes(state, notes) {
    state.notes = _.unionBy(notes, state.notes, note => note.noteId)
  },
  appendNotes(state, notes) {
    state.notes = _.unionBy(state.notes, notes, note => note.noteId)
  },
  setNotes(state, notes) {
    state.notes = notes
  },
  editNote(state, note) {
    const index = _.findIndex(state.notes, n => n.noteId === note.noteId)
    state.notes = [
      ...state.notes.slice(0, index),
      {
        ...note,
        noteId: note.newId || note.noteId
      },
      ...state.notes.slice(index + 1)
    ]
  },
  deleteNote(state, noteId) {
    state.notes = _.filter(state.notes, n => n.noteId !== noteId)
  },
  setSyncing(state, syncing) {
    state.syncing = syncing
  }
}

export const actions = {
  async initialize({ dispatch }) {
    await dispatch('fetchNotes')
  },
  async fetchNotes({ rootState, state, commit }) {
    const { data } = await this.$axios.$get(
      `${COMPANY_API_URL}/companies/${rootState.company.companyId}/notes`,
      {
        params: {
          page: state.page,
          limit: state.pageLimit
        },
        progess: false
      }
    )

    commit('setTotalNotes', data.total)
    commit(
      'setNotes',
      _.map(data.notes, n => ({
        ...n,
        posted: true
      }))
    )

    commit('setRemainingPages', data.total - state.notes.length)
  },
  async getMoreNotes({ rootState, state, commit }) {
    const { data } = await this.$axios.$get(
      `${COMPANY_API_URL}/companies/${rootState.company.companyId}/notes`,
      {
        params: {
          page: state.page + 1,
          limit: state.pageLimit
        }
      }
    )

    commit('setPage', state.page + 1)
    commit(
      'appendNotes',
      _.map(data.notes, n => ({
        ...n,
        posted: true
      }))
    )

    commit('setRemainingPages', data.total - state.notes.length)
  },
  async updatePage({ rootState, state, commit }, pageNumber) {
    commit('setSyncing', true)

    const { data } = await this.$axios.$get(
      `${COMPANY_API_URL}/companies/${rootState.company.companyId}/notes`,
      {
        params: {
          page: pageNumber,
          limit: state.pageLimit
        }
      }
    )

    commit(
      'appendNotes',
      _.map(data.notes, n => ({
        ...n,
        posted: true
      }))
    )

    commit('setRemainingPages', data.total - state.notes.length)
    commit('setSyncing', false)
  },
  async sendNote({ commit, state, dispatch }, note) {
    const temporaryId = uuid()

    commit('prependNotes', [
      {
        noteId: temporaryId,
        pending: true,
        updatedAt: this.$date.getUnixTime(new Date()),
        ...note
      }
    ])

    await dispatch('sendNoteById', { noteId: temporaryId, note })
    await dispatch('updatePage', state.page + 1)
  },
  async resendNote({ commit, dispatch }, { noteId, ...note }) {
    commit('editNote', { noteId, ...note, pending: true })

    await dispatch('sendNoteById', { noteId, note })
  },
  async deleteNote({ state, rootState, commit, dispatch }, note) {
    commit('editNote', {
      ...note,
      task: 'delete',
      pending: true
    })

    await this.$axios.$delete(
      `${COMPANY_API_URL}/companies/${rootState.company.companyId}/notes/${
        note.noteId
      }`
    )

    commit('deleteNote', note.noteId)
    commit('setTotalNotes', state.totalNotes - 1)

    await dispatch('updatePage', state.page)
  },
  async sendNoteById({ state, commit, rootState }, { noteId, note }) {
    try {
      const { data } = await this.$axios.$post(
        `${COMPANY_API_URL}/companies/${rootState.company.companyId}/notes`,
        note
      )

      commit('editNote', {
        ...data,
        noteId,
        newId: data.noteId,
        pending: false
      })

      commit('setTotalNotes', state.totalNotes + 1)
    } catch (e) {
      commit('editNote', {
        noteId,
        ...note,
        pending: false,
        success: false
      })
    }
  },
  async editNote({ commit, rootState }, note) {
    commit('editNote', {
      ...note,
      task: 'edit',
      pending: true
    })

    try {
      const { data } = await this.$axios.$patch(
        `${COMPANY_API_URL}/companies/${rootState.company.companyId}/notes/${
          note.noteId
        }`,
        note
      )

      commit('editNote', {
        noteId: note.noteId,
        ...data,
        pending: false
      })
    } catch (e) {
      commit('editNote', {
        ...note,
        pending: false,
        success: false
      })
    }
  }
}
