import { validateSorts, validatePageLimit } from './helper-functions'

export default function validateCompanyShipments({ state }, storage) {
  const shipments = {}

  // sortOrder && sortField
  const { sortOrder, sortField } = validateSorts(state, storage)
  if (sortOrder) {
    shipments.sortOrder = sortOrder
  }
  if (sortField) {
    shipments.sortField = sortField
  }

  if (validatePageLimit(state, storage.pageLimit)) {
    shipments.pageLimit = storage.pageLimit
  }

  return shipments
}
