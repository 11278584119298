import installCustomerio from './installCustomerio'
import initializeVuexSharedMutations from './initializeVuexSharedMutations'

export default function onNuxtReady(ctx) {
  const { store } = ctx

  // this is called right after the mounted hook
  window.onNuxtReady(() => {
    store.commit('setIsMounted', true)

    installCustomerio(store)
    initializeVuexSharedMutations(store)
  })
}
