<template>
  <div
    v-if="item"
    class="download-results-preview">
    <el-dialog
      :visible.sync="isOpen"
      @closed="clearModal">
      <div class="preview-toolbar">
        <div class="filename-section">
          <i :class="`${item.file_format}-icon ${item.file_format}-icon-white`"/>
          <span>{{ item.file_name }}.{{ item.file_format }}</span>
        </div>
        <i
          class="download-icon-white"
          @click="generateDownloadLink({ id: item.export_id })"/>
      </div>
      <div
        v-if="item.preview.length"
        class="preview-container">
        <table class="table table-bordered preview-table">
          <thead>
            <tr>
              <th>#</th>
              <th>A</th>
              <th>B</th>
              <th>C</th>
              <th>D</th>
              <th>E</th>
              <th>F</th>
              <th>G</th>
              <th>H</th>
              <th>I</th>
              <th>J</th>
              <th>K</th>
              <th>L</th>
            </tr>
          </thead>
          <tbody v-if="item.preview.length">
            <tr>
              <td 
                v-for="header in extendedHeaders" 
                :key="header.key">{{ header.text }}</td>
            </tr>
            <tr 
              v-for="(row, id) in extendedRows" 
              :key="id">
              <td 
                v-for="(header, index) in extendedHeaders" 
                :key="header.key">{{ formatCell({ id, index, row, header: header.text }) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import uuid from 'uuid/v4'
import downloadExport from '~/mixins/downloadExport'

export default {
  name: 'PreviewModal',
  mixins: [downloadExport],
  data() {
    return {
      isOpen: false,
      item: null
    }
  },
  computed: {
    extendedHeaders() {
      const extraHeaders =
        this.headers.length < 12
          ? Array.from(Array(12 - this.headers.length).keys()).map(() => '')
          : []
      return ['1', ...this.headers, ...extraHeaders].map(item => ({
        key: uuid(),
        text: item
      }))
    },
    extendedRows() {
      const extraRows = Array.from(Array(30).keys()).map(() => '')
      return [...this.item.preview, ...extraRows]
    },
    headers() {
      return Object.keys(this.item.preview[0])
    }
  },
  mounted() {
    this.$bus.$on('openPreview', this.populateModal)
  },
  destroyed() {
    this.$bus.$off('openPreview', this.populateModal)
  },
  methods: {
    populateModal(item) {
      this.item = item
      this.isOpen = true
    },
    clearModal() {
      this.item = null
    },
    formatHeader(header) {
      return this.$store.state.views.fields[header]
        ? this.$store.state.views.fields[header].displayName
        : header
    },
    formatCell({ id, index, row, header }) {
      if (index === 0) {
        return id + 2
      }
      if (id > 29 || !header) {
        return ''
      }

      const text = row[header]

      if (!text) {
        return ''
      }

      return text
    }
  }
}
</script>
<style lang="scss" src="~/assets/scss/sections/download/download-results-preview.scss"/>
