/**
 * Try / Catches promises individually.
 * Use Promise.all(tryPromises([...promises])
 * Each promise returns { response, error }
 * @param promisesArray
 * @returns {Promise<Array>}
 */
export default async function tryPromises(promisesArray) {
  if (!Array.isArray(promisesArray)) {
    promisesArray = [promisesArray]
  }

  return Promise.all(
    promisesArray.map(async promise => {
      const response = {}
      try {
        response.response = await promise
      } catch (error) {
        response.error = error
      }
      return response
    })
  )
}
