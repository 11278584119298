export default {
  consignee: [
    'NOT AVAILABLE',
    'N/A',
    'TO ORDER',
    'TO THE ORDER',
    'UNTO THE ORDER',
    'UN TO THE ORDER',
    'UNTO ORDER',
    'UN TO ORDER'
  ],
  country: ['UNKNOWN'],
  shipper: ['N/A', 'NOT AVAILABLE'],
  state: ['PUERTO RICO'],
  foreignPort: [],
  usPort: []
}
