export const SubscriptionFeatures = {
  DOWNLOAD_RESULTS: 'download_results',
  COMPANY_PROFILE_VIEWS: 'company_profile_views',
  ALLOW_MULTIPLE_USERS: 'allowed_multiple_users',
  EMAIL_ALERTS: 'email_alerts',
  CONTACT_REQUESTS: 'contact_requests',
  SEARCHES: 'searches',
  DATE_RANGE: 'date_interval',
  ANALYTICS: 'analytics',
  VISUAL_MAPPING: 'visual_mapping'
}

export const PlanNames = {
  'Limited@': 'Limited',
  'Plus&': 'Plus',
  'Standard $199': 'Standard',
  'Premium&': 'Premium'
}

export const DateRangeTimePeriods = {
  YEAR: 'year',
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day'
}
