<template>
  <el-popover
    ref="popover"
    trigger="click"
    placement="bottom"
    popper-class="user-credits-popover">
    <a slot="reference">
      <i class="icon status-loader-icon" />
    </a>
    <div class="user-credit">
      <h3>Remaining Credits</h3>
      <div class="credit-section">
        <credit-widget
          v-for="(widget, index) in widgets"
          :key="index"
          :values="{
            percentage: widget.percentage,
            credits: widget.credits
          }"
          :label="widget.label"
          :credits-label="widget.creditsLabel"/>
      </div>
    </div>
  </el-popover>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CreditWidget from '~/components/common/CreditWidget'

export default {
  name: 'UserCredits',
  components: {
    CreditWidget
  },
  computed: {
    ...mapGetters('userCredits', [
      'companyViewsPercentage',
      'searchesPercentage',
      'downloadPercentage',
      'emailAlertsPercentage',
      'contactRequestsPercentage',
      'searchCredits',
      'downloadCredits',
      'emailAlertsCredits',
      'contactRequestsCredits'
    ]),
    widgets() {
      return [
        {
          label: 'Searches (Daily)',
          creditsLabel: 'searches',
          percentage: this.searchesPercentage,
          credits: this.searchCredits
        },
        {
          label: 'Downloads',
          creditsLabel: 'records',
          percentage: this.downloadPercentage,
          credits: this.downloadCredits
        },
        {
          label: 'Contact Requests',
          creditsLabel: 'requests',
          percentage: this.contactRequestsPercentage,
          credits: this.contactRequestsCredits
        },
        {
          label: 'Email Alerts',
          creditsLabel: 'alerts',
          percentage: this.emailAlertsPercentage,
          credits: this.emailAlertsCredits
        }
      ]
    }
  },
  methods: {
    ...mapActions('userCredits', ['getUserCredits'])
  }
}
</script>

<style lang="scss" src="~/assets/scss/sections/navbar/user-credits.scss"/>
