import { extend } from 'vee-validate'
import {
  required,
  alpha_spaces,
  ext,
  regex,
  numeric,
  email,
  max
} from 'vee-validate/dist/rules'

// Add the required rule
extend('required', {
  ...required,
  message: field => `${field} is required`
})

// Add the alpha_spaces rule
extend('alpha_spaces', {
  ...alpha_spaces,
  message: field => `${field} may only contain alphabetic characters`
})

// Add the card date format regex rule
extend('cardDateFormat', {
  ...regex,
  message: 'Date must be in mm/yy format'
})

// Add the numeric rule
extend('numeric', {
  ...numeric,
  message: 'Invalid input'
})

// Add the email rule
extend('email', email)

// Add the extension rule
extend('ext', {
  ...ext,
  message: `Selected file is not a valid image`
})

// Add the max rule
extend('max', {
  ...max,
  message: (field, { length }) =>
    `${field} must not exceed ${length} characters`
})
