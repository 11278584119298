import localforage from 'localforage'
import * as shvl from 'shvl'
import { debounce } from 'lodash'

class Frigidaire {
  constructor({ paths, key, store }) {
    if (!store) throw new Error('store is required')
    this._paths = paths
    this._key = key || 'frigidaire'
    this._store = store
    this._unwatchers = {}
  }

  get key() {
    return this._key
  }

  set key(key) {
    this._key = key
  }

  get paths() {
    return this._paths
  }

  set paths(paths) {
    this._paths = paths
  }

  /**
   * Accepts a beforeMerge function that is called before the localforage saved
   * state is merged. This is a good place for validating saved data.
   * User can return a state to save, or the current one will be used.
   * @param {function} options.beforeMerge - callback(savedState, storeStat) before merging.
   * @param {string} options.key - key to save state to.
   * @param {boolean=true} options.replaceState - replace state
   * @returns {Promise<state>} - returns saved state.
   */
  async load(options = {}) {
    const { beforeMerge } = options
    const key = options.key || this._key

    let savedState = await localforage.getItem(key)
    if (typeof savedState !== 'object' || savedState === null) {
      return
    }

    if (beforeMerge && typeof beforeMerge === 'function') {
      const returnedState = await beforeMerge(savedState, this._store.state)
      savedState = returnedState || savedState
    }

    return savedState
  }

  /**
   * Accepts a beforeSave function that is called before the paths derived
   * state is saved. User can return a state to save, or the current one
   * will be used.
   * @param {function} options.beforeSave - callback(filteredState, storeState) before saving.
   * @param {string} options.key - key to save state to.
   * @returns {Promise<state>} - returns saved state.
   */
  async save(options) {
    const { beforeSave } = options
    const key = options.key || this._key
    let filteredState =
      this._paths.length === 0
        ? this._store.state
        : this._paths.reduce(
            (substate, path) =>
              shvl.set(substate, path, shvl.get(this._store.state, path)),
            {}
          )
    if (beforeSave && typeof beforeSave === 'function') {
      const returnedState = await beforeSave(filteredState, this._store.state)
      filteredState = returnedState || filteredState
    }

    await localforage.setItem(key, filteredState)
    return filteredState
  }

  /**
   * Start watching store.mutations and/or window.beforeunload.
   * Throws errors if already watching, or if all watchers were disabled in options.
   * @param {string} options.key - key to save state to.
   * @param {boolean=true} options.store - set false to disable watching store mutations.
   * @param {boolean=true} options.beforeunload - set false to disable listening to beforeunload event.
   * @param {function} options.beforeSave - callback(filteredState, storeState) before saving.
   * @returns {function(): void} - returns unwatch function.
   */
  watch(options = {}) {
    const key = options.key || this._key
    if (this._unwatchers[key]) {
      throw new Error('Already watching')
    }
    const unwatchers = []

    if (options.store !== false) {
      let promiseChain = new Promise(resolve => resolve())

      const promisedSavedState = () => {
        promiseChain = promiseChain.then(() => {
          return this.save(options)
        })
      }

      const DELAY = 1000 * 3 // 3 seconds
      const MAX_WAIT = DELAY * 10 // 30 seconds
      const debouncedSaveState = debounce(promisedSavedState, DELAY, {
        maxWait: MAX_WAIT
      })

      const unwatchStore = this._store.subscribe(debouncedSaveState)
      unwatchers.push(unwatchStore)
      unwatchers.push(debouncedSaveState.flush)
    }

    if (options.beforeunload !== false) {
      const handleWindowUnload = () => {
        this.save(options)
      }

      // before window resets
      window.addEventListener('beforeunload', handleWindowUnload, {
        passive: true
      })
      const unwatchWindowUnload = () => {
        window.removeEventListener('beforeunload', handleWindowUnload)
      }

      unwatchers.push(unwatchWindowUnload)
    }

    if (unwatchers.length === 0) {
      return // not watching anything
    }

    this._unwatchers[key] = unwatchers
    return () => this.unwatch(key)
  }

  /**
   * Unwatch store-mutations and/or window.beforeunload.
   * Throws error if not watching anything at the moment.
   */
  unwatch(key = this._key) {
    const unwatchers = this._unwatchers[key]
    if (!unwatchers) {
      throw new Error('Not watching anything')
    }

    unwatchers.forEach(unwatch => unwatch())
    this._unwatchers[key] = null
  }
}

export default Frigidaire
