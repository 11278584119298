<template>
  <div class="error-container">
    <h1 v-if="error && error.statusCode === 404">404: {{ error.message || 'Page not found' }}</h1>
    <h1 v-else>Whoops, it looks like we encountered a hiccup</h1>
    <a :href="redirect.link">{{ redirect.text }}</a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Error',
  layout: 'none',
  props: {
    error: {
      type: [Error, Object],
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['currentSearchURL']),
    redirect() {
      if (this.$auth.loggedIn) {
        return {
          link: this.currentSearchURL,
          text: 'Go Home'
        }
      }

      return {
        link: '/login',
        text: 'Login'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>
