<template>
  <multiselect
    ref="multiselect"
    :value="value"
    :options="allowedOptions"
    :searchable="false"
    :show-labels="false"
    :allow-empty="false"
    class="custom-select"
    @input="onInput"
    @open="$emit('open', true)"
    @close="$emit('close', false)">
    <template
      slot="singleLabel"
      slot-scope="{ option }">
      <span>{{ options[option] }}</span>
    </template>
    <template
      slot="option"
      slot-scope="{ option }">
      <span>{{ options[option] }}</span>
    </template>
  </multiselect>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    excludedOptions: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      options: {
        contains: 'Contains',
        exact_phrase: 'Exact Phrase',
        starts_with: 'Starts with',
        ends_with: 'Ends with'
      }
    }
  },
  computed: {
    allowedOptions() {
      const options = []

      Object.keys(this.options).forEach(item => {
        if (!this.excludedOptions.includes(item)) options.push(item)
      })

      return options
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>
