import DecoratedAuth from '~/plugins/decoratedAuth'

export default async function({ app }) {
  const isngCookie = app.$cookies.get('isng_access_login_id')

  if (isngCookie) {
    const decoratedAuth = new DecoratedAuth(app.$auth, app.$axios)
    await decoratedAuth.autologin()
    app.$auth.$storage.setState('isngCookie', isngCookie)
    await app.store.dispatch('nuxtServerInit')

    const previousGuardedPath = app.$auth.$storage.getUniversal('redirect')
    app.$auth.$storage.setUniversal('redirect', null)

    app.router.push(previousGuardedPath || '/')
    return
  } else {
    await app.store.dispatch('logout')
    app.$auth.$storage.setState('loggedIn', false)

    app.$auth.reset()
  }
}
