import Vue from 'vue'
import { SearchTypes } from '~/utils/constants/search'

export default () => {
  Vue.filter('formatKeyword', function(value, type) {
    if (type === SearchTypes.RANGE && value) {
      return value.replace('-', ' to ')
    }

    return value
  })
}
