const axios = require('axios')
const { CLIENT_API_URL } = require('../config')

/**
 * Get client information
 *
 * @param {String} token - Authorization token
 *
 * @returns {Promise<Object>} Client information
 */
exports.getClientInformation = token =>
  axios
    .get(`${CLIENT_API_URL}/client/account-info`, {
      headers: { Authorization: token }
    })
    .catch(e => Promise.reject(e.response))

/**
 * Attempt update referrer
 * - update offline user's lead information based on referrer
 * @param {Number} clientId
 * @param {String} referrer - where the user came from
 * @param {String} firstpage - first  page user has landed on when visiting IG for thhe first time
 * @param {String} token - auth0 bearer token
 */
exports.attemptUpdateReferrer = async (
  clientId,
  referrer,
  firstpage,
  token
) => {
  const payload = { referrer, firstpage }

  Object.keys(payload).forEach(key => {
    if (payload[key] == null) {
      delete payload[key]
    }
  })

  return await axios.put(`${CLIENT_API_URL}/client-lead/${clientId}`, payload, {
    headers: { Authorization: token }
  })
}

exports.updateShouldTrackOffline = async ($axios, clientId) => {
  return await $axios.put(
    `${CLIENT_API_URL}/client/${clientId}/account-info/should-track-offline`,
    { value: true }
  )
}
