<template>
  <multiselect
    ref="multiselect_dropdown"
    v-model="keywords"
    :options="options"
    :multiple="true"
    :close-on-select="false"
    :clear-on-select="clearInputOnSelect"
    :hide-selected="true"
    :show-labels="false"
    :show-no-results="!taggable"
    :preserve-search="preserveSearch"
    :taggable="taggable"
    :class="{ hideOptions, hideInputField }"
    :placeholder="placeholderText"
    :show-pointer="showPointer"
    :block-keys="blockKeys"
    :max-height="200"
    tag-placeholder=""
    class="custom-select multi-select-custom"
    label="name"
    track-by="code"
    @close="close"
    @tag="select"
    @open="open"
    @input="input">
    <template
      slot="option"
      slot-scope="{ option }">
      <span :class="{ bold: !option.isInput }">{{ option.name }}</span>
    </template>
    <span
      slot="noResult"
      class="field-not-found">Keyword not found.</span>
  </multiselect>
</template>

<script>
export default {
  name: 'MultiselectDropdown',
  props: {
    options: {
      type: Array,
      required: true
    },

    taggable: {
      type: Boolean,
      default: true
    },

    clearInputOnSelect: {
      type: Boolean,
      default: false
    },

    hideOptionsWhenEmpty: {
      type: Boolean,
      default: false
    },

    userInput: {
      type: String,
      default: ''
    },

    preserveSearch: {
      type: Boolean,
      default: true
    },

    placeholderText: {
      type: String,
      default: 'Enter a keyword'
    },

    showPointer: {
      type: Boolean,
      default: true
    },

    blockKeys: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      keywords: [],
      defaultPlaceholder: 'Enter a keyword',
      typed: false,
      hideInputField: false,
      optionIsEmpty: false
    }
  },

  computed: {
    hideOptions() {
      return this.hideOptionsWhenEmpty && !this.options.length
    }
  },

  mounted() {
    this.$refs.multiselect_dropdown.$refs.search.oninput = event => {
      if (event.srcElement.value) this.typed = !this.keywords.length

      this.$emit('keyword-change', event.srcElement.value)
    }

    this.$refs.multiselect_dropdown.$refs.search.onclick = event => {
      if (!this.typed && this.keywords.length) event.srcElement.select()
    }

    this.$refs.multiselect_dropdown.$refs.search.onkeydown = event =>
      this.readKeyPress(event)
  },

  methods: {
    readKeyPress(e) {
      if ((e.key === 'ArrowDown' || e.key === 'ArrowUp') && !this.showPointer) {
        e.preventDefault()

        this.$emit('show-pointer', true)
        this.$emit('block-keys', [])
        this.$refs.multiselect_dropdown.pointer = 0
        return
      } else if (e.key === 'Enter') {
        e.preventDefault()

        if (!this.showPointer) {
          this.$emit('input-enter')
          this.$emit('show-pointer', false)
          return
        } else if (this.$children[0].filteredOptions.length === 0) {
          if (this.optionIsEmpty) {
            this.$emit('input-enter')
            this.$emit('show-pointer', false)
            return
          }
          this.optionIsEmpty = true
          return
        }
      }
    },

    setValue(value) {
      this.keywords = value
      this.hideInputField = value.length
    },

    getValues() {
      return this.keywords
        .map(condition => condition.name)
        .filter(name => !!name)
    },

    input() {
      if (this.$children[0].filteredOptions.length > 0) {
        this.optionIsEmpty = false
      }

      this.typed = false
      this.$emit('input', this.keywords)
      this.hideInputField = !this.userInput && this.keywords.length
    },

    setInputValue(value) {
      this.$refs.multiselect_dropdown.$refs.search.value = value
    },

    select(keyword) {
      const tag = {
        name: keyword,
        code: keyword.substring(0, 2) + Math.floor(Math.random() * 10000000)
      }

      this.options.push(tag)
      this.keywords.push(tag)
      this.typed = false
      this.$emit('input', this.keywords)
    },

    open() {
      this.$emit('open')
      this.hideInputField = false
    },

    close() {
      this.$emit('close')
      this.$emit('show-pointer', false)
      this.$emit('block-keys', ['Enter'])
      this.hideInputField = !this.userInput && this.keywords.length
    }
  }
}
</script>
