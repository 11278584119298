<template>
  <fieldset>
    <div
      :class="{
        'first-condition': isFirstCondition,
        'condition': depth !== 0,
        'with-subgroup': depth !== 0
    }">
      <div
        v-if="!isFirstCondition && depth !== 0"
        class="logic-tag">{{ condition }}</div>
      <div
        :class="{
          'search-subgroup': depth !== 0,
          'search-group': depth === 0
      }">
        <fieldset>
          <div class="group-condition-switcher">
            <conditions-dropdown
              ref="conditionsDropdown"
              v-model="selectedCondition"
              @input="$bus.$emit('conditionChanged')"/><span class="option-note">of the following conditions match</span>
            <i
              v-if="depth !== 0"
              class="removed-icon"
              @click="$emit('remove');$bus.$emit('conditionChanged');"/>
          </div>
        </fieldset>
        <template v-for="(condition, $index) in conditions">
          <template v-if="condition.type === 'condition'">
            <condition
              :condition="selectedCondition"
              :is-first-condition="$index === 0"
              :key="condition.id"
              :is-active="isActive"
              :ref="`condition${$index}`"
              @remove="remove($index)" />
          </template>
          <template v-else>
            <condition-group
              :condition="selectedCondition"
              :is-first-condition="$index === 0"
              :depth="depth + 1"
              :key="condition.id"
              :ref="`condition${$index}`"
              @remove="remove($index)"/>
          </template>
        </template>
      </div>
      <div class="condition-buttons">
        <button
          class="btn btn-text"
          @click="addCondition(true)">Add Condition</button>
        <button
          v-if="depth < 2"
          class="btn btn-text secondary"
          @click="addGroup(true)">Add Group</button>
      </div>
    </div>
  </fieldset>
</template>

<script>
import ConditionsDropdown from './ConditionsDropdown'
import Condition from './Condition'
import { find } from 'lodash'
import uuid from 'uuid/v4'

export default {
  name: 'ConditionGroup',
  components: {
    ConditionsDropdown,
    Condition
  },
  props: {
    condition: {
      type: String,
      default: ''
    },
    isFirstCondition: {
      type: Boolean,
      default: false
    },
    depth: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selectedCondition: 'AND',
      conditions: [
        {
          type: 'condition',
          id: uuid(),
          active: true
        }
      ],
      isActive: false
    }
  },
  mounted() {
    this.isActive = true
  },
  methods: {
    addCondition(focus = false) {
      this.conditions.push({
        type: 'condition',
        id: uuid()
      })

      const parentRef =
        this.$parent.$parent.$options.name === 'ConditionGroup'
          ? this.$parent.$parent
          : this.$parent

      if (parentRef.$options.name === 'ConditionGroup') {
        const refsLength = Object.keys(parentRef.$refs).length

        for (let i = 0; i < refsLength - 1; i++) {
          const conditions = parentRef.$refs[`condition${i}`]
          this.removePreviousHelpText(conditions, 'group')
        }
      } else {
        this.conditions.map((c, index) => {
          const conditions = this.$refs[`condition${index}`]
          this.removePreviousHelpText(conditions, 'condition')
        })
      }

      if (focus) this.$nextTick(this.focusLastAdded)
    },
    addGroup(focus = false) {
      this.conditions.push({
        type: 'subgroup',
        id: uuid()
      })

      const parentRef =
        this.$parent.$parent.$options.name === 'ConditionGroup'
          ? this.$parent.$parent
          : this.$parent

      if (parentRef.$options.name === 'ConditionGroup') {
        const refsLength = Object.keys(parentRef.$refs).length

        for (let i = 0; i < refsLength - 1; i++) {
          const conditions = parentRef.$refs[`condition${i}`]
          this.removePreviousHelpText(conditions, 'group')
        }
      } else {
        this.conditions.map((c, index) => {
          const conditions = this.$refs[`condition${index}`]
          this.removePreviousHelpText(conditions, 'condition')
        })
      }

      if (focus) this.$nextTick(this.focusLastAdded)
    },
    focusLastAdded() {
      this.$refs[`condition${this.conditions.length - 1}`][0].focus()
    },
    removePreviousHelpText(conditions, type) {
      if (conditions && conditions[0]) {
        const length = conditions[0].$children.length
        const { name } = conditions[0].$children[length - 1].$options

        if (
          conditions[0].$options.name === 'Condition' &&
          conditions[0].activeCondition
        ) {
          conditions[0].activeCondition = false
          delete conditions[0].currentActiveCondition
        } else if (conditions[0].$options.name === 'ConditionGroup') {
          if (type === 'group') {
            if (
              name === 'Condition' &&
              conditions[0].$children[length - 1].activeCondition
            ) {
              conditions[0].$children[length - 1].activeCondition = false
              delete conditions[0].$children[length - 1].currentActiveCondition
            } else {
              conditions[0].$children.map(child => {
                child.$children.map(subChild => {
                  if (subChild.activeCondition) {
                    subChild.activeCondition = false
                    delete subChild.currentActiveCondition
                  }
                })
              })
            }
          } else {
            if (
              name === 'Condition' &&
              conditions[0].$children[length - 1].activeCondition
            ) {
              conditions[0].$children[length - 1].activeCondition = false
              delete conditions[0].$children[length - 1].currentActiveCondition
            } else {
              const childrenLength =
                conditions[0].$children[length - 1].$children.length

              conditions[0].$children[length - 1].$children[
                childrenLength - 1
              ].activeCondition = false

              delete conditions[0].$children[length - 1].$children[
                childrenLength - 1
              ].currentActiveCondition
            }
          }
        }
      }
    },
    clear() {
      this.conditions = [
        {
          type: 'condition',
          id: uuid()
        }
      ]
    },
    focus() {
      this.$refs.conditionsDropdown.$refs.multiselect.$el.focus()
    },
    remove(index) {
      this.conditions.splice(index, 1)

      if (this.conditions.length < 1) {
        this.$emit('remove')
        if (this.depth === 0) {
          this.$emit('clear')
          return
        }
      } else {
        const parentRef =
          this.$parent.$parent.$options.name === 'ConditionGroup'
            ? this.$parent.$parent
            : this.$parent.$options.name === 'ConditionGroup'
              ? this.$parent
              : this

        if (parentRef.$options.name === 'ConditionGroup') {
          this.$nextTick(() => {
            let hasActive = false
            const { length } = parentRef.$children
            const { name } = parentRef.$children[length - 1].$options

            const currentActive = find(parentRef.$children, {
              activeCondition: true
            })

            if (!currentActive && name === 'Condition') {
              parentRef.$children[length - 1].activeCondition = true
            } else if (!currentActive && name === 'ConditionGroup') {
              const { name: subName } = parentRef.$children[length - 1].$options
              const { length: subLength } = parentRef.$children[
                length - 1
              ].$children

              if (subName === 'ConditionGroup') {
                const { name: groupName } = parentRef.$children[
                  length - 1
                ].$children[subLength - 1].$options

                if (groupName === 'Condition') {
                  for (let i = 0; i < length; i++) {
                    const condition = parentRef.$refs[`condition${i}`]

                    if (condition && condition[0]) {
                      const currentActive = find(condition[0].$children, {
                        activeCondition: true
                      })

                      if (currentActive) {
                        hasActive = true
                        break
                      }
                    }
                  }

                  if (!hasActive) {
                    const childLength = this.$children.length

                    if (
                      this.$children[childLength - 1].$options.name ===
                      'Condition'
                    ) {
                      this.$children[childLength - 1].activeCondition = true
                      this.$children[
                        childLength - 1
                      ].currentActiveCondition = true
                    } else {
                      parentRef.$children[length - 1].$children[
                        subLength - 1
                      ].activeCondition = true
                      parentRef.$children[length - 1].$children[
                        subLength - 1
                      ].currentActiveCondition = true
                    }
                  }
                } else {
                  const subGroupLength =
                    parentRef.$children[length - 1].$children[subLength - 1]
                      .$children.length
                  parentRef.$children[length - 1].$children[
                    subLength - 1
                  ].$children[subGroupLength - 1].activeCondition = true
                  parentRef.$children[length - 1].$children[
                    subLength - 1
                  ].$children[subGroupLength - 1].currentActiveCondition = true
                }
              }
            }
          })
        }
      }
      this.$bus.$emit('conditionChanged')
    },
    validate(options) {
      const res = this.conditions.map((c, index) =>
        this.$refs[`condition${index}`][0].validate(options)
      )

      return res.indexOf(false) === -1
    },
    getValue(isFinal = false) {
      return {
        o: this.selectedCondition,
        i: this.conditions.map((c, index) => {
          return this.$refs[`condition${index}`][0].getValue(isFinal)
        })
      }
    },
    setValue(keywords) {
      this.selectedCondition = keywords.o

      this.conditions = []

      keywords.i.forEach((condition, index) => {
        if (condition.i) {
          this.addGroup()
        } else {
          this.addCondition()
        }

        this.$nextTick(() => {
          this.$refs[`condition${index}`][0].setValue(condition)
        })
      })
    }
  }
}
</script>
