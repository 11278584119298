import { render, staticRenderFns } from "./UpgradeSubscriptionModal.vue?vue&type=template&id=264b39d0&"
import script from "./UpgradeSubscriptionModal.vue?vue&type=script&lang=js&"
export * from "./UpgradeSubscriptionModal.vue?vue&type=script&lang=js&"
import style0 from "~/assets/scss/components/upgrade-options/upgrade-options.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports