<template>
  <el-dialog
    :visible.sync="isBetaModalOpen"
    custom-class="beta-modal with-footer"
    @close="$emit('close')">
    <div class="content-container">
      <h2>Welcome to ImportGenius Lighthouse</h2>
      <p>ImportGenius Lighthouse gives users a new search interface, new company profiles, and <br>new analytics tools for finding insights from Import Data.</p>
      <img
        src="~assets/img/isng_search_demo.gif"
        alt="Import Genius"
        width="650"
        height="368"
        class="demo-gif embed-responsive-item">
      <div class="action-buttons">
        <button
          class="btn btn-default btn-orange"
          @click="openSearch"><i class="search-icon white"/><span>Search US Import Data</span></button>
      </div>
    </div>
    <div class="modal-footer flex-container justify-space-between">
      <p>Any feedback will be greatly appreciated. You can email <a 
        href="mailto:feedback@importgenius.com" 
        style="font-weight:bold">feedback@importgenius.com</a> or chat with our team from inside ImportGenius Lighthouse.</p>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'BetaModal',
  computed: {
    isBetaModalOpen: {
      get() {
        return this.$store.state.isBetaModalOpen
      },
      set(value) {
        this.$store.commit('toggleBetaModal', value)
      }
    },
    inSearchPage() {
      return this.$route.path.includes('/search')
    }
  },
  created() {
    document.addEventListener('keyup', this.closeDialogOnEsc)
  },
  destroy() {
    document.removeEventListener('keyup', this.closeDialogOnEsc)
  },
  methods: {
    closeModalAndGoToSearch() {
      this.$store.commit('toggleBetaModal', false)

      if (!this.inSearchPage) this.$router.push(this.$store.state.baseLink)
    },
    viewShipments() {
      this.closeModalAndGoToSearch()
      this.$store.commit('setSidebarContent', false)
    },
    openSearch() {
      this.closeModalAndGoToSearch()

      if (this.inSearchPage) {
        this.$bus.$emit('open-advanced-search-drawer')
        return
      }

      this.$bus.$emit('show', true)
      this.$store.commit('setSidebarContent', true)
    },
    closeDialogOnEsc(event) {
      if (event.key === 'Escape') this.$store.commit('toggleBetaModal', false)
    }
  }
}
</script>
<style
  src="@/assets/scss/components/ig-beta.scss"
  lang="scss"
/>
