function createInMemoryHistory() {
  const maxHistory = 4
  const emptyHistoryState = null
  const history = new Array(Math.max(3, maxHistory)).fill(emptyHistoryState)

  const key = Symbol()
  const inMemoryHistory = {
    insert: function({ to: route, key: passedKey }) {
      if (key !== passedKey) {
        const warning =
          'inMemoryHistory is private and can only be modified with a valid key'
        console.warn(warning)

        return
      }

      history.pop()
      history.unshift(route)
    },
    getHistory: function(step = 0) {
      if (step > history.length - 1) {
        const warning =
          `inMemoryHistory has a maximum of ${history.length} entries ` +
          `starting from 0 (current route) and will return an empty state on ` +
          `out of bounds step`

        console.warn(warning)

        return emptyHistoryState
      }

      return history[step]
    },
    get routedBack() {
      const currentRoute = this.getHistory(0)
      const backRoute = this.getHistory(2)

      return !!(backRoute && currentRoute.fullPath === backRoute.fullPath)
    },
    get length() {
      return history.length
    }
  }

  return { key, inMemoryHistory }
}

export default async function(ctx, inject) {
  const { app } = ctx
  const { inMemoryHistory, key } = createInMemoryHistory()

  app.router.beforeEach((to, _from, next) => {
    inMemoryHistory.insert({ to, key })
    next()
  })

  ctx.$inMemoryHistory = inMemoryHistory
  inject('inMemoryHistory', inMemoryHistory)
}
