import Vue from 'vue'
import { Dialog } from 'element-ui'

Vue.use(Dialog)

// @TODO: DOES NOT WORK ON EDGE - needs to be fixed
// const observer = new MutationObserver(function(mutations) {
//   const bodyClass = 'el-popup-parent--hidden'
//   const htmlClass = 'el-dialog-html--open'
//   const isModalOpen = mutations[0].target.classList.contains(bodyClass)

//   const method = isModalOpen ? 'add' : 'remove'

//   document.documentElement.classList[method](htmlClass)
// })

// observer.observe(document.body, {
//   attributeFilter: ['class']
// })
