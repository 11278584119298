export const state = () => ({
  mainRoot: {},
  selectedNode: {},
  nodes: {},
  currentPage: 1,
  traderTypes: [],
  traderType: 'consignee'
})

export const mutations = {
  resetState(currentState) {
    Object.assign(currentState, state())
  },
  setSelectedNode(state, selectedNode) {
    state.selectedNode = selectedNode
  },
  setMainRoot(state, rootNode) {
    const { id, name, data, children } = rootNode
    state.mainRoot = { id, name, data, children }
    state.selectedNode = { id, name, data, children }
  },
  setNodes(state, nodes) {
    state.nodes = nodes
  },
  setCurrentPage(state, payload) {
    state.currentPage = payload
  },
  setTraderType(state, traderType) {
    state.traderType = traderType
  },
  setTraderTypes(state, traderTypes) {
    state.traderTypes = traderTypes
  }
}

export const actions = {
  async initialize({ commit, dispatch, rootState }) {
    const { isSupplier, isImporter, companyId } = rootState.company
    const traderType =
      isSupplier && isImporter
        ? 'consignee'
        : isSupplier && !isImporter
          ? 'shipper'
          : 'consignee'
    const traderTypes =
      isSupplier && isImporter
        ? ['shipper', 'consignee']
        : isSupplier && !isImporter
          ? ['shipper']
          : ['consignee']

    commit('setTraderTypes', traderTypes)
    commit('setTraderType', traderType)
    dispatch('fetchVisualMappingData', companyId)
  },
  setSelectedNode({ commit, rootState }, selectedNode) {
    const { companyId, isSupplier, isImporter } = rootState.company
    const { id, name, data, children } = selectedNode
    if (companyId === id) {
      const traderTypes =
        isSupplier && isImporter
          ? ['shipper', 'consignee']
          : isSupplier && !isImporter
            ? ['shipper']
            : ['consignee']
      commit('setTraderTypes', traderTypes)
      commit('setTraderType', 'consignee')
      commit('setSelectedNode', { id, name, data, children })
      return
    }
    commit('setTraderTypes', [data.traderType])
    commit('setTraderType', data.traderType)
    commit('setSelectedNode', { id, name, data, children })
  },
  async setCurrentPage({ state, commit, dispatch, rootState }, page) {
    const { companyId } = rootState.company
    if (state.selectedNode.id === companyId) {
      commit('setCurrentPage', page)
      dispatch('fetchVisualMappingData', state.mainRoot.id)
      return
    }
  },
  setTraderType({ commit }, traderType) {
    commit('setTraderType', traderType)
  },
  async fetchVisualMappingData({ state, commit, rootState }, companyId) {
    const { country, tradeType } = rootState

    const { data } = await this.$axios.$get(
      `${COMPANY_API_URL}/companies/${companyId}/${state.traderType}/${
        state.currentPage
      }/visual-mapping`,
      {
        params: {
          country,
          shipmentType: tradeType
        }
      }
    )
    commit('setMainRoot', data)
    commit('setNodes', data)
  }
}
