import { subDays, subWeeks } from 'date-fns'
import $date from '~/plugins/date/methods'

export function subtractDateRangeFromTimestamp(timestamp, dateRangeString) {
  let modifiedTimestamp = timestamp

  return extractDateRangeGroups(dateRangeString).reduce(
    (modifiedTimestamp, dateRangeGroup) => {
      const [value, scale] = dateRangeGroup.split(' ')

      if (isInYears(scale)) {
        modifiedTimestamp = $date.subYears(modifiedTimestamp, value).getTime()
      } else if (isInMonths(scale)) {
        modifiedTimestamp = $date.subMonths(modifiedTimestamp, value).getTime()
      } else if (isInWeeks(scale)) {
        modifiedTimestamp = subWeeks(modifiedTimestamp, value).getTime()
      } else if (isInDays(scale)) {
        modifiedTimestamp = subDays(modifiedTimestamp, value).getTime()
      }

      return modifiedTimestamp
    },
    modifiedTimestamp
  )
}

export function extractDateRangeGroups(dateRangeValue) {
  return (
    dateRangeValue
      .toLowerCase()
      .match(/\d+\s\b(?:year(?:s)?|month(?:s)?|week(?:s)?|day(?:s)?)/g) || []
  )
}

export function isInYears(scale) {
  return /\b(?:year(?:s)?)/.test(scale.toLowerCase())
}

export function isInMonths(scale) {
  return /\b(?:month(?:s)?)/.test(scale.toLowerCase())
}

export function isInWeeks(scale) {
  return /\b(?:week(?:s)?)/.test(scale.toLowerCase())
}

export function isInDays(scale) {
  return /\b(?:day(?:s)?)/.test(scale.toLowerCase())
}

export default {
  subtractDateRangeFromTimestamp
}
