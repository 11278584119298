/* eslint-disable import/prefer-default-export,no-param-reassign */
export const numberWithCommas = (x, decimals = 2) => {
  if (x) {
    const parts = x
      .toFixed(decimals)
      .toString()
      .split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
  }

  return 0
}

/**
 * Format a number to only have at most two non-zero digits after the leading zeroes in the decimal place
 *
 * @param {Number} number - Number to be formatted
 * @returns String
 */
export const convertToTwoDigitDecimal = number => {
  if (!number) return 0

  const [whole, decimal] = number.toString().split('.')
  const roundedDecimals = decimal ? getDecimalsString(decimal) : ''
  const wholeNumber = parseInt(whole)

  const convertedNumber =
    parseFloat(roundedDecimals) >= 1
      ? `${wholeNumber + 1}`
      : `${wholeNumber}${roundedDecimals}`

  return convertedNumber
}

/**
 * Formats a string that represents the decimal digits of a number to have at most two
 * non-zero digits only after the leading zeroes
 *
 * @param {String} decimal - string representing the decimal digits of a number
 * @returns String
 */
const getDecimalsString = decimal => {
  const getAtLeastTwoDigits = (number, leadingZeroesLength) =>
    leadingZeroesLength || number > 10 ? number : number * 10

  const decimalDigitIndex = decimal.slice(0).search(/[1-9]/)
  let leadingZeroes = decimal.slice(0, decimalDigitIndex)
  let nonZeroDecimal = parseInt(decimal)

  if (nonZeroDecimal > 0) {
    nonZeroDecimal = Number(parseFloat(`0.${nonZeroDecimal}`).toFixed(2))

    if (nonZeroDecimal >= 1) {
      if (!leadingZeroes.length) return '1'

      leadingZeroes = leadingZeroes.substring(1)
      nonZeroDecimal = 0.1
    }

    nonZeroDecimal = getAtLeastTwoDigits(
      parseFloat(nonZeroDecimal.toString())
        .toString()
        .split('.')[1],
      leadingZeroes.length
    )
  }

  return `.${leadingZeroes}${nonZeroDecimal}`
}

export const numberWithLetters = (num, decimal = 2, split = false) => {
  let greatestPow1000 = 0
  let shortenedNum = Math.abs(num)
  const sign = num >= 0 ? 1 : -1
  decimal = 10 ** decimal

  while (shortenedNum >= 1000) {
    shortenedNum /= 1000
    greatestPow1000 += 1
  }

  const value = Math.round(sign * shortenedNum * decimal) / decimal
  const suffix = ['', 'k', 'M', 'B', 'T', 'Q', 'Qt'][greatestPow1000] || ''

  return split ? { value, suffix } : value + suffix
}

export const percentage = (total, value, decimal = 2) =>
  `${((value / total) * 100).toFixed(decimal)}%`

export const inRange = (number, start, end, isInclusive = false) => {
  let from = start
  let to

  if (end === undefined) {
    to = start
    from = 0
  } else {
    to = end
  }

  if (from > to) {
    const temp = from
    from = to
    to = temp
  }

  const withinLowerBound = isInclusive ? number >= from : number > from
  const withinUpperBound = isInclusive ? number <= to : number < to

  return withinUpperBound && withinLowerBound
}
