export function buildURLParams(URLParams = []) {
  if (!URLParams || !Array.isArray(URLParams)) return ''

  return URLParams.length ? `/${URLParams.join('/')}` : ''
}

export function buildQueryParams(queryParams = {}) {
  if (
    Object.prototype.toString.call(queryParams) !== '[object Object]' ||
    Object.keys(queryParams).length === 0
  ) {
    return ''
  }

  const mappedQueryParams = []

  for (const key in queryParams) {
    if (Array.isArray(queryParams[key])) {
      queryParams[key].forEach(queryParam => {
        mappedQueryParams.push({ key, value: queryParam })
      })

      break
    }

    mappedQueryParams.push({ key, value: queryParams[key] })
  }

  return `?${mappedQueryParams
    .map(({ key, value }) => `${key}=${value}`)
    .join('&')}`
}

export default {
  buildURLParams,
  buildQueryParams
}
