export default ({ route, store, redirect }) => {
  const view = route.query.view

  if (
    view === 'analytics' &&
    !store.state.userSubscriptions.userHasAnalyticsSubscription
  ) {
    redirect(
      REDIRECT_URLS.UPGRADE_SUBSCRIPTION_PLACEHOLDER /*eslint-disable-line no-undef */
    )
  }
}
