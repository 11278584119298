export const state = () => ({
  isIdentified: false,
  redirectTestControlGroup: null
})

export const getters = {
  /**
   * State for identification in posthog
   * @returns {Boolean}
   */
  isIdentified(state) {
    return state.isIdentified
  },
  /**
   * Returns current control group you belong in. aka Variant
   * @returns {Boolean}
   */
  redirectTestControlGroup(state) {
    return state.redirectTestControlGroup
  }
}

export const mutations = {
  setIsIdentified(state, status) {
    state.isIdentified = status
  },
  setRedirectTestControlGroup(state, group) {
    state.redirectTestControlGroup = group
  }
}
