<template>
  <li :class="{ 'download-complete': item.export_status === 'completed' }">
    <i :class="`icon ${item.file_format}-icon`"/>
    <div class="item-status">
      <div class="item-name">{{ item.file_name }}.{{ item.file_format }}</div>
      <div
        v-if="item.export_status === 'started'"
        class="progress">
        <div
          :aria-valuenow="item.progress"
          :style="`width: ${item.progress}%`"
          class="progress-bar"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"/>
      </div>
      <div class="percentage-status">{{ status }}</div>
    </div>
    <div class="action-buttons">
      <template v-if="item.export_status === 'completed'">
        <i
          class="icon eye-icon"
          @click="$emit('preview', item)" />
        <i
          class="icon download-icon-dark"
          @click="generateDownloadLink({ id: item.export_id })"/>
      </template>
      <i
        class="removed-icon"
        @click="removeItem"/>
    </div>
  </li>
</template>

<script>
import downloadExport from '~/mixins/downloadExport'

export default {
  name: 'CurrentDownloadsItem',
  mixins: [downloadExport],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    status() {
      return this.item.export_status === 'queued'
        ? 'Queued'
        : `${this.item.progress}% complete`
    }
  },
  methods: {
    removeItem() {
      if (this.item.export_status === 'completed') {
        this.$emit('remove-download', this.item.export_id)
        return
      }
      this.$emit('cancel-download', this.item.export_id)
    }
  }
}
</script>
