/* eslint-disable */
import Pager from './Pager.vue'
import Jumper from './Jumper.vue'
import Total from './Total.vue'
import Sizes from './Sizes.vue'
import RowsCount from './RowsCount.vue'
import { mapState } from 'vuex'

export default {
  name: 'ElPagination',

  props: {
    pageSize: {
      type: Number,
      default: 10
    },

    small: Boolean,

    total: Number,
    limit: Number,
    totalLimit: {
      type: Number,
      default: 10000
    },
    currentPage: {
      type: Number,
      default: 1
    },

    layout: {
      default: 'total, sizes, pager, jumper, rows'
    },

    data: {
      type: Array,
      required: true
    },

    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 40, 50, 100]
      }
    },

    maxPageMessage: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      internalCurrentPage: 1,
      internalPageSize: 0
    }
  },

  render(h) {
    const template = <div class="el-pagination" />
    template.children = []
    const layout = this.layout || ''
    if (!layout) return
    const TEMPLATE_MAP = {
      jumper: <jumper />,
      pager: (
        <pager
          currentPage={this.internalCurrentPage}
          pageCount={this.internalPageCount}
          on-change={this.handleCurrentChange}
          showLast={this.total < this.totalLimit}
        />
      ),
      sizes: <sizes pageSizes={this.pageSizes} />,
      slot: <my-slot />,
      total: <total />,
      rows: <rows-count
              currentPage={this.internalCurrentPage}
              pageSizes={this.internalPageSize}
              totalLimit={this.limit}
              total={this.total}
              data={this.data}
            />
    }
    const components = layout.split(',').map(item => item.trim())
    const rightWrapper = <div class="el-pagination__rightwrapper" />
    let haveRightWrapper = false

    if (this.small) {
      template.data.class += ' el-pagination--small'
    }

    components.forEach(compo => {
      if (compo === '->') {
        haveRightWrapper = true
        return
      }

      const tp = TEMPLATE_MAP[compo]

      if (!tp) {
        return
      }

      if (!haveRightWrapper) {
        template.children.push(tp)
      } else {
        rightWrapper.children.push(tp)
      }
    })

    if (haveRightWrapper) {
      template.children.unshift(rightWrapper)
    }

    return template
  },

  components: {
    MySlot: {
      render(h) {
        return this.$parent.$slots.default ? this.$parent.$slots.default[0] : ''
      }
    },

    Sizes,

    Jumper,
    Total,

    Pager,
    RowsCount
  },

  methods: {
    handleCurrentChange(val) {
      this.internalCurrentPage = this.getValidCurrentPage(val)
    },

    prev() {
      const newVal = this.internalCurrentPage - 1
      this.internalCurrentPage = this.getValidCurrentPage(newVal)
    },

    next() {
      const newVal = this.internalCurrentPage + 1
      this.internalCurrentPage = this.getValidCurrentPage(newVal)
    },

    getValidCurrentPage(value) {
      value = parseInt(value, 10)

      const havePageCount = typeof this.internalPageCount === 'number'

      let resetValue
      if (!havePageCount) {
        if (isNaN(value) || value < 1) resetValue = 1
      } else {
        if (value < 1) {
          resetValue = 1
        } else if (value > this.internalPageCount) {
          if (this.maxPageMessage) {
            this.$notify.warning({
              message: this.maxPageMessage
            })
          }
          resetValue = this.internalPageCount
        }
      }

      if (resetValue === undefined && isNaN(value)) {
        resetValue = 1
      } else if (resetValue === 0) {
        resetValue = 1
      }

      return resetValue === undefined ? value : resetValue
    }
  },

  computed: {
    ...mapState('search', ['initializingPage']),
    internalPageCount() {
      const totalPagesCount = Math.ceil(this.total / this.internalPageSize)
      const maxPagesCount = Math.ceil(this.limit / this.internalPageSize)
      return Math.min(totalPagesCount, maxPagesCount)
    }
  },

  watch: {
    currentPage: {
      immediate: true,
      handler(val) {
        this.internalCurrentPage = val
      }
    },

    pageSize: {
      immediate: true,
      handler(val) {
        this.internalPageSize = val
      }
    },

    internalPageSize(newVal, oldVal) {
      if (newVal !== undefined) {
        this.$nextTick(() => {
          if (oldVal !== newVal) {
            this.$emit('size-change', newVal)
          }
        })
      } else {
        this.$emit('size-change', newVal)
      }
    },

    internalCurrentPage(newVal, oldVal) {
      newVal = parseInt(newVal, 10)

      if (this.initializingPage) {
        return
      }

      /* istanbul ignore if */
      if (isNaN(newVal)) {
        newVal = oldVal || 1
      } else {
        newVal = this.getValidCurrentPage(newVal)
      }

      if (newVal !== undefined) {
        this.$nextTick(() => {
          this.internalCurrentPage = newVal
          if (oldVal !== newVal) {
            this.$emit('update:currentPage', newVal)
            this.$emit('current-change', this.internalCurrentPage)
          }
        })
      } else {
        this.$emit('update:currentPage', newVal)
        this.$emit('current-change', this.internalCurrentPage)
      }
    },

    internalPageCount(newVal) {
      /* istanbul ignore if */
      const oldPage = this.internalCurrentPage
      if (newVal > 0 && oldPage === 0) {
        this.internalCurrentPage = 1
      } else if (oldPage > newVal) {
        this.internalCurrentPage = newVal === 0 ? 1 : newVal
      }
    }
  }
}
