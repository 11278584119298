import _ from 'lodash'
import camelize from 'camelize'
import fieldsConfig from '~/config/fields'

export const state = () => ({
  initialView: 'legacy order',
  activeShipmentsView: {
    name: '',
    items: [],
    readonly: true
  },
  views: [],
  fields: {},
  activeAggregateView: 'overview',
  activeAggregateViews: [
    'overview',
    'shipments',
    'consignees',
    'shippers',
    'analytics'
  ]
})

export const mutations = {
  resetState(currentState) {
    Object.assign(currentState, state())
  },
  setViews(state, views) {
    state.views = views
  },
  setViewFields(state, view) {
    const index = _.findIndex(
      state.views,
      stateView => stateView.templateId === view.templateId
    )
    state.views[index].items = view.items
  },
  setActiveShipmentsView(state, view) {
    state.activeShipmentsView = view
  },
  deleteView(state, view) {
    state.views = _.filter(
      state.views,
      stateView => stateView.templateId !== view.templateId
    )
  },
  addView(state, view) {
    state.views = [...state.views, view]
  },
  setFields(state, fields) {
    const excludedFields = [
      'ShipmentId',
      'Containers',
      'ConsigneeAddressBreakdown',
      'NotifyPartyAddressBreakdown',
      'ShipperAddressBreakdown'
    ]

    state.fields = camelize(_.omit(fields, excludedFields))
  },
  setActiveAggregateView(state, view) {
    state.activeAggregateView = view
  }
}

export const getters = {
  getDefaultView(state) {
    let defaultView = state.views.find(
      view => view.name.trim().toLowerCase() === state.initialView
    )

    if (defaultView) {
      return defaultView
    }

    return _.head(state.views)
  },
  customFieldsAvailable(state) {
    const fields = Object.assign({}, state.fields)
    delete fields['AllFields']
    return fields
  },
  sortFields() {
    const getPriority = fieldId => {
      const fields = fieldsConfig.matchable
      const fieldFound = fields.find(field => field.id === fieldId)
      return fieldFound ? fieldFound.priority : Infinity
    }

    return fields => {
      return fields.sort((a, b) => (getPriority(a) > getPriority(b) ? 1 : -1))
    }
  },
  availableFields(state, getters) {
    return [
      ...getters.sortFields(
        Object.keys(
          _.pickBy(
            state.fields,
            (field, id) => field.searchable && !['ArrivalDate'].includes(id)
          )
        )
      )
    ].reduce(
      (field, key) =>
        Object.assign(field, { [key]: { ...state.fields[key] } }, {}),
      {}
    )
  }
}

export const actions = {
  async fetchViews({ commit, rootState }) {
    const { clientId } = this.$auth.user
    const { data } = await this.$axios.$get(
      `${USERS_API_URL}/v1/client/${clientId}/custom-view`,
      {
        params: {
          country: rootState.country,
          shipmentType: rootState.tradeType
        }
      }
    )

    commit('setViews', data)
  },
  async saveView({ dispatch, commit, state, rootState }, { view, fields }) {
    if (_.findIndex(state.views, v => v === view) > -1) {
      const { clientId } = this.$auth.user

      const { data } = await this.$axios.$put(
        `${USERS_API_URL}/v1/client/${clientId}/custom-view/${view.templateId}`,
        {
          country: rootState.country,
          shipmentType: rootState.tradeType,
          name: view.name,
          items: fields
        }
      )

      data.forEach(view => {
        commit('setViewFields', view)
      })

      return
    }

    await dispatch('addView', { view, fields })
  },
  async addView({ rootState, commit }, { view, fields }) {
    const { clientId } = this.$auth.user
    const { data } = await this.$axios.$post(
      `${USERS_API_URL}/v1/client/${clientId}/custom-view`,
      {
        country: rootState.country,
        shipmentType: rootState.tradeType,
        name: view.name,
        items: fields
      }
    )

    data.forEach(view => {
      commit('addView', view)
    })
  },
  async deleteView({ commit }, view) {
    const { clientId } = this.$auth.user
    const { data } = await this.$axios.$delete(
      `${USERS_API_URL}/v1/client/${clientId}/custom-view/${view.templateId}`
    )

    data.forEach(view => {
      commit('deleteView', view)
    })
  }
}
