import $date from '../../plugins/date/methods'

export const state = () => ({
  totals: {
    asConsignee: 0,
    asShipper: 0,
    internal: 0
  },

  topLocations: {
    destinations: [],
    origins: []
  },
  selectedContinent: 'Global',
  selectedLocation: '',

  topPorts: {
    destinations: [],
    origins: []
  },
  topCountriesOfOrigin: [],
  topProductTerms: {
    all: [],
    consignee: [],
    shipper: [],
    internal: []
  },
  topProducts: {
    consignees: [],
    shippers: []
  },
  overviewChartsTopPorts: {
    destinations: [],
    origins: []
  },
  overviewChartsTopCountriesOfOrigin: [],
  overviewChartsTopTraders: {
    consignees: [],
    shippers: []
  },
  stat: 'all',
  dateInterval: 'months'
})

export const getters = {
  dateRangeText(state, getters, rootState) {
    const {
      twoWeeksAgo,
      oneWeekAgo,
      oneYearAgo,
      threeMonthsAgo,
      oneMonthAgo,
      end
    } = $date.getCommonDates(rootState.maxDate)
    const { from, to } = rootState.company.dateRange
    if (end === to) {
      const texts = {
        [oneYearAgo]: 'for the past year',
        [threeMonthsAgo]: 'for the past 3 months',
        [oneMonthAgo]: 'for the past month',
        [twoWeeksAgo]: 'for the past 2 weeks',
        [oneWeekAgo]: 'for the past week'
      }

      return texts[from]
    }
    const toFormat = timestamp => {
      const date = new Date(timestamp)
      return $date.formatUtc(date, 'yyyy-MM-dd')
    }

    return `from ${toFormat(from)} to ${toFormat(to)}`
  },
  shipmentKeys({ activeCompanyType }) {
    const countryTypeAliases = {
      shipper: 'asShipper',
      consignee: 'asConsignee',
      internal: 'internal',
      all: 'all'
    }

    const keys = {
      asShipper: ['shipper'],
      asConsignee: ['consignee'],
      internal: ['internal'],
      all: ['consignee', 'shipper', 'internal']
    }

    return keys[countryTypeAliases[activeCompanyType]] || []
  }
}

export const mutations = {
  resetState(currentState) {
    Object.assign(currentState, state())
  },
  setTopPorts(state, topPorts) {
    state.topPorts = topPorts
  },
  setTopProductTerms(state, topProductTerms) {
    state.topProductTerms = topProductTerms
  },
  setTopTraders(state, topProduct) {
    state.topProducts = topProduct
  },
  setTopLocations(state, locations) {
    state.topLocations = locations
  },
  setSelectedLocation(state, location) {
    state.selectedLocation = location
  },
  setSelectedContinent(state, continent) {
    state.selectedContinent = continent
  },
  setTopCountriesOfOrigin(state, topCountries) {
    state.topCountriesOfOrigin = topCountries
  },
  setOverviewChartsTopTraders(state, overviewChartsTopTraders) {
    state.overviewChartsTopTraders = overviewChartsTopTraders
  },
  setOverviewChartsTopPorts(state, overviewChartsTopPorts) {
    state.overviewChartsTopPorts = overviewChartsTopPorts
  },
  setOverviewChartsTopCountriesOfOrigin(
    state,
    overviewChartsTopCountriesOfOrigin
  ) {
    state.overviewChartsTopCountriesOfOrigin = overviewChartsTopCountriesOfOrigin
  }
}

export const actions = {
  async initialize({ rootState, dispatch }) {
    const promises = [
      dispatch('fetchCompanyTraders'),
      dispatch('fetchOverviewChartsTopTraders')
    ]

    if (rootState.company.isImporter) {
      promises.push(dispatch('fetchTopCountriesOfOrigin'))
      promises.push(dispatch('fetchOverviewChartsTopCountriesOfOrigin'))
    }

    if (rootState.company.isSupplier) {
      promises.push(dispatch('fetchTopPorts'))
      promises.push(dispatch('fetchOverviewChartsTopPorts'))
    }

    await Promise.all(promises)
  },
  async fetchTopCountriesOfOrigin({ rootState, rootGetters, commit }) {
    try {
      const {
        country,
        tradeType: shipmentType,
        company: { companyId }
      } = rootState
      const { from, to } = rootGetters['company/arrivalDateBetween']

      const { data } = await this.$axios.get(
        `${COMPANY_API_URL}/companies/${companyId}/top/country-of-origin`,
        {
          params: {
            arrivalDates: `${from}-${to}`,
            country,
            shipmentType
          }
        },
        { progress: false }
      )

      commit('setTopCountriesOfOrigin', data.data.CountryOfOrigin)
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async fetchTopPorts({ rootState, rootGetters, commit }) {
    try {
      const {
        country,
        tradeType: shipmentType,
        company: { companyId }
      } = rootState
      const { from, to } = rootGetters['company/arrivalDateBetween']
      const { data } = await this.$axios.get(
        `${COMPANY_API_URL}/companies/${companyId}/top/ports`,
        {
          params: {
            arrivalDates: `${from}-${to}`,
            country,
            shipmentType
          }
        },
        { progress: false }
      )

      commit('setTopPorts', {
        destinations: data.data.destination.ports,
        origins: data.data.origin.ports
      })
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async fetchTopLocations({ state, commit, rootState, rootGetters }) {
    const types = ['origin', 'destination']
    const topLocations = {}

    const promises = types.map(async type => {
      const endpoint = `get_top_shipment_${type}_locations`
      const url = `${SHIPMENTS_API_URL}/v1/us/import/company/${
        rootState.company.companyId
      }/${endpoint}`

      const { locations } = await this.$axios.$post(
        url,
        {
          arrivalDateBetween: rootGetters['company/arrivalDateBetween'],
          continent: state.selectedContinent
        },
        { progress: false }
      )

      // fetch borders
      await Promise.all(
        locations.map(async location => {
          location.borders = await this.$axios
            .$post(
              '/api/borders',
              {
                country: location.country_iso2_code,
                state: location.state_iso2_code
              },
              { progress: false }
            )
            .catch(() => ({
              type: 'Polygon',
              coordinates: [[]]
            }))
        })
      )

      topLocations[type + 's'] = locations
    })

    await Promise.all(promises)

    // Shipper uses destination
    // Every other stat type uses origin
    commit('setTopLocations', topLocations)
  },
  async fetchCompanyTraders({ commit, rootState, rootGetters }) {
    const requestId = 'fetchCompanyTraders'
    this.$axios.cancel(requestId)
    const tradersEndpoints = ['consignees', 'shippers']
    const topTraders = {}

    await Promise.all(
      tradersEndpoints.map(async key => {
        let terms = []

        try {
          const { from, to } = rootGetters['company/arrivalDateBetween']
          const {
            country,
            tradeType: shipmentType,
            company: { companyId }
          } = rootState
          const sortBy = rootGetters['company/companySortBy']
          const { data } = await this.$axios.$get(
            `${COMPANY_API_URL}/companies/${companyId}/${key}`,
            {
              params: {
                arrivalDates: `${from}-${to}`,
                sortBy,
                country,
                shipmentType
              }
            }
          )

          terms = data.items

          if (key === 'consignees') {
            commit('company/importers/setConsignees', terms, {
              root: true
            })
          } else {
            commit('company/suppliers/setSuppliers', terms, {
              root: true
            })
          }
        } catch (error) {
          if (this.$axios.isCancel(error)) {
            return // request has been canceled
          }
          throw error
        }

        topTraders[key] = terms
      })
    )

    commit('setTopTraders', topTraders)
  },
  async fetchTopProductTerms({ commit, rootState, rootGetters }) {
    const topProductTermsEndpoints = {
      all: 'get-company-top-product-terms',
      consignee: 'get-company-top-product-terms-as-consignee',
      shipper: 'get-company-top-product-terms-as-shipper',
      internal: 'get-company-top-internal-product-terms'
    }
    const topProductTerms = {}

    await Promise.all(
      Object.keys(topProductTermsEndpoints).map(async key => {
        const endpoint = topProductTermsEndpoints[key]
        let terms = []

        try {
          const data = await this.$axios.$post(
            `${SHIPMENTS_API_URL}/v1/us/import/company/${
              rootState.company.companyId
            }/${endpoint}`,
            {
              arrivalDateBetween: rootGetters['company/arrivalDateBetween'],
              count: 5
            },
            { progress: false }
          )

          terms = data.terms
        } catch (error) {
          // eslint-disable-next-line
          console.error(error)
        }

        topProductTerms[key] = terms
      })
    )

    commit('setTopProductTerms', topProductTerms)
  },
  async setSelectedContinent({ commit, dispatch, state }, continent) {
    commit('setSelectedContinent', continent)
    await dispatch('fetchTopLocations')
    commit('setSelectedLocation', state.locations[0])
  },
  async fetchOverviewChartsTopCountriesOfOrigin({ rootState, commit }) {
    try {
      const {
        country,
        tradeType: shipmentType,
        company: { companyId }
      } = rootState
      const startDate = new Date(rootState.minDate).getTime() / 1000
      const endDate = new Date(rootState.maxDate).getTime() / 1000

      const { data } = await this.$axios.get(
        `${COMPANY_API_URL}/companies/${companyId}/top/country-of-origin`,
        {
          params: {
            arrivalDates: `${startDate}-${endDate}`,
            country,
            shipmentType
          }
        },
        { progress: false }
      )

      commit('setOverviewChartsTopCountriesOfOrigin', data.data.CountryOfOrigin)
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async fetchOverviewChartsTopPorts({ rootState, commit }) {
    try {
      const startDate = new Date(rootState.minDate).getTime() / 1000
      const endDate = new Date(rootState.maxDate).getTime() / 1000

      const { data } = await this.$axios.get(
        `${COMPANY_API_URL}/companies/${rootState.company.companyId}/top/ports`,
        {
          params: {
            arrivalDates: `${startDate}-${endDate}`,
            country: `${rootState.country}`,
            shipmentType: `${rootState.tradeType}`
          }
        },
        { progress: false }
      )

      commit('setOverviewChartsTopPorts', {
        destinations: data.data.destination.ports,
        origins: data.data.origin.ports
      })
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async fetchOverviewChartsTopTraders({ commit, rootState, rootGetters }) {
    const requestId = 'fetchOverviewChartsTopTraders'
    this.$axios.cancel(requestId)
    const topTradersEndpoints = ['consignees', 'shippers']
    const overviewTopTraders = {}

    await Promise.all(
      topTradersEndpoints.map(async key => {
        let terms = []

        try {
          const startDate = new Date(rootState.minDate).getTime() / 1000
          const endDate = new Date(rootState.maxDate).getTime() / 1000
          const sortBy = rootGetters['company/companySortBy']
          const { data } = await this.$axios.$get(
            `${COMPANY_API_URL}/companies/${
              rootState.company.companyId
            }/top/${key}`,
            {
              params: {
                arrivalDates: `${startDate}-${endDate}`,
                sortBy,
                country: `${rootState.country}`,
                shipmentType: `${rootState.tradeType}`
              }
            }
          )

          terms = key === 'consignees' ? data.Consignee : data.Shipper
        } catch (error) {
          if (this.$axios.isCancel(error)) {
            return // request has been canceled
          }
          throw error
        }

        overviewTopTraders[key] = terms.map(company => ({
          ...company,
          link:
            company.name !== 'Others' ? `/company/${company.id}/overview` : null
        }))
      })
    )
    commit('setOverviewChartsTopTraders', overviewTopTraders)
  }
}
