<template>
  <multiselect
    ref="multiselect"
    :value="value"
    :options="Object.keys(options)"
    :searchable="false"
    :show-labels="false"
    :allow-empty="false"
    class="custom-select"
    @input="onInput">
    <template
      slot="singleLabel"
      slot-scope="{ option }">
      <span>{{ options[option] }}</span>
    </template>
    <template
      slot="option"
      slot-scope="{ option }">
      <span>{{ options[option] }}</span>
    </template>
  </multiselect>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      options: {
        AND: 'All',
        OR: 'At least one',
        NOT: 'None'
      }
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>
