import {
  subDays,
  subMonths as dateFnsSubMonths,
  addMonths as dateFnsAddMonths,
  format,
  subYears as dateFnsSubYears,
  toDate,
  differenceInCalendarDays,
  differenceInCalendarWeeks,
  addDays
} from 'date-fns'

function handleDstOffsets(oldDate, newDate) {
  const oldOffset = getDstOffset(oldDate)
  const newOffset = getDstOffset(newDate)
  if (newOffset !== oldOffset) {
    const offset = oldOffset - newOffset
    newDate = new Date(newDate.getTime() - offset * 60 * 1000)
  }
  return newDate
}

export function subYears(date, years) {
  return handleDstOffsets(date, dateFnsSubYears(date, years))
}

export function subMonths(date, months) {
  return handleDstOffsets(date, dateFnsSubMonths(date, months))
}

export function addMonths(date, months) {
  return handleDstOffsets(date, dateFnsAddMonths(date, months))
}

export function getPreviousDate(date, noOfDaysAgo = 1) {
  return handleDstOffsets(date, subDays(date, noOfDaysAgo))
}

/**
 * Changes the date to local time and then formats it with date-fns.
 * i.e. 12/15/2019 UTC is converted to 12/15/2019 local
 * @param original
 * @param formatter
 * @returns {string | *}
 */
export function formatUtc(original, formatter) {
  let date = original
  // if is unix timestamp
  if (typeof date === 'number' && date.toString().length === 10) {
    date = date * 1000
  }
  date = toDate(date)
  const offset = getStandardTimezoneOffset(date) * 60 * 1000
  const localTimestamp = date.getTime() + offset
  return format(localTimestamp, formatter)
}

export function getCommonDates(...args) {
  const today = getUtcDate(...args)
  // today's time is set at 8:00 am in PST
  // today.setHours(8, 0, 0, 0)
  const twoWeeksAgo = subDays(today, 13).getTime()
  const oneWeekAgo = subDays(today, 6).getTime()
  const oneYearAgo = subYears(today, 1).getTime()
  const threeYearsAgo = subYears(today, 3).getTime()
  const sixMonthsAgo = subMonths(today, 6).getTime()
  const threeMonthsAgo = subMonths(today, 3).getTime()
  const oneMonthAgo = subMonths(today, 1).getTime()
  const end = today.getTime()
  const oneDayAgo = subDays(today, 1).getTime()

  return {
    today,
    twoWeeksAgo,
    oneWeekAgo,
    oneYearAgo,
    threeYearsAgo,
    sixMonthsAgo,
    threeMonthsAgo,
    oneMonthAgo,
    end,
    oneDayAgo
  }
}

export function getCommonDateRanges(...args) {
  const {
    threeYearsAgo,
    oneYearAgo,
    sixMonthsAgo,
    threeMonthsAgo,
    oneMonthAgo,
    end
  } = getCommonDates(...args)

  return [
    {
      label: '1M',
      text: 'Last 30 days',
      from: oneMonthAgo
    },
    {
      label: '3M',
      text: 'Last 3 months',
      from: threeMonthsAgo
    },
    {
      label: '6M',
      text: 'Last 6 months',
      from: sixMonthsAgo
    },
    {
      label: '1Y',
      text: 'Last 1 year',
      from: oneYearAgo
    },
    {
      label: '3Y',
      text: 'Last 3 years',
      from: threeYearsAgo
    }
  ].map(date => ({ ...date, to: end }))
}

export function getDateInterval(from, to) {
  const dateThresholds = [
    { scale: 'days', days: { min: 1, max: 31 } },
    { scale: 'weeks', days: { min: 32, max: 92 } },
    // { scale: 'months', days: { min: 93, max: 366 } },
    { scale: 'months', days: { min: 93, max: 1096 } },
    // { scale: 'quarters', days: { min: 367, max: 1096 } },
    { scale: 'years', days: { min: 1097 } }
  ]
  const timeDiff = Math.abs(from - to)
  const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))

  return (
    dateThresholds.find(
      val =>
        (!val.days.min || diffDays >= val.days.min) &&
        (!val.days.max || diffDays <= val.days.max)
    ) || {
      scale: 'days'
    }
  ).scale
}

export function getFormat(scale) {
  return {
    years: 'yyyy',
    quarters: "'Q'Q of yyyy",
    months: 'MMMM yyyy',
    weeks: "'Week' w of yyyy",
    days: 'yyyy-MM-dd'
  }[scale]
}

export function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

/**
 * Returns DST offset value > 0 if DST is en effect
 * @param date
 * @returns {number}
 */
export function getDstOffset(date) {
  date = new Date(date)
  const offset = date.getTimezoneOffset()
  const stdOffset = getStandardTimezoneOffset()
  return stdOffset - offset
}

/**
 * Get the timezoneOffset with DST in mind
 * @param args - passed to new Date(..args)
 * @returns {number}
 */
export function getStandardTimezoneOffset(...args) {
  const date = new Date(...args)
  const jan = new Date(date.getFullYear(), 0, 1)
  const jul = new Date(date.getFullYear(), 6, 1)
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
}

/**
 * Returns year/month/day of date as today
 * Only considers year, month, date
 * @param args
 */
export function getLocalDate(...args) {
  const [year, month, day] = new Date(...args)
    .toISOString()
    .split('T')[0]
    .split('-')
  return new Date(year, month - 1, day)
}

/**
 * Returns Start of day for UTC date
 * @param args - passed to new Date(..args)
 * @returns {Date}
 */
export function getUtcDate(...args) {
  const date = new Date(...args)

  const year = date.getFullYear()
  let month = (
    (getStandardTimezoneOffset(date) > 0
      ? date.getUTCMonth()
      : date.getMonth()) + 1
  ).toString()
  let day = (getStandardTimezoneOffset(date) > 0
    ? date.getUTCDate()
    : date.getDate()
  ).toString()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  const isoString = `${year}-${month}-${day}T00:00:00.000Z`
  return new Date(isoString)
}

export function convertUTC(...args) {
  const date = new Date(...args)
  const year = date.getFullYear()
  let month = date.getMonth()
  let day = date.getUTCDate()
  return Date.UTC(year, month, day, 0, 0, 0, 0)
}

/**
 * Returns true if date/timestamp is a start of UTC date
 * @param args - passed to new Date(..args)
 * @returns {boolean}
 */
export function validateUtcDate(...args) {
  const date = new Date(...args)
  const times = date
    .toISOString()
    .substring(11, 23)
    .split(':')

  // Utc is always at 00:00:00.000Z
  for (let i = 0; i < times.length; i++) {
    const time = Number(times[i])
    if (time !== 0) {
      return false
    }
  }
  return true
}

export function getIsCompleteInterval(from, to, interval) {
  const comparisonFunction = {
    days: () => true,
    weeks: (to, from) => differenceInCalendarDays(to, from) / 7 >= 1,
    months: (to, from) => differenceInCalendarWeeks(to, from) >= 4,
    quarters: (to, from) => differenceInCalendarWeeks(to, from) >= 12,
    years: (to, from) => differenceInCalendarWeeks(to, from) >= 52
  }[interval]
  let [start, end] = [from, to].sort()
  start = start * 1000
  /**
   * add one day to account for the start day
   * differenceInCalendarWeeks does not include the start day in the counting
   */
  end = addDays(end * 1000, 1).getTime()

  return comparisonFunction(end, start)
}
