export const state = () => ({
  shippers: [],
  totalShippers: 0,
  sortField: 'CalculatedTEU',
  sortOrder: 'desc',
  currentPage: 1,
  pageLimit: 20,
  aggregateLimit: 500
})

export const getters = {
  aggregatedShippers(state) {
    const start = (state.currentPage - 1) * state.pageLimit
    const end = state.currentPage * state.pageLimit

    return state.shippers.slice(start, end)
  }
}
export const mutations = {
  setShippers(state, shippers) {
    state.shippers = shippers
  },
  setTotalShippers(state, totalShippers) {
    state.totalShippers = totalShippers
  },
  setSorting(state, { sortField, sortOrder }) {
    state.sortField = sortField
    state.sortOrder = sortOrder
  },
  setPageLimit(state, pageLimit) {
    state.pageLimit = +pageLimit
  },
  setCurrentPage(state, page) {
    state.currentPage = +page
  }
}
export const actions = {
  async fetchAggregateShippers({ state, commit, rootState, rootGetters }) {
    const requestId = 'fetchAggregateShippers'
    this.$axios.cancel(requestId)

    try {
      const { params } = rootGetters['search/searchQueryParam']()
      const sort = state.sortOrder === 'desc' ? '-' : ''

      params.set('sortBy', `${sort}${state.sortField}`)
      params.set('size', state.aggregateLimit)
      params.delete('limit')
      params.delete('page')

      const response = await this.$axios.$get(
        `${SHIPMENTS_API_URL}/v1/${rootState.country}/${
          rootState.tradeType
        }/shipments/aggregations/shippers`,
        {
          params,
          requestId,
          progress: false,
          headers: {
            ...(rootState.search.searchToken
              ? { 'x-search-token': rootState.search.searchToken }
              : {})
          }
        }
      )

      commit('setShippers', response.data.items)
      commit('setTotalShippers', response.data.total)
    } catch (error) {
      const response = error.response ? error.response.data : ''

      if (response.reason === 'Daily search limit exceeded') {
        return
      }
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async setSorting({ commit, dispatch }, payload) {
    commit('setSorting', payload)
    commit('setCurrentPage', 1)
    dispatch('fetchAggregateShippers')
  },
  setPageLimit({ commit }, pageLimit) {
    commit('setPageLimit', pageLimit)
    commit('setCurrentPage', 1)
  },
  setCurrentPage({ state, commit }, page) {
    const { totalShippers: total, aggregateLimit: limit, pageLimit } = state
    const viewableAggregateCount = Math.min(total, limit)
    const aggregateRowNumber = state.pageLimit * page

    if (aggregateRowNumber > viewableAggregateCount) {
      const lastPage = Math.ceil(viewableAggregateCount / pageLimit)

      return commit('setCurrentPage', lastPage)
    }

    commit('setCurrentPage', page)
  },
  resetShippersData({ commit }) {
    commit('setShippers', [])
    commit('setTotalShippers', 0)
    commit('setCurrentPage', 1)
  }
}
