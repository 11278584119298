<template>
  <el-popover
    ref="popover"
    trigger="click"
    placement="bottom"
    popper-class="account-container__popover"
    class="account-container">
    <a
      slot="reference"
      class="my-account"
      rel="popover"
      role="button"
      @click.prevent>
      <img
        v-if="user.image_url"
        :src="user.image_url"
        class="my-account">
      <span v-else>{{ userInitials }}</span>
    </a>


    <div class="avatar-container">
      <a class="my-account">
        <img
          v-if="user.image_url"
          :src="user.image_url"
          style="border-radius: 50%;">
        <span v-else>{{ userInitials }}</span>
      </a>

      <div class="user-info">
        <div class="user-info__name">
          {{ this.$auth.user.firstname | lowerCase | startCase }}
          {{ this.$auth.user.lastname | lowerCase | startCase }}
        </div>
        <div class="user-info__email">{{ email }}</div>
      </div>

      <button
        class="btn btn-text removed-icon"
        @click="closePopover"/>
    </div>

    <ul class="block-section">
      <li>
        <a 
          href="#" 
          @click="accountInfoClicked">
          Account Info
        </a>
      </li>
      <li>
        <a :href="updatePasswordURL">
          Change Password
        </a>
      </li>
      <nuxt-link
        to="/bookmarks"
        tag="li"
        @click.native="closePopover">
        Bookmarks
      </nuxt-link>
      <nuxt-link
        to="/shipment-alerts"
        tag="li"
        @click.native="closePopover">
        Shipment Alerts
      </nuxt-link>
      <li @click.prevent="handleLogout">Logout</li>
    </ul>
  </el-popover>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { startCase, lowerCase } from 'lodash'
import posthogHelper from '~/mixins/posthog-helper'
import md5 from 'md5'
import axios from 'axios'

export default {
  filters: {
    startCase,
    lowerCase
  },
  mixins: [posthogHelper],
  data() {
    return {
      gravatarUrl: '',
      accountInfoURL: `${process.env.CONSOLE_APP_URL}/account/user`,
      updatePasswordURL: `${process.env.CONSOLE_APP_URL}/account/password`
    }
  },
  computed: {
    ...mapState({
      email: state => state.auth.user.email || ''
    }),
    ...mapState('accounts', ['user', 'isCardDeclined']),
    userInitials() {
      let initials = ''
      if (this.$auth.user) {
        initials = `${this.$auth.user.firstname[0]}${
          this.$auth.user.lastname[0]
        }`
      }
      return initials
    }
  },
  methods: {
    ...mapMutations('accounts', ['setGravatarUrl']),
    ...mapGetters('experiments', ['isIdentified']),
    closePopover() {
      this.$refs.popover.doClose()
    },
    accountInfoClicked() {
      if (this.isUserPartOfRedirectTestExperiment()) {
        this.$posthog.capture('Click - Account Info', {
          from: 'lighthouse',
          to: 'console'
        })
      }

      window.location.href = this.accountInfoURL
    },
    async handleLogout() {
      this.closePopover()
      window.location = `${process.env.CONSOLE_APP_URL}/logout`
    },
    async checkGravatar() {
      const urlString = `https://www.gravatar.com/avatar/${md5(
        this.email
      )}.jpeg?d=blank`
      try {
        const { data } = await axios.get(urlString, { responseType: 'blob' })

        this.gravatarUrl = data.type === 'image/jpeg' ? urlString : ''
        this.setGravatarUrl(this.gravatarUrl)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          return
        }
        throw error
      }
    }
  }
}
</script>

<style scoped lang="scss">
.el-popover {
  ul {
    li {
      &:hover {
        a {
          color: #15a2ed;
          cursor: pointer;
          background-color: #e7f5fd;
        }
      }
    }
  }
}
</style>
<style src="~/assets/scss/sections/navbar/account-settings.scss" lang="scss"/>
