export const state = () => ({
  companyProfileViews: {
    count: null,
    limit: null,
    companyIds: []
  },
  searches: {
    count: null,
    limit: null
  },
  downloads: {
    count: null,
    limit: null
  },
  emailAlerts: {
    count: null,
    limit: null
  },
  contactRequests: {
    count: null,
    limit: null
  }
})

export const getters = {
  companyViewsPercentage: state => {
    const { count, limit } = state.companyProfileViews
    if (count === null || limit === 0) {
      return 'Not Available'
    }
    return Math.ceil((count / limit) * 100)
  },
  searchesPercentage: state => {
    const { count, limit } = state.searches
    if (count === null || limit === 0) {
      return 'Not Available'
    }
    return Math.ceil((count / limit) * 100)
  },
  downloadPercentage: state => {
    const { count, limit } = state.downloads
    if (count === null || limit === 0) {
      return 'Not Available'
    }
    return Math.ceil((count / limit) * 100)
  },
  emailAlertsPercentage: state => {
    const { count, limit } = state.emailAlerts
    if (count === null || limit === 0) {
      return 'Not Available'
    }
    return Math.ceil((count / limit) * 100)
  },
  contactRequestsPercentage: state => {
    const { count, limit } = state.contactRequests
    if (count === null || limit === 0) {
      return 'Not Available'
    }
    return Math.ceil((count / limit) * 100)
  },
  companyViewsCredits: state => {
    return Math.max(
      0,
      state.companyProfileViews.limit - state.companyProfileViews.count
    )
  },
  searchCredits: state => {
    return Math.max(0, state.searches.limit - state.searches.count)
  },
  downloadCredits: state => {
    return Math.max(0, state.downloads.limit - state.downloads.count)
  },
  emailAlertsCredits: state => {
    return Math.max(0, state.emailAlerts.limit - state.emailAlerts.count)
  },
  contactRequestsCredits: state => {
    return Math.max(
      0,
      state.contactRequests.limit - state.contactRequests.count
    )
  }
}

export const mutations = {
  setUserCredits(state, data) {
    if (data.companyProfileViews) {
      state.companyProfileViews = data.companyProfileViews
    }
    if (data.searches) {
      state.searches = data.searches
    }
    if (data.downloads) {
      state.downloads = data.downloads
    }
    if (data.emailAlerts) {
      state.emailAlerts = data.emailAlerts
    }
    if (data.contactRequests) {
      state.contactRequests = data.contactRequests
    }
  }
}

export const actions = {
  async getUserCredits({ commit }) {
    try {
      const { id: clientId } = this.$auth.user
      const { data } = await this.$axios.$get(
        `${USERS_API_URL}/client/${clientId}/credits`,
        { progress: false }
      )
      commit('setUserCredits', data)
    } catch (error) {
      if (this.$axios.isCancel(error)) return
      throw error
    }
  }
}
