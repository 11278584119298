/**
 * Loads the Zoho SalesIQ script.
 * @param {Function} readyCallback - The callback function to run after Zoho SalesIQ is ready.
 */
export default function loadZohoSalesIQ(readyCallback) {
  const { ZOHO_SALESIQ_WIDGET_CODE } = process.env

  if (!ZOHO_SALESIQ_WIDGET_CODE) {
    return
  }

  // Check if the $zoho object exists
  window.$zoho = window.$zoho || {}
  window.$zoho.salesiq = window.$zoho.salesiq || {
    widgetcode: ZOHO_SALESIQ_WIDGET_CODE,
    values: {},
    ready() {}
  }

  // Assign a function to `window.$zoho.salesiq.ready` if not defined
  window.$zoho.salesiq.ready =
    window.$zoho.salesiq.ready ||
    function(callback) {
      callback()
    }

  document.body.addEventListener('mouseenter', loadZohoSalesIQ)

  function loadZohoSalesIQ() {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'zsiqscript'
    script.defer = true
    script.src = 'https://salesiq.zohopublic.com/widget'

    const firstScript = document.getElementsByTagName('script')[0]
    firstScript.parentNode?.insertBefore(script, firstScript)

    document.body.removeEventListener('mouseenter', loadZohoSalesIQ)

    window.$zoho.salesiq.ready = () => {
      if (readyCallback) {
        readyCallback()
      }
    }
  }
}
