import { mapGetters, mapActions } from 'vuex'
import clientWebAPI from '~/api/helpers/client'

export default {
  computed: {
    ...mapGetters('user', ['clientId']),
    ...mapGetters('userSubscriptions', ['recurlyPlans'])
  },
  async mounted() {
    if (
      this.$auth &&
      this.$auth.user &&
      this.$gtm &&
      this.$auth.user.shouldTrackOffline
    ) {
      try {
        if (!this.recurlySubscriptions) {
          await this.fetchRecurlySubscriptions()
        }

        this.$gtm.push({
          event: 'plg_signup_offline',
          plans: this.recurlyPlans
        })

        const clientId = this.$auth.user.id
        await clientWebAPI.updateShouldTrackOffline(this.$axios, clientId)
        await this.$auth.fetchUser()
      } catch (err) {
        this.$sentry.captureException(err)
      }
    }
  },
  methods: {
    ...mapActions('userSubscriptions', ['fetchRecurlySubscriptions'])
  }
}
