/**
 * @deprecated
 * Fetch announcement status of the user
 *
 * @param {Object} context - Nuxt context
 * @param {Object} context.store - Vuex store
 */
export async function fetchStarterAnnouncement({ store, $auth }) {
  if ($auth.user) {
    await store.dispatch('fetchStarterAnnouncement')
  }
}

export default fetchStarterAnnouncement
