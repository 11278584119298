import shareMutations from 'vuex-shared-mutations'

const sharedMutations = [
  'userCredits/setUserCredits',
  'userSubscriptions/setUserSubscriptions'
]

export default function initializeVuexSharedMutations(store) {
  shareMutations({
    predicate: sharedMutations
  })(store)
}
