export function poll(condition, { limit = 5000, interval = 200 } = {}) {
  return new Promise((resolve, reject) => {
    const evaluate = () => {
      if (!condition() && limit > 0) {
        limit -= interval
        setTimeout(evaluate, interval)
      } else if (!limit) {
        reject(
          `Poll for the following condition has expired:\n${condition.toString()}`
        )
      } else {
        resolve()
      }
    }

    evaluate()
  })
}

export function isDirectlyHovered(element) {
  return element.parentElement.querySelector(':hover') === element
}

export function existsInDOM(element) {
  if (!(element instanceof HTMLElement)) return false

  if (element.id) return document.getElementById(element.id)

  if (element.className) {
    return document.getElementsByClassName(element.className).length
  }

  return document.body.contains(element)
}

// See: https://gist.github.com/twxia/bb20843c495a49644be6ea3804c0d775
export function getHorizontallyScrollableParent(node) {
  if (!node) return null

  const parent = node.parentNode

  if (!parent || !(parent instanceof HTMLElement)) return document.body

  const overflowY = window.getComputedStyle(parent).overflowY
  const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden'
  const scrollbarIsVisible = parent.scrollWidth > parent.clientWidth

  if (isScrollable && scrollbarIsVisible) return parent

  return getHorizontallyScrollableParent(parent)
}

export default {
  poll,
  isDirectlyHovered,
  existsInDOM,
  getHorizontallyScrollableParent
}
