export default {
  methods: {
    checkPfsFlag() {
      if (!this.$auth || !this.$auth.user) {
        console.error('Auth or user is not defined')
        return
      }

      const {
        requireInfoUpdate,
        require_password_change: requirePasswordChange
      } = this.$auth.user

      if (requireInfoUpdate === 1 && requirePasswordChange === 1) {
        window.location.href =
          process.env.CONSOLE_APP_URL + '/account/onboarding'
      }

      if (requireInfoUpdate === 1) {
        window.location.href = process.env.CONSOLE_APP_URL + '/account/user'
      }

      if (requirePasswordChange === 1) {
        window.location.href = process.env.CONSOLE_APP_URL + '/account/password'
      }
    }
  },
  created() {
    this.checkPfsFlag()
  }
}
