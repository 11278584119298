export default async function(ctx) {
  // Validate if for Recurly migration
  if (ctx.$auth && ctx.$auth.user) {
    // Redirect to terms only if user has an active plan
    const {
      cancelled,
      isForMigration,
      isForNupiMigration,
      edu_verification_code,
      edu_url
    } = ctx.$auth.user
    const { SITE_URL } = REDIRECT_URLS //eslint-disable-line no-undef

    // Redirect to site verification for unverified university users
    if (edu_verification_code && edu_url) {
      window.location.assign(
        `${SITE_URL}/edu/${edu_url}/almostdone/${edu_verification_code}`
      )
      return
    }

    if (isForMigration && !cancelled) {
      const encoded = encodeURI(window.location.href)
      location.assign(
        `${
          process.env.CONSOLE_APP_URL
        }/account/terms?code=ncon&returl=${encoded}`
      )
      return
    }

    if (isForNupiMigration) {
      const encoded = encodeURI(window.location.href)
      location.assign(
        `${process.env.CONSOLE_APP_URL}/account/terms/non-us?returl=${encoded}`
      )
      return
    }
  }

  const {
    country_access: countries,
    team = null
  } = ctx.store.state.userSubscriptions.features
  const plans = ctx.store.state.userSubscriptions.plans

  // For team accounts, redirect billing admin to Console
  const hasBillingAdminPlan =
    plans && plans.some(plan => plan.name === 'team-billing-admin')

  if (team && hasBillingAdminPlan) {
    location.assign(`${process.env.CONSOLE_APP_URL}/account/team-management`)
    return
  }

  // Not for Recurly migration
  if (plans.length === 0 || !countries.includes('us')) {
    ctx.store.commit('setRedirectNonMigratedAccount', true)
  }

  if (ctx.store.state.redirectNonMigratedAccount) {
    location.assign(`${process.env.CONSOLE_APP_URL}/dashboard?code=nvus`)
  }
}
