export const countries = [
  {
    countryCode: 'ar',
    name: 'Argentina'
  },
  {
    countryCode: 'bo',
    name: 'Bolivia'
  },
  {
    countryCode: 'cl',
    name: 'Chile'
  },
  {
    countryCode: 'co',
    name: 'Colombia'
  },
  {
    countryCode: 'cr',
    name: 'Costa Rica'
  },
  {
    countryCode: 'ec',
    name: 'Ecuador'
  },
  {
    countryCode: 'in',
    name: 'India'
  },
  {
    countryCode: 'mx',
    name: 'Mexico'
  },
  {
    countryCode: 'pa',
    name: 'Panama'
  },
  {
    countryCode: 'py',
    name: 'Paraguay'
  },
  {
    countryCode: 'pe',
    name: 'Peru'
  },
  {
    countryCode: 'ru',
    name: 'Russia'
  },
  {
    countryCode: 'ua',
    name: 'Ukraine'
  },
  {
    countryCode: 'uy',
    name: 'Uruguay'
  },
  {
    countryCode: 'us',
    name: 'USA'
  },
  {
    countryCode: 've',
    name: 'Venezuela'
  }
]

export const miscFilters = [
  {
    displayName: 'Exclude Blank Consignee',
    fieldName: 'ExcludeBlankConsignee',
    value: 'BlankConsigneesExcluded',
    info: "Don't show shipments with undeclared consignee/importers"
  },
  // {
  //   displayName: 'Exclude Blank Shipper',
  //   fieldName: 'ExcludeBlankShipper',
  //   value: 'BlankShippersExcluded'
  // },
  {
    displayName: 'Exclude Master',
    fieldName: 'ExcludeMaster',
    value: 'MasterShipmentsExcluded',
    info: "Don't show Master Bill of Lading shipments"
  },
  {
    displayName: 'House Only',
    fieldName: 'HouseOnly',
    value: 'HouseShipmentsOnly',
    info: 'Only show House Bill of Lading shipments'
  },
  {
    displayName: 'Master Only',
    fieldName: 'MasterOnly',
    value: 'MasterShipmentsOnly',
    info: 'Only show Master Bill of Lading shipments'
  }
]

export const characterLimit = 50

export { default as chart } from './chart'

export { default as fields } from './fields'
