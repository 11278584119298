import { datadogRum } from '@datadog/browser-rum'

const rumAppId = process.env.DATADOG_RUM_APPLICATION_ID
const serviceName = process.env.DATADOG_SERVICE
const rumToken = process.env.DATADOG_RUM_CLIENT_TOKEN
const datadogUrl = 'datadoghq.com'
const enableDatadogRum = process.env.DATADOG_RUM_ENABLED
const environmentName = process.env.NODE_ENV

export default function() {
  if (String(enableDatadogRum).toLowerCase() === 'true') {
    datadogRum.init({
      applicationId: rumAppId,
      clientToken: rumToken,
      site: datadogUrl,
      service: serviceName,
      sampleRate: 100,
      env: environmentName,
      trackInteractions: true,
      allowedTracingOrigins: [/https:\/\/.*\.importgenius\.com/]
    })
  }
}
