export default {
  layout: {
    padding: {
      left: 0,
      right: 0
    }
  },
  cutoutPercentage: 0,
  tooltips: {
    enabled: false,
    mode: 'index',
    caretSize: 5,
    position: 'nearest',
    callbacks: {
      title(tooltipItems, data) {
        const idx = tooltipItems[0].index
        return data.labels[idx]
      }
    }
  },
  animation: { duration: 500 },
  legend: { display: false },
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 4,
      hitRadius: 10
    },
    line: {
      tension: 0
    }
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
          drawBorder: false,
          tickMarkLength: 20
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 15,
          maxRotation: 0,
          minRotation: 0,
          fontSize: 11,
          fontColor: 'rgba(52, 64, 80, 0.7)'
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          color: 'rgba(0,0,0,0.05)',
          drawBorder: false,
          zeroLineColor: 'rgba(0,0,0,0.05)'
        },
        ticks: {
          suggestedMax: 4,
          fontSize: 10,
          fontColor: 'rgba(52, 64, 80, 0.4)',
          maxTicksLimit: 3
        },
        afterFit(object) {
          const scale = object
          scale.width = 45
        },
        afterBuildTicks(object) {
          const chart = object
          chart.ticks = chart.ticks.filter(value => value >= 0)
        }
      }
    ]
  }
}
