import { capitalize } from 'lodash'
export const state = () => ({
  currentDownloads: [],
  downloadHistory: [],

  minDate: null,
  maxDate: null,
  dateRange: { from: null, to: null },

  currentPage: 1,
  pageLimit: 20,
  lastRow: false,
  enableDownload: true,

  selectedRows: {
    shipments: [],
    consignees: [],
    shippers: []
  }
})

const { SHIPMENTS_API_URL } = process.env

export const getters = {
  allDownloads: state => {
    const downloads = [
      ...state.currentDownloads.filter(
        download => download.export_status !== 'completed'
      ),
      ...state.downloadHistory
    ]
    return downloads.map(item => {
      if (
        ['consignee', 'shipper'].includes(item.download_source.toLowerCase())
      ) {
        let dummy = Object.assign({}, item)
        dummy.view = dummy.download_source + 's'
        return dummy
      }
      return item
    })
  },
  downloadSelectedRows(state, getters, rootState) {
    const { activeAggregateView } = rootState.views
    return state.selectedRows[activeAggregateView].toString()
  }
}

export const mutations = {
  resetState(currentState) {
    Object.assign(currentState, state())
  },
  setDateRange(state, { from, to }) {
    if (from) {
      state.dateRange.from = from
    }
    if (to) {
      state.dateRange.to = to
    }
  },
  setDateLimits(state, { minDate, maxDate }) {
    if (minDate) {
      state.minDate = minDate
    }
    if (maxDate) {
      state.maxDate = maxDate
    }
  },
  removeDownload(state, id) {
    state.currentDownloads = state.currentDownloads.filter(
      download => download.export_id !== id
    )
  },
  setCurrentDownloads(state, downloads) {
    state.currentDownloads = downloads
  },
  addToCurrentDownloads(state, download) {
    state.currentDownloads = [download, ...state.currentDownloads]
  },
  setDownloadHistory(state, history) {
    state.downloadHistory = history
  },
  addToDownloadHistory(state, history) {
    state.downloadHistory = [...state.downloadHistory, ...history]
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  },
  setLastRow(state, last) {
    state.lastRow = last
  },
  setDownloadStatus(state, downloadStatus) {
    state.enableDownload = downloadStatus
  },
  selectRow(state, { index, value, data }) {
    if (value) {
      state.selectedRows[index] = { value, data }
      return
    }
    state.selectedRows[index] = value
  },
  setSelectedRows(state, { view, rows }) {
    state.selectedRows[view] = [...rows].sort((a, b) => a - b)
  }
}

export const actions = {
  async initialize({ state, commit, dispatch }) {
    commit('setDateRange', {
      from: 0, // 1970
      to: Date.now()
    })

    const promises = state.currentDownloads.length
      ? [dispatch('fetchDownloadHistory')]
      : [dispatch('fetchCurrentDownloads'), dispatch('fetchDownloadHistory')]
    await Promise.all(promises)
  },
  async fetchCurrentDownloads({ commit }, params = '') {
    try {
      const { id: clientId } = this.$auth.user
      const requestId = 'fetchCurrentDownloads'
      this.$axios.cancel(requestId)
      if (!clientId) {
        return
      }
      const queryParameter = params.length
        ? {
            params: {
              exportId: params
            }
          }
        : {}
      const { data } = await this.$axios.$get(
        `${SHIPMENTS_API_URL}/v1/client/${clientId}/downloads/status`,
        {
          ...queryParameter,
          requestId,
          progress: false
        }
      )
      commit('setCurrentDownloads', data)
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return
      }
      throw error
    }
  },
  async fetchDownloadHistory({ state, commit }, progress = true) {
    try {
      const { id: clientId } = this.$auth.user
      if (!clientId) {
        return
      }
      const requestId = 'fetchDownloadHistory'
      this.$axios.cancel(requestId)
      const { from, to } = state.dateRange
      // if page has been moved, we'll fetch all records up to that page
      const fetchAll = state.currentPage > 1
      // convert 'to' parameter to end of day
      const eodTo = new Date(to).setHours(23, 59, 59) / 1000
      const { data } = await this.$axios.$get(
        `${SHIPMENTS_API_URL}/v1/client/${clientId}/downloads/history`,
        {
          params: {
            dates: `${from / 1000}-${eodTo}`,
            page: 1,
            size: fetchAll
              ? state.currentPage * state.pageLimit
              : state.pageLimit
          },
          requestId,
          progress
        }
      )
      commit('setDownloadHistory', data)
      if (data.length < state.pageLimit) {
        commit('setLastRow', true)
      }
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return
      }
      throw error
    }
  },
  async fetchMoreHistory({ state, commit }) {
    try {
      const { id: clientId } = this.$auth.user
      if (!clientId) {
        return
      }
      const requestId = 'fetchMoreDownloadHistory'
      this.$axios.cancel(requestId)
      const { from, to } = state.dateRange
      // convert 'to' parameter to end of day
      const eodTo = new Date(to).setHours(23, 59, 59) / 1000
      const { data } = await this.$axios.$get(
        `${SHIPMENTS_API_URL}/v1/client/${clientId}/downloads/history`,
        {
          params: {
            dates: `${from / 1000}-${eodTo}`,
            page: state.currentPage + 1,
            size: state.pageLimit
          },
          requestId
        }
      )
      if (!data.length) {
        commit('setLastRow', true)
        return
      }
      commit('setCurrentPage', state.currentPage + 1)
      commit('addToDownloadHistory', data)
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return
      }
      throw error
    }
  },
  async generateDownload(
    { getters, commit, rootState, rootGetters },
    { type, payload }
  ) {
    try {
      const isCompany = ['consignee', 'shipper'].includes(type)
      let url = `${SHIPMENTS_API_URL}/v1/${rootState.country}/${
        rootState.tradeType
      }/downloads${isCompany ? `/${rootState.company.companyId}` : ''}`

      let params = {}
      let sortField, sortOrder
      let sort = ''

      if (!isCompany && type !== 'bookmarks') {
        const activeAggregateView = rootState.views.activeAggregateView
        const aggregateNamespace = `aggregated${capitalize(
          activeAggregateView
        )}`
        const aggregateData = rootState.search[aggregateNamespace]

        if (activeAggregateView !== 'shipments') {
          ;({ sortField, sortOrder } = aggregateData)
          sort = sortOrder === 'desc' ? '-' : ''
        }
      }

      switch (type) {
        case 'consignee':
        case 'shipper':
          params = rootGetters['company/companyCriteria']({
            ...payload,
            types: type,
            selectedRows: ''
          })
          break
        case 'search':
          params = rootGetters['search/searchCriteria']({
            ...payload,
            fields: rootState.views.activeShipmentsView.items,
            view: rootState.views.activeShipmentsView.name,
            selectedRows: getters.downloadSelectedRows,
            searchId: rootState.search.currentSearchId
          })
          break
        case 'bookmarks':
          let view = rootState.views.views.find(v => v.name === payload.view)
          if (!view) {
            view = rootState.views.views[0]
          }
          params = {
            ...payload,
            fields: view.items,
            view: view.name,
            selectedRows: ''
          }
          break
        case 'aggregateConsignees':
          url = `${SHIPMENTS_API_URL}/v1/${rootState.country}/${
            rootState.tradeType
          }/downloads/aggregates/consignees`

          params = rootGetters['search/searchCriteria']({
            ...payload,
            sortBy: `${sort}${sortField}`,
            size: 500,
            selectedRows: getters.downloadSelectedRows,
            searchId: rootState.search.currentSearchId
          })
          break
        case 'aggregateShippers':
          url = `${SHIPMENTS_API_URL}/v1/${rootState.country}/${
            rootState.tradeType
          }/downloads/aggregates/shippers`

          params = rootGetters['search/searchCriteria']({
            ...payload,
            sortBy: `${sort}${sortField}`,
            size: 500,
            selectedRows: getters.downloadSelectedRows,
            searchId: rootState.search.currentSearchId
          })
          break
        default:
          throw 'Type does not exist.'
      }
      const { export_id } = await this.$axios.$post(url, params, {
        progress: false,
        headers: {
          ...(rootState.search.searchToken
            ? { 'x-search-token': rootState.search.searchToken }
            : {})
        }
      })
      const { id: clientId } = this.$auth.user

      const { data } = await this.$axios.$get(
        `${SHIPMENTS_API_URL}/v1/client/${clientId}/downloads/status`,
        {
          params: {
            exportId: export_id
          },
          progress: false
        }
      )

      commit('addToCurrentDownloads', data[0])
    } catch (error) {
      const {
        response: { data: { reason } = { data: { reason: '' } } }
      } = error

      if (reason === 'wait until the queue became 2') {
        commit('setDownloadStatus', false)
        return
      }

      if (reason === 'Downloads Results Limit reached') {
        return
      }

      throw error
    }
  },
  async cancelDownload({ commit, rootState }, exportId) {
    try {
      await this.$axios.$put(
        `${SHIPMENTS_API_URL}/v1/${rootState.country}/${
          rootState.tradeType
        }/downloads/${exportId}`,
        { progress: false }
      )
      commit('removeDownload', exportId)
    } catch (error) {
      const response = error.response ? error.response.data : ''
      // Ignore export already cancelled and completed errors
      if (
        response.reason === 'Export is already canceled' ||
        response.reason === 'Export is already completed'
      ) {
        return
      }
      throw error
    }
  },
  async setDateRange({ commit, dispatch }, dateRange) {
    commit('setCurrentPage', 1)
    commit('setLastRow', false)
    commit('setDateRange', dateRange)
    dispatch('fetchDownloadHistory')
  },
  getSelectedData({ state }) {
    return state.selectedRows.filter(item => {
      return item !== false
    })
  }
}
