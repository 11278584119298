<template>

  <!-- This component is currently not used and is outdated. -->

  <div
    v-if="selectedRefinedByMobile"
    class="exclude-include-mobile">
    <div
      v-click-outside="close"
      :class="{
        slideUp: isOpen,
        slideDown: !isOpen
      }"
      class="btn-tools">
      <a
        href="#"
        class="btn btn-text"
        @click.prevent="selectCondition('include')">
        <span
          :class="{ include: activeCondition === 'include' }"
          class="default-radio-btn"/>
        Include
      </a>
      <a
        href="#"
        class="btn btn-text secondary"
        @click.prevent="selectCondition('exclude')">
        <span
          :class="{ exclude: activeCondition === 'exclude' }"
          class="default-radio-btn"/>
        Exclude
      </a>
      <a
        href="#"
        class="btn btn-text secondary"
        @click.prevent="selectCondition('')">
        <span
          :class="{ clear: activeCondition === '' }"
          class="default-radio-btn"/>
        Clear
      </a>
      <a
        href="#"
        class="btn btn-text secondary"
        @click.prevent.stop="close">
        Cancel
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  data() {
    return {
      isOpen: false,
      activeCondition: ''
    }
  },
  computed: {
    ...mapState('search', {
      selectedRefinedByMobile: state => state.selectedRefinedByMobile
    })
  },
  watch: {
    selectedRefinedByMobile(newVal) {
      if (newVal) {
        this.activeCondition = newVal.initialCondition
        setTimeout(() => {
          this.isOpen = true
        }, 5)
      }
    }
  },
  methods: {
    ...mapMutations('search', [
      'setCurrentPage',
      'setRefinedBy',
      'removeRefinedBy',
      'setSelectedRefinedByMobile'
    ]),
    ...mapActions('search', [
      'fetchRefinedBy',
      'fetchShipmentsAndShipmentAggregates',
      'fetchSearchHistory',
      'fetchShipmentGraph'
    ]),
    ...mapActions('analytics', ['fetchAnalyticsData']),
    selectCondition(condition) {
      if (this.activeCondition !== condition) {
        this.activeCondition = condition

        this.toggleRefinedBy({
          ...this.selectedRefinedByMobile,
          condition
        })
      }

      this.close()
    },
    close() {
      this.isOpen = false
      setTimeout(() => {
        this.setSelectedRefinedByMobile(null)
      }, 400)
    },
    toggleRefinedBy(payload) {
      if (payload.condition) {
        this.setRefinedBy(payload)
      } else {
        this.removeRefinedBy(payload)
      }

      this.fetchRefinedBy()
      this.setCurrentPage(1)
      // this.fetchShipmentGraph()
      this.fetchAnalyticsData()
      this.fetchShipmentsAndShipmentAggregates().then(() => {
        this.fetchSearchHistory()
      })
    }
  }
}
</script>
