import clientWebAPI from '~/api/helpers/client'
import { APPLICATION_DOMAIN } from '~/api/config'

const distantFuture = 60 * 60 * 24 * 365 * 9999
/**
 * Initialize Referrer
 * Create cookie containing referrer information
 */
export function initReferrerCookie({ app, $auth }) {
  let clientId = null

  if ($auth.user) {
    clientId = $auth.user.id
  }

  const offlineReferrerCookie = app.$cookies.get('offline_referrer')

  if (!offlineReferrerCookie) {
    const offlineReferrer = {
      referrer: document.referrer,
      firstpage: window.location.href,
      attemptedUpdate: false
    }

    app.$cookies.set('offline_referrer', JSON.stringify(offlineReferrer), {
      path: '/',
      domain: APPLICATION_DOMAIN,
      maxAge: distantFuture
    })
  } else {
    const assumedValidCookie = 'attemptedUpdate' in offlineReferrerCookie

    if (assumedValidCookie && !offlineReferrerCookie.attemptedUpdate) {
      tryUpdateReferrer(clientId, offlineReferrerCookie, app.$cookies)
    }
  }
}

/**
 * This will determine if offline registered user is suitable for referrer tracking.
 */
export async function tryUpdateReferrer(
  clientId,
  offlineReferrer = null,
  cookies
) {
  const referrerReference = offlineReferrer
  const assumedValidCookie = 'attemptedUpdate' in offlineReferrer

  const isValidAttempt =
    referrerReference !== null &&
    clientId !== null &&
    assumedValidCookie &&
    !referrerReference.attemptedUpdate

  if (isValidAttempt) {
    const token = cookies.get('auth._token.auth0')
    const response = await clientWebAPI.attemptUpdateReferrer(
      clientId,
      referrerReference.referrer,
      referrerReference.firstpage,
      token
    )

    if (response.data && response.data.success) {
      referrerReference.attemptedUpdate = true
      updateCookie(referrerReference, cookies)
    }
  }
}

function updateCookie(referrerCookieObject, cookies) {
  cookies.set('offline_referrer', JSON.stringify(referrerCookieObject), {
    path: '/',
    domain: APPLICATION_DOMAIN,
    maxAge: distantFuture
  })
}
