import Radio from './src/radio'
import RadioGroup from './src/radio-group'
import RadioButton from './src/radio-button'

/* istanbul ignore next */
Radio.install = function(Vue) {
  Vue.component(Radio.name, Radio)
}

RadioGroup.install = function(Vue) {
  Vue.component(RadioGroup.name, RadioGroup)
}

RadioButton.install = function(Vue) {
  Vue.component(RadioButton.name, RadioButton)
}

export default function installRadios(vue) {
  Radio.install(vue)
  RadioGroup.install(vue)
  RadioButton.install(vue)
}
