<template>
  <div class="option-fields">
    <div
      ref="label"
      @click.prevent="open" />
    <el-dialog
      :width="width"
      :visible.sync="isOpen"
      :title="title"
      :before-close="close"
      class="iframe-dialog"
      append-to-body>
      <iframe
        :src="source"
        :width="'760px'"
        :height="loading ? '0px' : height"
        allow="fullscreen"
        @load="hideLoader" />
      <div
        v-loading="loading"
        v-if="loading"
        :style="{ width: '760px !important', height: height + ' !important' }" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'IframeModal',
  props: {
    label: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: '800px'
    },
    height: {
      type: String,
      default: '500px'
    },
    title: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: this.visible,
      loading: true
    }
  },
  mounted() {
    this.$refs.label.innerHTML = this.label
  },
  methods: {
    open() {
      this.isOpen = true
      this.$emit('update:visible', true)
    },
    close() {
      this.isOpen = false
      this.$emit('update:visible', false)
    },
    hideLoader() {
      this.loading = false
    }
  }
}
</script>

<style>
.iframe-dialog .el-dialog__header {
  padding: 1.6rem 1.25rem 1.55rem;
}
.iframe-dialog {
  line-height: 0;
  z-index: 9999999 !important;
}
</style>
