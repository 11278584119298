<template>
  <span class="paging-count-showing">
    Showing {{ dataShowing.from }} to {{ dataShowing.to }} of {{ dataShowing.limit }} entries {{ filteredDataMessage }}
  </span>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      default: 1
    },
    data: {
      type: Array,
      required: true
    },
    totalLimit: {
      type: Number,
      required: true
    },
    pageSizes: {
      type: Number,
      required: true
    }
  },
  computed: {
    dataShowing() {
      const index = (this.currentPage - 1) * this.pageSizes
      return {
        from: index + 1,
        to: index + this.data.length,
        limit: Math.min(this.totalLimit, this.total)
      }
    },
    filteredDataMessage() {
      return this.totalLimit <= this.dataShowing.limit
        ? `(filtered from ${this.total})`
        : ''
    }
  }
}
</script>
